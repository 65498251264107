import {create} from 'zustand'

import { filterProductByPriceRange, filterProductsByKeywordService, getProductDetailService, getProductsService, getStatsService, getTopSellingProductsService   } from '../api/apiServices'

const productStore = create((set) => ({
  products: [],
  isFilterOpen: false,
  filteredProducts: [],
  filteredProductsByNameAndCategory: [],
  TopSellingProducts: [],
  setTopSellingProducts:async ()=>{
    try {
      console.log("Calling setTopSellingProducts");
      const { data } = await getTopSellingProductsService()
      set({ TopSellingProducts: data });
      console.log("Top selling products set",data);
    } catch (error) {
      console.error('Error fetching products:', error)
    }

  },
  dashboardStat: [],
  search: '',

  setIsFilterOpen: (isOpen) => {
    set({ isFilterOpen: isOpen })
  },

  getStat: async () => {
    try {
      const { data } = await getStatsService()
      set({ dashboardStat: data })
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  },

  //get all products
  getProducts: async (currentPage, perPage) => {
    try {
      const { data } = await getProductsService(currentPage, perPage)
      set({ products: data })
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  },

  setFilteredProducts: async (keywordtoSearch) => {
    try {
      const { data } = await filterProductsByKeywordService(keywordtoSearch)
      set({ filteredProducts: data })
    } catch (error) {
      console.log('Error fetching filtered data')
    }
  },

  setFilteredByPrice: (order) => {
    if (order === 'asc') {
      set((state) => ({
        filteredProducts: state.filteredProducts.sort(
          (a, b) => a.price - b.price
        ),
      }))
    } else {
      set((state) => ({
        filteredProducts: state.filteredProducts.sort(
          (a, b) => b.price - a.price
        ),
      }))
    }
  },

  setFilteredProductsByNameAndCategory: (SearchResult) => {
    if (SearchResult.length === 0) {
      set({ filteredProductsByNameAndCategory: [] })
      return
    }

    set({ filteredProductsByNameAndCategory: SearchResult })
  },

  setPriceRangeProduct: async (listproduct) => {
    try {
      const { data } = await filterProductByPriceRange(
        listproduct[0],
        listproduct[1]
      )
      set({ filteredProducts: data })
    } catch (error) {
      console.log('Error fetching filtered data')
    }
  },

  setClearFilter: () => {
    set({
      filteredProducts: [],
      filteredProductsByNameAndCategory: [],
    })
  },

  setAllProducts: async (currentPage, perPage) => {
    try {
      const { data } = await getProductsService(currentPage, perPage)
      set((state) => ({
        filteredProducts: data.products,
      }))
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  },

  setProducts: (filteredProducts) => {
    set({ products: filteredProducts })
  },

  addProduct: (product) => {
    set((state) => ({ products: [...state.products, product] }))
  },

  //update product by id
  updateProductById: (productId, updatedProduct) => {
    set((state) => ({
      products: state.products.map((product) =>
        product.id === productId ? updatedProduct : product
      ),
    }))
  },

  // getproductbyName
  getProductByName: async (name) => {
    try {
      const response = await getProductDetailService(name)
      const product = response.data
      return product
    } catch (error) {
      console.error('Error fetching product by id:', error)
      return null
    }
  },

  //delete product by id
  deleteProductById: (productId) => {
    set((state) => ({
      products: state.products.filter((product) => product.id !== productId),
    }))
  },

  updateProduct: (productId, updatedProduct) => {
    set((state) => ({
      products: state.products.map((product) =>
        product.id === productId ? updatedProduct : product
      ),
    }))
  },

  setOrderSearch: (search) => {
    set({ search })
  },
}))

export default productStore
