import React, { useState } from 'react'
import ProductFilterPage from '../Home/ProductFilterPage'
import { useParams } from 'react-router-dom'

const CategoryWithProduct = () => {
  const [category, setCategory] = useState(true)
  const param = useParams()
  const { id, name } = param
  return (
    <div>
      <ProductFilterPage
        category={category}
        setCategory={setCategory}
        id={id}
        name={name}
      />
    </div>
  )
}

export default CategoryWithProduct
