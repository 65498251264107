import React, { useState } from 'react'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import { Modal } from './Modal'
import CategoryForm from './CategoryForm'
import CategoryTable from '../Modal/CategoryTable'
import SubcategoryForm from './SubcategoryForm'

const Category = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const ModalToggle = (title) => {
    switch (title) {
      case 'Add Category':
        setModalTitle(title)
        setIsOpen(true)
        break
      case 'Add Subcategory':
        setModalTitle(title)
        setIsOpen(true)
        break
      case 'Edit Category':
        setModalTitle(title)
        setIsOpen(true)
        break
      case 'Edit Subcategory':
        setModalTitle(title)
        setIsOpen(true)
        break
      default:
        break
    }
  }

  return (
    <>
      <section className='p-4 mt-4 bg-white'>
        <div className='flex gap-2 justify-between items-center'>
          <h1 className='md:text-xl text-sm font-bold text-gray-500'>
            List of categories
          </h1>
          <div className='flex items-center justify-center space-x-2'>
            <button
              type='button'
              onClick={() => ModalToggle('Add Category')}
              className='w-full flex space-x-1 gap-x-1 items-center px-3 py-2 rounded-md
             bg-blue-500 hover:bg-blue-500 text-white duration-300'
            >
              <MdOutlineAddCircleOutline /> Category
            </button>
            <button
              type='button'
              onClick={() => ModalToggle('Add Subcategory')}
              className='w-full flex space-x-1 gap-x-1 items-center px-3 py-2 rounded-md
             bg-blue-500 hover:bg-blue-500 text-white duration-300'
            >
              <MdOutlineAddCircleOutline /> SubCategory
            </button>
          </div>
        </div>
      </section>

      <div className='rounded-sm border border-stroke bg-white px-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1'>
        <Modal title={modalTitle} isOpen={isOpen} setIsOpen={setIsOpen}>
          {modalTitle === 'Add Category' && (
            <CategoryForm setIsOpen={setIsOpen} />
          )}
          {modalTitle === 'Add Subcategory' && (
            <SubcategoryForm setIsOpen={setIsOpen} />
          )}
        </Modal>
      </div>
      <CategoryTable />
    </>
  )
}

export default Category
