import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { veryEmailService } from "../../api/apiServices";
import { useNavigate } from "react-router-dom";
import Helmat from "../../components/Helmat/Helmat";

function EmailVerification() {
  const { token } = useParams();
  const [verificationStatus, setVerificationStatus] = useState("verifying...");
  const navigate = useNavigate();

  const verifyEmail = useCallback(async () => {
    try {
      const { data } = await veryEmailService(token);
      setVerificationStatus(data.message);
      setTimeout(() => {
        navigate("/auth/login");
      }, 2000);
    } catch (error) {
      setVerificationStatus(error.response.data.message);
    }
  }, [navigate, token]);

  useEffect(() => {
    verifyEmail();
  }, [token, verifyEmail]);

  return (
    <>
      <Helmat title="Email verification" content="Email verification" />
      <div className="min-h-[70vh] bg-gray-100 flex items-center justify-center">
        <div className="max-w-md w-full bg-white p-6 rounded-md shadow-md">
          <h2 className="text-2xl font-semibold mb-4 text-red-700">
            Email Verification
          </h2>
          <p className="text-base text-gray-700">{verificationStatus}</p>
        </div>
      </div>
    </>
  );
}

export default EmailVerification;
