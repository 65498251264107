import React from 'react'
import ReactApexChart from 'react-apexcharts'

const ChartFour = ({ data }) => {
  if (!data || !data.productCounts || !data.categoryNames) {
    return <>Loading...</>
  }

  const series = [
    {
      data: data.productCounts,
    },
  ]

  const options = {
    colors: ['#3C50E0'],
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 2,
        dataLabels: {
          // Enable data labels for the bars
          position: 'top', // Display data labels above the bars
          offsetY: -15, // Adjust the vertical position of the data labels
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            colors: ['#000'], // Color of the data labels
          },
        },
      },
    },
    dataLabels: {
      enabled: false, // Enable data labels for all series
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.categoryNames,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: 'Category Name', // Set the title for the X-axis
        offsetX: 0,
        offsetY: 10,
        style: {
          color: '#444',
          fontWeight: 'bold',
        },
      },
    },
    yaxis: {
      title: {
        text: 'product count', // Set the title for the Y-axis
        offsetX: 0,
        offsetY: -5,
        style: {
          color: '#444',
          fontWeight: 'bold',
        },
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'inter',
      markers: {
        radius: 99,
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (val) {
            return 'Product Count'
          },
        },
      },
    },
  }

  return (
    <div className='col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5'>
      <div>
        <h3 className='text-base text-slate-600 font-semibold dark:text-white'>
          Category with product count
        </h3>
      </div>
      <div className='mb-2'>
        <div id='chartFour' className='-ml-5 pb-2'>
          <ReactApexChart
            options={options}
            series={series}
            type='bar'
            height={350}
          />
        </div>
      </div>
    </div>
  )
}

export default ChartFour
