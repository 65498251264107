import React, { useEffect, useRef } from 'react'
import useProductStore from '../../zustand/productStore'
import Title from '../ui/UiElements/Title'
import Product from '../../pages/product/Product'

const SearchResult = () => {
  const { filteredProductsByNameAndCategory } = useProductStore()
  const titleRef = useRef(null)

  useEffect(() => {
    if (
      filteredProductsByNameAndCategory &&
      filteredProductsByNameAndCategory.length > 0
    ) {
      if (titleRef.current) {
        titleRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [filteredProductsByNameAndCategory])

  return (
    <>
      <Title ref={titleRef}>Search results</Title>
      <div className='mx-auto gap-1  grid items-center py-4 grid-cols-2 sm:gap-2 md:gap-3 lg:grid-cols-5'>
        {filteredProductsByNameAndCategory?.map((product) => (
          <Product product={product} key={product.product_id} />
        ))}
      </div>
    </>
  )
}

export default SearchResult
