import axios from "axios";

import {
  UPDATE_SUBCATEGORY_URL,
  SIGNUP_URL,
  LOGIN_URL,
  LOGOUT_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  USER_URL,
  USER_DETAILS_URL,
  UPDATE_USER_ROLE,
  USER_DELETE_URL,
  ADD_PRODUCT_URL,
  UPDATE_PRODUCT_URL,
  DELETE_PRODUCT_URL,
  PRODUCTS_URL,
  CATEGORIES_URL,
  ADD_CATEGORY_URL,
  UPDATE_CATEGORY_URL,
  DELETE_CATEGORY_URL,
  ORDER_URL,
  ORDER_DETAILS_URL,
  MY_ORDERS,
  GET_ALL_ORDERS,
  PRODUCT_DETAILS_URL,
  SUBCATEGORIES_URL,
  ADD_SUBCATEGORY_URL,
  DELETE_SUBCATEGORY_URL,
  PAYMENT_URL,
  DASHBOARD_URL,
  SHOW_PRODUCTS_BY_CATEGORY_URL,
  GET_ALL_CATEGORY_WITH_SUBCATEGORY_URL,
  GET_PRODUCTS_BY_SUBCATEGORY_URL,
  UPDATE_ORDER_STATUS,
  UPDATE_PASSWORD,
  SUBCATEGORY_KEYWORD_SEARCH,
  PRICE_RANGE_SEARCH,
  UPLOAD_FEATURE_IMAGES,
  GET_ALL_CATEGORY_WITH_PRODUCT_URL,
  GET_FEATURE_IMAGES,
  DELETE_FEATURE_IMAGE,
  SEARCH_PRODUCT_BY_KEYWORD,
  SHIPPING_URL,
  UPDATE_PAYMENT_STATUS,
  GET_ORDERS_BY_PAYMENT_STATUS,
  GET_ORDERS_BY_SHIPPING_STATUS,
  ADD_COUPON_URL,
  GET_ALL_COUPON_URL,
  GET_COUPON_BY_ID_URL,
  DELETE_COUPON_URL,
  CHECK_COUPON_CODE_URL,
  VERYFY_EMAIL_URL,
  Add_OTHER_SERVICE_URL,
  GET_ALL_OTHER_SERVICE_URL,
  DELETE_OTHER_SERVICE_URL,
  UPDATE_OTHER_SERVICE_URL,
  GET_OTHER_SERVICE_BY_ID_URL,
  SEND_EMAIL_OTHER_SERVICE_URL,
  GUEST_ORDER_URL,
  getTopSoldProducts,
} from "./apiUrls";
import { notify } from "../utils/utils";

const axiosInstance = axios.create({
  withCredentials: true,
  timeout: 100000,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response.status === "ERR_NETWORK") {
      notify("error", "Network error, please try again later");
    }

    return Promise.reject(err);
  }
);

export const getTopSellingProductsService = () =>
  axiosInstance.get(getTopSoldProducts);

export const loginService = ({ email, password }) =>
  axiosInstance.post(LOGIN_URL, {
    email: email,
    password: password,
  });

export const signupService = ({ name, email, password, confirmPassword }) =>
  axiosInstance.post(SIGNUP_URL, { name, email, password, confirmPassword });

export const logoutService = () =>
  axios.post(LOGOUT_URL, { withCredentials: true });

export const forgotPasswordService = (email) =>
  axios.post(FORGOT_PASSWORD_URL, { email });

export const resetPasswordService = (token, password, confirmPassword) =>
  axios.post(`${RESET_PASSWORD_URL}/${token}`, { password, confirmPassword });

export const veryEmailService = (token) =>
  axios.get(`${VERYFY_EMAIL_URL}/${token}`);

export const updateCrediential = (
  name,
  oldPassword,
  newPassword,
  confirmPassword
) =>
  axios.put(
    UPDATE_PASSWORD,
    {
      name,
      oldPassword,
      newPassword,
      confirmPassword,
    },
    { withCredentials: true }
  );

export const getUserListService = (page, perPage) =>
  axiosInstance.get(USER_URL, {
    params: {
      page,
      perPage,
    },
  });

export const getUserDetailsService = () => axiosInstance.get(USER_DETAILS_URL);

export const updateUserProfileService = (user) =>
  axiosInstance.put(`${UPDATE_USER_ROLE}/${user.id}`, { role: user.role });

export const deleteUserProfileService = (id) =>
  axiosInstance.delete(`${USER_DELETE_URL}/${id}`);

export const addProductService = (product) =>
  axiosInstance.post(ADD_PRODUCT_URL, product, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateProductService = ({ formData, id }) => {
  console.log(formData, id);
  return axiosInstance.put(`${UPDATE_PRODUCT_URL}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteProductService = (id) =>
  axiosInstance.delete(`${DELETE_PRODUCT_URL}/${id}`);

//get product detail
export const getProductDetailService = (name) =>
  axiosInstance.get(`${PRODUCT_DETAILS_URL}/${name}`);

// feature image upload
export const uploadFeatureImageService = (images) =>
  axiosInstance.post(UPLOAD_FEATURE_IMAGES, images, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getAllFeatureImages = () => axiosInstance.get(GET_FEATURE_IMAGES);

export const deleteFeatureImage = (id) =>
  axiosInstance.delete(`${DELETE_FEATURE_IMAGE}/${id}`);

export const addCategoryService = (category) =>
  axiosInstance.post(ADD_CATEGORY_URL, { name: category });

export const updateCategoryService = (id, category) =>
  axiosInstance.put(`${UPDATE_CATEGORY_URL}/${id}`, { category });

export const deleteCategoryService = (index) =>
  axiosInstance.delete(`${DELETE_CATEGORY_URL}/${index}`);

export const showCategoriesService = () => axiosInstance.get(CATEGORIES_URL);

export const getProductByCategoryService = (category) =>
  axiosInstance.get(`${SHOW_PRODUCTS_BY_CATEGORY_URL}/${category}`);

//subcategories service
export const addSubCategoryService = (subcategory) => {
  return axiosInstance.post(ADD_SUBCATEGORY_URL, {
    newSubcategory: subcategory.newSubCategory,
    categoryId: subcategory.selectedCategory,
  });
};

export const updateSubCategoryService = (id, title) =>
  axiosInstance.put(`${UPDATE_SUBCATEGORY_URL}/${id}`, { title });

export const deleteSubCategoryService = (id) =>
  axiosInstance.delete(`${DELETE_SUBCATEGORY_URL}/${id}`);

// order service
export const orderService = (order) => {
  return axiosInstance.post(ORDER_URL, {
    order: order.cartItems,
    paymentOptions: order.paymentOptions,
    shippingInfo: order.shippingAddress,
    coupon_info: order.coupon_info,
    shippingOption: order.shippingOption,
  });
};

export const guestOrderService = (order) => {
  return axiosInstance.post(GUEST_ORDER_URL, {
    order: order.cartItems,
    paymentOptions: order.paymentOptions,
    shippingInfo: order.shippingAddress,
    coupon_info: order.coupon_info,
    shippingOption: order.shippingOption,
  });
};

export const orderDetailsService = () => axiosInstance.get(ORDER_DETAILS_URL);

export const myOrdersService = (page, perPage) =>
  axiosInstance.get(MY_ORDERS, {
    params: {
      page,
      perPage,
    },
  });

export const updateOrderStatus = ({ order_id, shipping_status }) =>
  axiosInstance.put(`${UPDATE_ORDER_STATUS}/${order_id}/${shipping_status}`);

export const updatePaymentStatus = ({ order_id, payment_status }) =>
  axiosInstance.put(`${UPDATE_PAYMENT_STATUS}/${order_id}/${payment_status}`);

export const getOrderByIDService = (id) =>
  axiosInstance.get(`${ORDER_DETAILS_URL}/${id}`);

export const addShippingInfoService = (shippingInfo) =>
  axiosInstance.post(SHIPPING_URL, { shippingInfo });

export const paymentService = (order) => {
  return axiosInstance.post(PAYMENT_URL, {
    order: order.cartItems,
    paymentOptions: order.paymentOptions,
    shippingInfo: order.shippingAddress,
    coupon_info: order.coupon_info,
  });
};

export const getAllOrdersService = (page, perPage) =>
  axiosInstance.get(GET_ALL_ORDERS, { params: { page, perPage } });

export const getProductsService = (page, perPage, search) =>
  axiosInstance.get(PRODUCTS_URL, { params: { page, perPage, search } });

export const getCategoriesService = () => axiosInstance.get(CATEGORIES_URL);

export const getAllSubCategoriesService = () =>
  axiosInstance.get(SUBCATEGORIES_URL);

export const getAllCategoryWithSubcategoryService = () =>
  axiosInstance.get(GET_ALL_CATEGORY_WITH_SUBCATEGORY_URL);

export const getallcategorieswithproducts = () =>
  axiosInstance.get(GET_ALL_CATEGORY_WITH_PRODUCT_URL);

export const getProductBySubcategory = (id) =>
  axiosInstance.get(`${GET_PRODUCTS_BY_SUBCATEGORY_URL}/${id}`);
export const getStatsService = () => axiosInstance.get(DASHBOARD_URL);

// Filter products by subcategory and keyword
export const filterProductsByKeywordService = (keywordArray) =>
  axiosInstance.post(SUBCATEGORY_KEYWORD_SEARCH, { keywordArray });

export const filterProductByPriceRange = (low, high) =>
  axiosInstance.post(PRICE_RANGE_SEARCH, { low, high });

export const searchProductByKeywordService = (keyword) =>
  axiosInstance.get(`${SEARCH_PRODUCT_BY_KEYWORD}?keyword=${keyword}`);

export const addShippingAddressService = (shippingAddress) =>
  axiosInstance.post(`${ORDER_URL}/shipping`, { shippingAddress });

// get order by payment and shipping status
export const getOrdersByPaymentStatusService = (paymentStatus) =>
  axiosInstance.get(`${GET_ORDERS_BY_PAYMENT_STATUS}`, {
    params: { paymentStatus },
  });

export const getOrdersByShippingStatusService = (
  shippingStatus,
  page,
  perPage
) =>
  axiosInstance.get(`${GET_ORDERS_BY_SHIPPING_STATUS}`, {
    params: { shippingStatus, page, perPage },
  });

// coupon service
export const addCouponService = (coupon) =>
  axiosInstance.post(`${ADD_COUPON_URL}`, { coupon });

export const deleteCouponService = (id) =>
  axiosInstance.delete(`${DELETE_COUPON_URL}/${id}`);

export const getAllCouponService = () =>
  axiosInstance.get(`${GET_ALL_COUPON_URL}`);

export const getCouponById = (id) =>
  axiosInstance.get(`${GET_COUPON_BY_ID_URL}/${id}`);

export const checkCouponCodeService = (code) =>
  axiosInstance.post(CHECK_COUPON_CODE_URL, { code });

// other service service
export const createLinkService = (service) => {
  console.log(service, "service information...");
  return axiosInstance.post(Add_OTHER_SERVICE_URL, service, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getAllLinkService = () =>
  axiosInstance.get(GET_ALL_OTHER_SERVICE_URL);

export const deleteLinkService = (id) =>
  axiosInstance.delete(`${DELETE_OTHER_SERVICE_URL}/${id}`);

export const updateLinkService = (edit) => {
  console.log(edit);
  return axiosInstance.put(`${UPDATE_OTHER_SERVICE_URL}/${edit.id}`, edit.link);
};

export const getLinkByIdService = (id) =>
  axiosInstance.get(`${GET_OTHER_SERVICE_BY_ID_URL}/${id}`);

export const sendemailServiceInformation = (data) =>
  axiosInstance.post(`${SEND_EMAIL_OTHER_SERVICE_URL}`, data);
