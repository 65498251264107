import React, { useEffect, useState } from "react";
import OrderList from "../../components/Order/OrderList";
import NoOrdersMessage from "../../components/Order/NoOrdersMessage";
import { Link } from "react-router-dom";
import Helmat from "../../components/Helmat/Helmat";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { myOrdersService } from "../../api/apiServices";

const MyOrdersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const handlePageChange = (newPage) => {
    if (newPage <= 0) newPage = 1;

    setCurrentPage(newPage);
    queryClient.invalidateQueries(["myorders", newPage, pageSize]);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries(["myorders", currentPage, pageSize]);
  }, [currentPage, pageSize, queryClient]);

  const { isLoading, data: orders } = useQuery({
    queryKey: ["myorders", currentPage, pageSize],
    queryFn: () => myOrdersService(currentPage, pageSize),
    cacheTime: 0,
  });
  const ordersData = orders?.data.orders;

  if (ordersData?.orders?.length === 0) return <NoOrdersMessage />;

  return (
    <div className="mt-10 md:mt-0">
      <Helmat title="my order" content="my order" />

      <Link to="/" className="text-2xl text-red-500 underline">
        Back
      </Link>
      <OrderList
        orders={ordersData}
        totalPages={orders?.data.totalPages}
        totalCount={orders?.data.totalCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        loading={isLoading}
      />
    </div>
  );
};

export default MyOrdersPage;
