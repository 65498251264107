import React from 'react'
import CheckoutCrumb from '../components/Breadcrumb/CheckoutCrumb'
import { Outlet } from 'react-router-dom'
import BackToHome from '../components/Breadcrumb/BackToHome'

const CheckoutLayout = () => {
  return (
    <main className='flex flex-col mt-2 md:mt-3 px-5  '>
      <BackToHome />
      <CheckoutCrumb />
      <div className='h-full gap-4 mt-5 main-content flex space-x-5'>
        <Outlet />
      </div>
    </main>
  )
}

export default CheckoutLayout
