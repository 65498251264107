import React from 'react'
import CarouselComponent from './Carousel'
import { useQuery } from '@tanstack/react-query'
import { getAllFeatureImages } from '../../api/apiServices'

const EcommerceCarousel = () => {
  const { isLoading, data: featureImages } = useQuery({
    queryKey: ['featureImages'],
    queryFn: getAllFeatureImages,
  })

  const featureImagesData = featureImages?.data.images

  // filter feature images by category name
  const filteredFeatureImages = featureImagesData?.filter(
    (image) => image.category_type === 'OTHER'
  )
  return (
    <>
      <CarouselComponent
        featureImagesData={filteredFeatureImages}
        isLoading={isLoading}
      />
    </>
  )
}

export default EcommerceCarousel
