import React from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { fadeInOut } from "../animations";

const CarouselComponent = ({ featureImagesData, isLoading }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isVideo, setIsVideo] = React.useState(false);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? featureImagesData.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === featureImagesData.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  React.useEffect(() => {
    if (!featureImagesData || featureImagesData.length === 0) {
      return;
    }

    const currentData = featureImagesData[currentIndex];
    if (!currentData) {
      return;
    }

    setIsVideo(currentData.url.endsWith(".mp4"));

    const interval = setInterval(() => {
      if (!isVideo) {
        nextSlide();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex, isVideo, featureImagesData]);

  if (isLoading) return <p>Loading...</p>;

  const currentData = featureImagesData[currentIndex];

  if (!currentData) return null;

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={fadeInOut}
      className="max-w-full mt-10 h-[250px] object-cover sm:h-[300px] md:h-[400px] md:mt-0 w-full m-auto py-0 relative group"
    >
      {isVideo ? (
        <video
          src={currentData.url}
          className="w-full h-full object-cover duration-300"
          loop
          autoPlay
        ></video>
      ) : (
        <div
          style={{
            backgroundImage: `url(${currentData.url})`,
            backgroundRepeat: "no-repeat",
          }}
          className="w-full h-full bg-center rounded-md bg-contain transition-all duration-300"
        ></div>
      )}

      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 hover:bg-orange-500  text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white hover:bg-orange-500  cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
    </motion.div>
  );
};

export default CarouselComponent;
