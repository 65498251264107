import React from 'react'
import { Outlet } from 'react-router'
import { Navbar } from '../components/Navbar/Navbar'
import { Footer } from '../components/Footer/Footer'

const SharedLayout = () => {
  return (
    <div className='flex flex-col min-h-screen'>
      <Navbar />
      <div className='mx-0 md:mx-8 lg:mx-12 xl:mx-20 py-20 flex-1'>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default SharedLayout
