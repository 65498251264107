import React, { useState, useEffect } from 'react'
import AnalyticsCard from './AnalyticsCard'
import ChartFour from '../../components/ui/UiElements/ChartFour'
import useProductStore from '../../zustand/productStore'
import useScrollToTop from '../../hooks/useScrollToTop'

const Dashboard = () => {
  const { dashboardStat, getStat } = useProductStore()
  const [chartData, setChartData] = useState({
    productCounts: [],
    categoryNames: [],
  })

  useScrollToTop()
  useEffect(() => {
    // Call the getStat function
    getStat()
  }, [getStat])

  useEffect(() => {
    setChartData({
      productCounts: dashboardStat.productCounts,
      categoryNames: dashboardStat.categoryNames,
    })
  }, [dashboardStat])

  return (
    <div className='dark:bg-boxdark-2 dark:text-bodydark'>
      <div className='flex'>
        <div className='mt-3'>
          <div className='grid grid-cols-3 gap-2 md:grid-cols-2 md:gap-6 xl:grid-cols-5 2xl:gap-7.5'>
            <AnalyticsCard data={dashboardStat} />
          </div>

          <div className='mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5'>
            <ChartFour data={chartData} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
