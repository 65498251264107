import SummaryCard from "../../components/Checkout/SummaryCard";
import AddressForm from "../../components/Address/AddressForm";
import Helmat from "../../components/Helmat/Helmat";
import useScrollToTop from "../../hooks/useScrollToTop";

const CheckoutPage = () => {
  useScrollToTop();

  return (
    <>
      <Helmat title="checkout" content="checkout" />

      <div className="md:min-h-[80vh] flex justify-center items-center py-3">
        <main className="grid md:grid-cols-2 gap-10 w-full">
          <div className="py-3 md:py-7 px-5 md:px-7 lg:px-12 rounded-md shadow-sm bg-white/[0.7] border-r border-blue-300 ">
            <h1 className="text-xl font-bold text-center">Shipping Address</h1>
            <AddressForm />
          </div>
          <SummaryCard />
        </main>
      </div>
    </>
  );
};

export default CheckoutPage;
