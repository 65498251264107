import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useAuthStore from '../../zustand/userStore'
import { redirect } from 'react-router-dom'

const SuccessPage = () => {
  const [showSvg, setShowSvg] = useState(true)

  const { isAuthenticated } = useAuthStore((state) => state.isAuthenticated)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSvg(false)
      redirect('/myorder')
    }, 1000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <div className='h-screen'>
        <div className='p-6 md:mx-auto'>
          <svg
            viewBox='0 0 24 24'
            className={`text-green-600 w-16 h-16 mx-auto my-6 transition-opacity ${
              showSvg ? 'opacity-0' : 'opacity-100'
            }`}
          >
            <path
              fill='currentColor'
              d='M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z'
            ></path>
          </svg>
          <div className='text-center'>
            <h3 className='md:text-2xl text-base text-gray-900 font-medium text-center'>
              Payment Done!
            </h3>
            <p className='text-gray-600 my-2'>
              Thank you for completing your secure online payment.
            </p>
            <p> Have a great day! </p>
            <div className='flex items-center justify-center space-x-3'>
              <div className='py-10 text-center '>
                <Link
                  to='/'
                  className='px-5 rounded-md bg-green-500 hover:bg-indigo-500 text-black custom-blur font-semibold py-2'
                >
                  GO BACK
                </Link>
              </div>
              {isAuthenticated && (
                <div className='py-10 text-center'>
                  <Link
                    to='/myorder'
                    className='px-5 rounded-md bg-green-500 hover:bg-indigo-500  text-white font-medium py-2'
                  >
                    MY ORDERS
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessPage
