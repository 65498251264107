import React, { useState } from 'react'
import { Slider } from 'antd'
import useProductStore from '../../zustand/productStore'

const InputRange = () => {
  const [priceRange, setPriceRange] = useState([1, 100])

  const setPriceRangeProduct = useProductStore(
    (state) => state.setPriceRangeProduct
  )

  const debounce = (callback, delay) => {
    let timer
    return function (...args) {
      clearTimeout(timer)
      timer = setTimeout(() => callback(...args), delay)
    }
  }

  const applyFilter = async (value) => {
    setPriceRange(value)
    await setPriceRangeProduct(value)
  }

  const debouncedApplyFilter = debounce(applyFilter, 200)

  return (
    <div className='px-2 py-2 rounded-md'>
      <p className='text-white dark:text-black'>
        ${priceRange[0]} - ${priceRange[1]}
      </p>

      <Slider
        className='w-full h-3'
        range={{
          draggableTrack: true,
        }}
        value={priceRange}
        min={1}
        max={100}
        onChange={(value) => debouncedApplyFilter(value)}
      />
    </div>
  )
}

export default InputRange
