import { GiHamburgerMenu } from 'react-icons/gi'
import useProductStore from '../../../zustand/productStore'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/logo/Logo.png'

const Header = ({ sidebarOpen, setSidebarOpen, children }) => {
  const { setIsFilterOpen } = useProductStore()
  return (
    <header className='sticky top-0 z-20 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none'>
      <div className='flex flex-grow items-center justify-between py-0 px-2 md:py-4  shadow-2 md:px-6 2xl:px-11'>
        <div className='flex items-center justify-between  lg:hidden'>
          <Link to='/'>
            <img className='block h-13' src={Logo} alt='Workflow' />
          </Link>

          <button
            aria-controls='sidebar'
            onClick={(e) => {
              e.stopPropagation()
              setIsFilterOpen(true)
              setSidebarOpen(!sidebarOpen)
            }}
            className='z-20 block rounded-sm   bg-white p-2 text-black  dark:border-strokedark dark:bg-boxdark lg:hidden'
          >
            <span className='relative block h-6.5 w-6.5 cursor-pointer'>
              <GiHamburgerMenu className='text-2xl' />
            </span>
          </button>
        </div>
        <div className='hidden sm:block'>{children}</div>
      </div>
    </header>
  )
}

export default Header
