export { default as RegisterPage } from './auth/RegisterPage'
export { default as Login } from './auth/LoginPage'
export { default as EmailVerification } from './auth/EmailVerification'

export { default as Home } from './Home/HomePage'
export { default as Orders } from '../components/Table/AllOrders'
export { default as Users} from '../components/Table/Users'

export { default as ProductDetail } from './product/ProductDetail'

export { default as Cart } from '../pages/cart/CartPageTest'
export { default as Checkout } from '../pages/checkout/CheckoutPage'

export { default as MyOrderPage } from '../pages/order/MyOrdersPage'
export { default as OrderDetail } from '../pages/order/OrderDetail'

export { default as Category } from '../components/Category/Category'
export { default as CategoryWithProduct} from '../pages/product/CategoryWithProduct'

export { default as Dashboard } from './dashboard/Dashboard'

export {default as Shipping} from '../pages/shipping/ShippingPage'

export { default as ErrorPage } from './notFound/not-found'

export { default as ResetPassword } from './validation/ResetPassword'
export { default as ForgotPassword } from './validation/EmailReset'

export { default as Profile } from '../components/Profile/Profile'
export { default as ProfileSetting} from '../components/Profile/ProfileSetting'

export { default as Contact } from './contact/ContactPage'

export { default as UploadFeatureProduct } from '../components/UploadFeatureProduct/UploadFeatureProduct'

export {default as FAQ} from '../components/Footer/Faq'
export {default as PrivacyPolicy} from '../components/Footer/PrivacyPolicy'
export { default as RefundPolicy } from '../components/Footer/RefundPolicy'
export { default as TermConditions } from '../components/Footer/TermConditions'

// payment successfull
export { default as PaymentSuccess } from '../pages/success/SuccessPage'

