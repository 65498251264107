import React from 'react'
import { Link } from 'react-router-dom'

const BackToHome = () => {
  return (
    <Link
      to='/'
      className='underline px-5 md:px-0 mt-7 py-3 font-semibold mb-2 text-slate-600 md:hidden'
    >
      Back to Home
    </Link>
  )
}

export default BackToHome
