import { useEffect, useRef, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import useCartStore from "../../zustand/cartStore";
import { Avatar } from "../Avatar/Avatar";
import { Link } from "react-router-dom";
import Profile from "../Profile/Profile";
import Logo from "../../assets/logo/Logo.png";
import Search from "../filters/Search";
import useAuthStore from "../../zustand/userStore";
import CustomerSidebar from "../Sidebar/CustomerSidebar";

export function Navbar() {
  const isAuth = useAuthStore((state) => state.isAuthenticated);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { totalQuantity } = useCartStore();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc k2ey is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="w-full z-999 bg-white fixed top-0 shadow-md ">
      <CustomerSidebar
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        className={`${isFilterOpen ? "block" : "hidden"}  bg-gray-200 `}
      />
      <nav
        aria-label="Top"
        className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  relative "
      >
        <div className="flex h-14 md:h-16 items-center">
          <Profile
            dropdown={dropdown}
            setDropdownOpen={setDropdownOpen}
            dropdownOpen={dropdownOpen}
            trigger={trigger}
          />
          <button
            type="button"
            className="rounded-md text-black p-2  sm:hidden"
          >
            <span className="sr-only">Open menu</span>
            <GiHamburgerMenu
              className=" lg:block sh-6 w-6"
              aria-hidden="true"
              onClick={() => setIsFilterOpen(true)}
            />
          </button>
          <div className="flex-shrink-2 md:flex items-center ">
            <Link to="/">
              <img className="block md:h-20 h-14" src={Logo} alt="Workflow" />
            </Link>
          </div>
          <div className="hidden sm:flex lg:ml-6 items-center   flex-grow">
            <div className="relative w-full mx-auto">
              <Search />
            </div>
          </div>
          <div className="ml-auto flex h-full justify-center items-center ">
            {isAuth ? (
              <div onClick={() => setDropdownOpen(!dropdownOpen)} ref={trigger}>
                <Avatar className="cursor-pointer " />
              </div>
            ) : (
              <div className="space-y-6  px-4 py-6">
                <div className=" flex space-x-2 ">
                  <Link
                    to="/auth/login"
                    className="px-3 py-2 bg-red-600 text-white text-sm font-medium rounded-md hover:bg-red-700"
                  >
                    Sign in
                  </Link>
                </div>
              </div>
            )}

            <div className="mr-4 md:mr-2 relative flow-root lg:ml-6">
              <Link to="/cartpage" className="">
                <AiOutlineShoppingCart
                  className="text-2xl text-red-500"
                  aria-hidden="true"
                />
                <span className="absolute flex left-6 -top-3 items-center justify-center text-xs font-medium text-white bg-green-600 h-7 w-7 rounded-full">
                  {totalQuantity}
                </span>
              </Link>
            </div>
          </div>
        </div>
        <section className="mt-2 sm:hidden relative mb-3">
          <Search />
        </section>
      </nav>
    </div>
  );
}
