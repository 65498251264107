import React from 'react'
import { Link } from 'react-router-dom'

const BackToHome = () => {
  return (
    <div>
      <Link
        to='/'
        className='text-xl text-gray-600 underline sm:hidden font-bold'
      >
        Back
      </Link>
    </div>
  )
}

export default BackToHome
