import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router";
import { notify } from "../../utils/utils";
import useCartStore from "../../zustand/cartStore";


const Product = ({ product }) => {
  const {
    product_id,
    name,
    category,
    images,
    price,
    discounted_price,
    count_in_stock,
  } = product;
  const [isInCart, setIsInCart] = useState(false);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  const navigate = useNavigate();

  const cartItems = useCartStore((state) => state.cartItems);
  const addToCart = useCartStore((state) => state.addToCart);
  const CheckIsInCart = useCartStore((state) => state.isInCart);

  useEffect(() => {
    setIsInCart(CheckIsInCart(product_id));
  }, [cartItems, product_id, CheckIsInCart]);

  const handleAddToCart = (product) => {
    if (cartItems.find((item) => item.product_id === product_id)) {
      notify("error", "Item already in cart");
      return;
    } else {
      addToCart({
        product_id,
        name,
        price: discounted_price? discounted_price : price,
        discounted_price,
        category,
        qty: 1,
        image: images[0]?.url,
        count_in_stock,
      });
    }
  };

  return (
    <div
      key={product_id}
      className="flex flex-col w-full h-full  bg-white/[0.5] shadow-md border-2 border-black/[0.05] cursor-pointer transition-transform hover:scale-[1.02]" // Updated container div to span full width
    >
      {!loading ? (
        <>
          <div
            onClick={() => {
              navigate(`/product/${name}`);
              window.scrollTo(0, 0);
            }}
          >
            <div className="flex items-center justify-center bg-black/[0.075] w-full h-40 ">
              {" "}
              <img
                src={images?.[0]?.url}
                alt=""
                className="w-full h-full overflow-hidden  object-cover"
              />
            </div>

            <div className="p-3 flex flex-col justify-center items-center gap-2 mt-2">
              <div className="flex text-center items-center">
                <span className="text-sm text-center font-normal line-clamp-2">
                  {name}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center pb-3 md:gap-4 mx-auto">
            {" "}
            <div className="flex flex-wrap items-center justify-center md:space-x-4">
              <span className="text-sm w-auto text-gray-600">
                {discounted_price && `$${discounted_price}`}
              </span>
              <p className="text-base md:text-lg">
                <span
                  className={`text-amber-600 ${
                    discounted_price? "line-through" : ""
                  }`}
                >
                  ${price}
                </span>
              </p>
            </div>
            <button
              disabled={count_in_stock === 0}
              className={`w-full px-4 py-2 border border-orange-500 text-center text-sm rounded-md transition hover:shadow-md disabled:cursor-not-allowed whitespace-nowrap 
                ${count_in_stock === 0 ? "" : "bg-white text-gray-700"}
                hover:bg-orange-500 hover:text-white border-[--primary-text-color]`}
              onClick={() => {
                if (!isInCart) {
                  handleAddToCart(product);
                } else {
                  navigate("/cartpage");
                }
              }}
            >
              {count_in_stock === 0
                ? "OUT OF STOCK"
                : isInCart
                ? "Go to Cart"
                : "Add to Cart"}
            </button>
          </div>
        </>
      ) : (
        <>
          <Skeleton
            height={300}
            width={300}
            className="w-full h-full bg-gray-400"
          />{" "}
          <Skeleton height={6} width={300} />
          <Skeleton height={80} width={300} />
          <div className="flex items-center justify-center">
            <Skeleton height={10} width={50} />
            <Skeleton height={10} width={50} />
            <Skeleton height={10} width={50} />
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
