import React, { useState } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import { Modal } from "../Category/Modal";
import UpdateCategoryForm from "../Category/UpdateCategoryForm";
import DeleteCategory from "../Category/DeleteCategory";
import DeleteSubCategory from "../Category/DeleteSubCategory";
import UpdateSubcategoryForm from "../Category/UpdateSubcategoryForm";
import { useQuery } from "@tanstack/react-query";
import { getAllCategoryWithSubcategoryService } from "../../api/apiServices";

const CategoryTable = () => {
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [modalTitle, setModalTitle] = useState("");

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["categories"],
    queryFn: getAllCategoryWithSubcategoryService,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [updateCategory, setUpdateCategory] = useState({});
  const [updateSubcategory, setUpdateSubcategory] = useState({});
  const [deleteCategory, setDeleteCategory] = useState("");
  const [deleteSubCategory, setDeleteSubCategory] = useState("");

  const [viewTotalSubcategories, setViewTotalSubcategories] = useState(5);

  const categoriesWithSub = data?.data;

  const handleDeleteCategory = (categoryId) => {
    setDeleteCategory(categoryId);
    setModalTitle("Delete Category");
    setIsOpen(true);
  };

  const handleDeleteSubCategory = (subcategoryId) => {
    setDeleteSubCategory(subcategoryId);
    setModalTitle("Delete Subcategory");
    setIsOpen(true);
  };

  const handleEditSubcategory = (subCategory) => {
    setModalTitle("Edit Subcategory");
    setUpdateSubcategory(subCategory);
    setIsOpen(true);
  };

  const handleUpdateCategory = (category) => {
    setUpdateCategory(category);
    setModalTitle("Edit Category");
    setIsOpen(true);
  };

  const toggleCategoryExpansion = (categoryId) => {
    if (expandedCategories.includes(categoryId)) {
      setExpandedCategories(
        expandedCategories.filter((id) => id !== categoryId)
      );
    } else {
      setExpandedCategories([...expandedCategories, categoryId]);
    }
  };

  const isCategoryExpanded = (categoryId) =>
    expandedCategories.includes(categoryId);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="relative overflow-x-auto">
        <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-4 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Subcategories
                </th>
                <th className="px-4 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {categoriesWithSub.map((category) => (
                <tr key={category.id}>
                  <td className="px-6 py-4 border-t border-gray-200">
                    {category.title}
                  </td>
                  <td
                    className={`flex flex-wrap gap-2 ${
                      !viewTotalSubcategories > 6 && "space-x-2"
                    } items-center px-6 py-4 border-t border-gray-200`}
                  >
                    {categoriesWithSub &&
                      category.subcategories &&
                      (isCategoryExpanded(category.id)
                        ? category.subcategories
                        : category.subcategories.slice(
                            0,
                            viewTotalSubcategories
                          )
                      ).map((subCategory) => (
                        <div
                          key={subCategory.id}
                          className="flex items-center text-white text-xs bg-gradient-to-r px-2 py-1 rounded-md bg-slate-600"
                        >
                          <span>{subCategory.title}</span>
                          <FaEdit
                            className="ml-2 cursor-pointer text-white hover:text-blue-700"
                            onClick={() => handleEditSubcategory(subCategory)}
                          />
                          <FaTrash
                            className="ml-2 cursor-pointer text-white"
                            onClick={() =>
                              handleDeleteSubCategory(subCategory.id)
                            }
                          />
                        </div>
                      ))}
                    {category.subcategories &&
                      category.subcategories.length > 5 && (
                        <span
                          className="text-xs text-gray-600 font-light cursor-pointer"
                          onClick={() => toggleCategoryExpansion(category.id)}
                        >
                          {isCategoryExpanded(category.id)
                            ? `- Less`
                            : `+${category.subcategories.length - 5} more`}
                        </span>
                      )}
                  </td>
                  <td className="px-6 py-4 border-t border-gray-200">
                    <button
                      className="text-red-600 hover:text-red-800"
                      onClick={() => handleUpdateCategory(category)}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="text-red-600 hover:text-red-800"
                      onClick={() => handleDeleteCategory(category.id)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal title={modalTitle} isOpen={isOpen} setIsOpen={setIsOpen}>
        {modalTitle === "Edit Category" && (
          <UpdateCategoryForm
            setIsOpen={setIsOpen}
            updateCategory={updateCategory}
          />
        )}

        {modalTitle === "Edit Subcategory" && (
          <UpdateSubcategoryForm
            setIsOpen={setIsOpen}
            updateSubcategory={updateSubcategory}
            categoryWithSub={data.data}
          />
        )}

        {modalTitle === "Delete Category" && (
          <DeleteCategory
            setIsOpen={setIsOpen}
            deleteCategory={deleteCategory}
          />
        )}

        {modalTitle === "Delete Subcategory" && (
          <DeleteSubCategory
            setIsOpen={setIsOpen}
            deleteSubCategory={deleteSubCategory}
          />
        )}
      </Modal>
    </div>
  );
};

export default CategoryTable;
