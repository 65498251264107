import { Disclosure } from '@headlessui/react'
import { FiChevronUp } from 'react-icons/fi'
import { faq as FaqData } from '../../utils/data'
import BackToHome from './BackToHome'
import useScrollToTop from '../../hooks/useScrollToTop'

const Faq = () => {
  useScrollToTop()
  return (
    <>
      <div className='max-w-5xl text-sm mx-auto mt-6 md:mt-7 p-4 text-justify tracking-wide'>
        <BackToHome />
        <h2 className='text-2xl font-bold mb-4 text-center'>PRIVACY POLICY</h2>
        <div className='w-full  mx-auto  rounded-2xl bg-white p-2 mb-5'>
          {FaqData.map((faqItem, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <>
                  <Disclosure.Button className='flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                    <span className='text-base font-bold'>
                      {faqItem.question}
                    </span>
                    <FiChevronUp
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-purple-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className='px-4 pt-4 pb-2 text-sm text-slate-600'>
                    {faqItem.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </>
  )
}

export default Faq
