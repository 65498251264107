import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { uploadFeatureImageService } from '../../api/apiServices'
import { notify } from '../../utils/utils'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const AddFeatureImage = ({ setAddFeatureImage }) => {
  const [categoryType, setCategoryType] = useState('')
  const [mediaType, setMediaType] = useState('')

  const validationSchema = Yup.object().shape({
    category_type: Yup.string().required('Required type'),
    media_type: Yup.string().required('Required type'),
  })
  const queryClient = useQueryClient()

  const { mutate: uploadImages, isLoading: isUploading } = useMutation(
    uploadFeatureImageService,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('featureImages')
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      media_type: '',
      category_type: '',
      images: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      uploadImages(values)
      setAddFeatureImage(false)
      notify(
        `Feature ${mediaType} ${categoryType}  uploaded successfully`,
        'success'
      )
    },
  })

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files)
    formik.setFieldValue('images', files)
  }

  return (
    <>
      <div className='mx-auto w-full'>
        <div className='mx-auto'>
          <div className='grid grid-cols-5 gap-8'>
            <div className='col-span-5 md:col-span-3 xl:col-span-5'>
              <div className='rounded-sm  bg-white dark:border-strokedark dark:bg-boxdark'>
                <div className='p-2'>
                  <form
                    encType='multipart/form-data'
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='mb-5.5'>
                      <div className='flex flex-col space-y-2'>
                        <div className='relative'>
                          <label
                            className={`mb-3 block text-sm font-medium text-black dark:text-white'
                          htmlFor='category_type ${
                            formik.errors.media_type ? 'text-red-500' : ''
                          }`}
                          >
                            {formik.errors.media_type &&
                            formik.touched.media_type
                              ? formik.errors.media_type
                              : 'Media Type'}
                          </label>
                          <select
                            className='w-full rounded border border-stroke bg-gray text-black focus:border-primary focus-visible:outline-none dark:border-strokedark  dark:text-white dark:focus:border-primary'
                            id='media_type'
                            showSearch
                            placeholder='Select'
                            optionFilterProp='children'
                            value={formik.values.media_type}
                            onChange={(e) => {
                              formik.handleChange(e)
                              setMediaType(e.target.value)
                            }}
                          >
                            <option value='' disabled>
                              Select Media Type
                            </option>
                            <option value='IMAGE'>Image</option>
                            <option value='VIDEO'>Video</option>
                          </select>
                        </div>
                        <div className='relative'>
                          <label
                            className={`mb-3 block text-sm font-medium text-black dark:text-white'
                          htmlFor='category_type ${
                            formik.errors.category_type ? 'text-red-500' : ''
                          }`}
                          >
                            {formik.errors.category_type &&
                            formik.touched.category_type
                              ? formik.errors.category_type
                              : 'Category Type'}
                          </label>
                          <select
                            className='w-full rounded border border-stroke bg-gray text-black focus:border-primary focus-visible:outline-none dark:border-strokedark  dark:text-white dark:focus:border-primary'
                            id='category_type'
                            showSearch
                            placeholder='Select'
                            optionFilterProp='children'
                            value={formik.values.category_type} // Update this line
                            onChange={(e) => {
                              formik.handleChange(e)
                              setCategoryType(e.target.value)
                            }}
                          >
                            <option value='' disabled>
                              Select Role
                            </option>
                            <option value='ECOMMERCE'>ECOMMERCE</option>
                            <option value='OTHER'>OTHER</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='mb-5.5'>
                      <div className='mb-3 block text-sm font-medium text-black dark:text-white '>
                        <div className='mb-4 flex flex-col bg-transparent  py-3 '>
                          <label
                            className='block mb-2 text-base  font-medium text-gray-900 dark:text-white'
                            htmlFor='large_size'
                          >
                            Choose {mediaType === 'IMAGE' ? 'image' : 'video'}{' '}
                            for promotion
                          </label>
                          <input
                            className='block w-full text-base text-green-500  dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                            type='file'
                            name='images[]'
                            multiple
                            accept={
                              mediaType === 'IMAGE' ? 'image/*' : 'video/*'
                            }
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        type='submit'
                        className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primarydark'
                      >
                        {isUploading ? 'Uploading...' : 'Upload'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddFeatureImage
