import React from 'react'
import { Outlet } from 'react-router'
import { Navbar } from '../components/Navbar/Navbar'

const OtherLayout = () => {
  return (
    <main className='flex flex-col '>
      <Navbar />
      <div className='bg-white h-full mt-20 main-content flex-1'>
        <Outlet />
      </div>
    </main>
  )
}

export default OtherLayout
