import React from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
// import usePagination from '../../hooks/usePagination'

const Pagination = ({ currentPage, totalPages, totalCount, onPageChange }) => {
  return (
    <div className='flex justify-between items-center px-4'>
      <div className='flex space-x-2'>
        <span className='text-sm font-medium text-gray-700 dark:text-gray-200'>
          Showing
        </span>
        <span className='text-sm font-medium text-gray-700 dark:text-gray-200'>
          {currentPage}
        </span>
        <span className='text-sm font-medium text-gray-700 dark:text-gray-200'>
          to
        </span>
        <span className='text-sm font-medium text-gray-700 dark:text-gray-200'>
          {totalPages}
        </span>
      </div>

      <div className='flex items-end space-x-2'>
        <button
          className={`px-3 py-1 rounded-md${
            currentPage === 1
              ? 'cursor-not-allowed opacity-50'
              : 'cursor-pointer'
          }`}
          onClick={() => {
            onPageChange(currentPage - 1)
          }}
          disabled={currentPage === 1}
        >
          <FiChevronLeft />
        </button>
        <span>{currentPage}</span>
        <button
          className={`px-3 py-1 rounded-md  ${
            currentPage === totalPages
              ? 'cursor-not-allowed opacity-50'
              : 'cursor-pointer'
          }`}
          onClick={() => {
            onPageChange(currentPage + 1)
          }}
          disabled={currentPage === totalPages}
        >
          <FiChevronRight />
        </button>
      </div>
    </div>
  )
}

export default Pagination
