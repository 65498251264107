import React from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import cartStore from '../../zustand/cartStore'
import { BsTrashFill } from 'react-icons/bs'
import { notify } from '../../utils/utils'

const CartItemCard = ({ product }) => {
  const [qty, setQty] = React.useState(product.qty)
  const { product_id, name, price, image } = product

  const removeFromCart = cartStore((state) => state.removeFromCart)
  const updateQuantity = cartStore((state) => state.updateQuantity)

  const increaseQty = (product_id) => {
    // compare qty with count_in_stock and return if qty is greater than count_in_stock
    if (qty >= product.count_in_stock) {
      notify('error', 'out of stock')

      return
    }

    // check if price is greather than 50
    const newQty = qty + 1
    setQty(newQty)
    updateQuantity(product_id, newQty)
  }

  const decreaseQty = (product_id) => {
    if (qty <= 1) {
      notify('error', 'Quantity cannot be less than 1');
      
      return 
    }
    const newQty = qty - 1
    setQty(newQty)
    updateQuantity(product_id, newQty)
  }

  const handleRemoveFromCart = (product_id) => {
    removeFromCart(product_id)
  }

  return (
    <>
      <div className='flex flex-col space-y-2 md:space-y-0 md:flex-row w-full my-5 h-auto items-center justify-between'>
        <div className='product flex self-start '>
          <img
            className=' w-[100px] h-[100px] md:w-[120px] md:h-[120px] object-fill rounded-lg shadow-lg hover:scale-[1.1] duration-300 m-3'
            src={image}
            alt={name}
          />
          <div className='description flex flex-col ml-2 space-y-1 mt-3 h-[130px] justify-between '>
            <p className='font-medium tracking-widest  text-base'> {name}</p>
            <p className='text-gray-600'>
              Price:
              <span className='text-gray-600 ml-1'> ${price}</span>
            </p>
            <div className='flex text-sm '>
              <button
                onClick={() => handleRemoveFromCart(product_id)}
                type='btn'
                className='flex items-center shadow-md rounded-md bg-slate-100 hover:bg-slate-200 space-x-2 px-2 py-1 pl-0'
              >
                <BsTrashFill
                  className='text-red-500 hover:text-red-400'
                  size={16}
                />
                <span className='text-red-500'>Remove</span>
              </button>
            </div>
          </div>
        </div>
        <div className='flex  text-heading mb-2.5 font-semibold capitalize text-2px md:text-lg'>
          <span className='text-gray-700'>Quantity: </span>
          <div className='ml-5 shadow-md flex'>
            <button
              className='bg-success text-white w-6 items-center justify-center flex cursor-pointer rounded-l-md'
              onClick={() => decreaseQty(product_id)}
            >
              <AiOutlineMinus />
            </button>
            <span className='w-6 flex items-center justify-center border-[1px]'>
              {qty}
            </span>
            <button
              className='bg-green-700 text-white w-6 items-center justify-center flex cursor-pointer rounded-r-md'
              onClick={() => increaseQty(product_id)}
            > 
              <AiOutlinePlus />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CartItemCard
