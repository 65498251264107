import { useFormik } from 'formik'
import * as Yup from 'yup'
import { notify } from '../../utils/utils'
import { useMutation } from '@tanstack/react-query' // Assuming this is the correct import path
import { sendemailServiceInformation } from '../../api/apiServices'

const AddServiceCustomerForm = ({ service, setIsOpen }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    phone: Yup.string().required('Phone is required'),
  })

  const mutation = useMutation(sendemailServiceInformation, {
    onSuccess: () => {},
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        mutation.mutate({
          name: values.name,
          email: values.email,
          phone: values.phone,
          service: {
            name: service.name,
            description: service.description,
            id: service.id,
          },
        })
        setIsOpen(false)
        resetForm()
      } catch (error) {
        notify('error', 'An error occurred')
      }
    },
  })

  return (
    <div>
      <div className='mx-auto'>
        <h1 className='text-lg text-center'>Add Service Information</h1>
        <div className='grid grid-cols-5 gap-8'>
          <div className='col-span-5 md:col-span-3 xl:col-span-5'>
            <div className='rounded-sm bg-white'>
              <form onSubmit={formik.handleSubmit}>
                <div className='mb-5.5'>
                  <label
                    className={`mb-3 block text-sm font-medium text-black ${
                      formik.errors.name && formik.touched.name
                        ? 'text-red-500'
                        : ''
                    }`}
                    htmlFor='name'
                  >
                    {formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : 'Name'}
                  </label>
                  <input
                    className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                    name='name'
                    value={formik.values.name} // Corrected value access
                    type='text'
                    onChange={formik.handleChange}
                    id='name'
                  />
                </div>
                <div className='mb-5.5'>
                  <label
                    className={`mb-3 block text-sm font-medium text-black ${
                      formik.errors.phone && formik.touched.phone
                        ? 'text-red-500'
                        : ''
                    }`}
                    htmlFor='phone'
                  >
                    {formik.errors.phone && formik.touched.phone
                      ? formik.errors.phone
                      : 'phone'}
                  </label>
                  <input
                    className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark.bg-meta-4 dark.text-white dark:focus-border-primary'
                    name='phone'
                    value={formik.values.phone} // Corrected value access
                    type='text'
                    onChange={formik.handleChange}
                    id='phone'
                  />
                </div>
                <div className='mb-5.5'>
                  <label
                    className={`mb-3 block text-sm font-medium text-black ${
                      formik.errors.email && formik.touched.email
                        ? 'text-red-500'
                        : ''
                    }`}
                    htmlFor='email'
                  >
                    {formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : 'Email'}
                  </label>
                  <input
                    className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark.bg-meta-4 dark.text-white dark:focus-border-primary'
                    name='email'
                    value={formik.values.email} // Corrected value access
                    type='text'
                    onChange={formik.handleChange}
                    id='email'
                  />
                </div>
                <div className='flex justify-end gap-4.5'>
                  <button
                    className='flex justify-center rounded bg-green-500 py-2 px-6 font-medium text-gray hover:bg-opacity-95'
                    type='submit'
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddServiceCustomerForm
