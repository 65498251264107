export const faq = [
  {
    question: 'What are the delivery options available?',
    answer:
      'We offer both standard and express delivery options. Standard delivery usually takes 2-5 business days, while express delivery can arrive within 1-2 business days.',
  },
  {
    question: 'Is there a minimum order amount for delivery?',
    answer:
      'Yes, there is a minimum order amount of $50 for delivery. Orders below this amount can be picked up from our nearest store location.',
  },
  {
    question: 'Do you deliver to all locations in Australia?',
    answer:
      'We deliver to most locations in Australia. You can check if we deliver to your area by entering your postcode during checkout.',
  },
  {
    question: 'What are the accepted payment methods?',
    answer:
      'We accept payments through major credit cards, debit cards, and PayPal. We also offer the option for cash on delivery.',
  },
  {
    question: 'How can I track my order?',
    answer:
      'Once your order is dispatched, you will receive a tracking link via email or SMS. You can click on the link to track the status of your delivery.',
  },
  {
    question: 'What is your return policy?',
    answer:
      "We have a hassle-free 30-day return policy. If you're not satisfied with your purchase, you can return it within 30 days for a full refund or exchange.",
  },
  {
    question: 'Are there any delivery charges?',
    answer:
      'Delivery charges vary depending on your location and the order total. You can view the delivery charges during the checkout process before confirming your order.',
  },
  {
    question: 'Do you offer discounts or promotions?',
    answer:
      'Yes, we regularly run discounts and promotions on various products. Keep an eye on our website and subscribe to our newsletter to stay updated on the latest offers.',
  },
  {
    question: "Can I modify or cancel my order after it's been placed?",
    answer:
      'You can modify or cancel your order within the first hour of placing it. After that, please contact our customer support for assistance.',
  },
  {
    question: 'How can I contact customer support?',
    answer:
      "You can reach our customer support team by calling our toll-free number at 1-800-123-4567 or by sending an email to support@example.com. We're available 24/7 to assist you.",
  },
]