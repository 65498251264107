import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addCategoryService } from '../../api/apiServices'

const AddCategoryForm = ({ setIsOpen }) => {
  const [newCategory, setNewCategory] = useState('')
  const queryClient = useQueryClient()

  const mutation = useMutation(addCategoryService, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('categories')
    },
  })

  const handleNewCategoryChange = (e) => {
    setNewCategory(e.target.value)
  }

  const handleCategorySubmit = (e) => {
    e.preventDefault()

    mutation.mutate(newCategory)

    setIsOpen(false)
    // Clear the input field after submitting
    setNewCategory('')
  }

  return (
    <>
      <form onSubmit={handleCategorySubmit}>
        <div className='flex flex-col space-y-4 '>
          <input
            type='text'
            placeholder='New Category'
            value={newCategory}
            onChange={handleNewCategoryChange}
            className='px-4 py-2 w-full border border-gray-300 rounded-l '
          />
          <button
            type='submit'
            className='px-4 py-2 bg-success w-full text-white rounded-r hover:bg-blue-600 transition-colors flex-1'
          >
            Add Category
          </button>
        </div>
      </form>
    </>
  )
}

export default AddCategoryForm
