import React from 'react'
import cartStore from '../../zustand/cartStore'

const PriceCard = () => {
  const cartItems = cartStore((state) => state.cartItems)

  return (
    <>
      {cartItems.map((item) => (
        <div key={item.product_id} className=' flex  md:justify-between  '>
          <p className=' text-gray-600 mb-2 flex-1'>
            {item.name}  ({item.qty})item X
          </p>
          <div className='flex flex-col items-center justify-center'>
            <p className='space-x-2'>
              <span
                className={`text-sm text-gray-600 ${
                  item.price && item.discounted_price
                }`}
              >
                ${item.price}
              </span>
            </p>
          </div>
        </div>
      ))}
    </>
  )
}

export default PriceCard
