import React, { useState } from 'react'
import { deleteCategoryService } from '../../api/apiServices'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const DeleteCategory = ({ deleteCategory, setIsOpen }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const queryClient = useQueryClient()

  const mutation = useMutation(deleteCategoryService, {
    onSuccess: () => {
      queryClient.invalidateQueries('categories')
    },
  })

  const handleDelete = async () => {
    mutation.mutate(deleteCategory)

    setShowConfirmation(false)
    setIsOpen(false)
  }

  const handleCancel = () => {
    setShowConfirmation(false)
  }

  const showDeleteConfirmation = () => {
    setShowConfirmation(true)
  }

  return (
    <>
      <button
        onClick={showDeleteConfirmation}
        className='px-4 py-2 bg-danger text-white rounded hover:bg-red-600 transition-colors'
      >
        Delete
      </button>
      {showConfirmation && (
        <div className='fixed inset-0 flex items-center justify-center z-50'>
          <div className='bg-white p-4 rounded-lg shadow-md'>
            <p className='text-lg font-semibold mb-4'>
              Are you sure you want to delete this category?
            </p>
            <div className='flex space-x-4'>
              <button
                onClick={handleDelete}
                className='px-4 py-2 bg-danger text-white rounded hover:bg-red-600 transition-colors'
              >
                Yes, Delete
              </button>
              <button
                onClick={handleCancel}
                className='px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition-colors'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DeleteCategory
