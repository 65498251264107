import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getOrderByIDService,
  updateOrderStatus,
  updatePaymentStatus,
} from "../../api/apiServices";
import { notify } from "../../utils/utils";
import MainLoader from "../../components/MainLoader/MainLoader";
import useAuthStore from "../../zustand/userStore";

const OrderDetail = () => {
  const param = useParams();
  const [selectedShippingStatus, setSelectedShippingStatus] = useState("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");

  const user = useAuthStore((state) => state.user);

  const isAdminOrEditor =
    user && (user.role === "ADMIN" || user.role === "EDITOR");

  const handlePaymentChange = (event) => {
    setSelectedPaymentStatus(event.target.value);
  };

  const handleShippingStatusChange = (event) => {
    setSelectedShippingStatus(event.target.value);
  };

  const queryClient = useQueryClient();

  const { isLoading: orderLoading, data } = useQuery({
    queryKey: ["order", param.id],
    queryFn: () => getOrderByIDService(param.id),
  });

  const order = data?.data.order;

  const updateOrderStatusMutation = useMutation(updateOrderStatus, {
    onSuccess: async () => {
      queryClient.invalidateQueries(["order"]);
      await getOrderByIDService(param.id);
      notify(
        "success",
        `Successfully updated order status to ${selectedShippingStatus}`,
        100
      );
    },
    onError: (error) => {
      notify("error", error.response.data.message, 100);
    },
  });

  const updatePaymentStatusMutation = useMutation(updatePaymentStatus, {
    onSuccess: async () => {
      queryClient.invalidateQueries(["order"]);
      await getOrderByIDService(param.id);
      notify(
        "success",
        `Successfully updated payment status to ${selectedPaymentStatus}`,
        100
      );
    },
    onError: (error) => {
      notify("error", error.response.data.message, 100);
    },
  });

  const handleUpdatePaymentStatus = async () => {
    try {
      updatePaymentStatusMutation.mutate({
        order_id: order.order_id,
        payment_status: selectedPaymentStatus,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateShippingStatus = async () => {
    try {
      updateOrderStatusMutation.mutate({
        order_id: order.order_id,
        shipping_status: selectedShippingStatus,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  if (orderLoading) return <MainLoader />;

  return (
    <div className="my-5 mx-auto px-2">
      <div className="py-3 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
        <button className="underline text-xl mb-3 text-red-500" onClick={handleBack}>Back</button>
        <div className="flex justify-start item-start space-y-1 flex-col md:flex-row md:justify-between">
          <h1 className="text-xl lg:text-2xl font-medium leading-7 lg:leading-9 text-gray-800">
            Order #{order?.order_id}
          </h1>
          <p className="text-base font-medium leading-6 text-gray-600">
            {new Date(order.createdAt).toLocaleString("en-US", {
              dateStyle: "long",
            })}
          </p>
        </div>
        <div className="mt-10 flex flex-col xl:flex-row justify-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-4 xl:space-y-0">
          <div className="flex shadow-1 flex-col pt-2 justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            <div className="flex flex-col justify-start items-start bg-gray-50 px-4 w-full">
              {order.order_items?.map((item, index) => (
                <div key={index} className="mt-2 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                  <div className="pb-4 md:pb-4 w-[90px] md:w-15">
                    <img className="w-full" src={item.product.images[0]?.url} alt={order.product?.name} />
                  </div>
                  <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full">
                    <div className="w-full flex flex-col justify-start items-start">
                      <h3 className="text-sm xl:text-base font-semibold leading-6 text-gray-800">
                        {item.product.name}
                      </h3>
                    </div>
                    <div className="flex justify-between space-x-2 items-start w-full">
                      <p className="text-base xl:text-lg leading-6">
                        ${item.product.price}
                      </p>
                      <p className="text-base xl:text-lg leading-6 text-gray-800">
                        {item.quantity}
                      </p>
                      <p className="text-base xl:text-lg font-semibold leading-6 text-gray-800">
                        ${item.product.price * item.quantity}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="md:flex hidden justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="flex flex-col justify-center px-4 py-2 md:p-4 xl:p-5 w-full bg-gray-50 space-y-6">
                <h3 className="text-xl font-semibold leading-5 text-gray-800">
                  Payment and shipping status
                </h3>
                <div className="flex flex-col space-y-6 justify-between items-start w-full">
                  <div className="leading-6">
                    payment method:
                    <span className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider p-2 text-center">
                      {order.payment.payment_method}
                    </span>
                  </div>
                  <p className="leading-6">
                    shipping status:
                    <span className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider p-2 text-center">
                      {order.shipping_status}
                    </span>
                  </p>
                  <div className="leading-6">
                    Payment status:
                    <span className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider p-2 text-center">
                      {order.payment.payment_status}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 mt-5 w-full xl:w-128 flex justify-between items-center md:items-start px-4 flex-col">
            <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
              <div className="flex flex-col w-full bg-gray-50 space-y-6">
                <h3 className="text-xl font-semibold leading-5 text-gray-800">
                  Summary
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Subtotal
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      ${order.total_amount - order?.payment.shippingFee}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Shipping
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      ${order?.payment.shippingFee}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base font-semibold leading-4 text-gray-800">
                    Total
                  </p>
                  <p className="text-base font-semibold leading-4 text-gray-600">
                    ${order.payment.amount}
                  </p>
                </div>
                {isAdminOrEditor && (
                  <>
                    <div className="flex flex-col space-y-4">
                      <label htmlFor="shippingStatusSelect" className="text-base font-medium leading-4 text-red-700">
                        Change Shipping Status:
                      </label>
                      <div className="flex items-center justify-between">
                        <select
                          id="shippingStatusSelect"
                          className="block px-2 py-2 bg-white border border-gray-300 rounded-md focus:outline-none sm:text-sm"
                          value={selectedShippingStatus}
                          onChange={handleShippingStatusChange}
                        >
                          <option value="" disabled>
                            Select status...
                          </option>
                          <option value="PENDING">Pending</option>
                          <option value="SHIPPED">Shipping</option>
                          <option value="DELIVERED">Delivered</option>
                          <option value="CANCELLED">Cancelled</option>
                        </select>
                        <button
                          className="bg-slate-600 text-white font-base py-2 px-4 rounded"
                          onClick={handleUpdateShippingStatus}
                        >
                          {updateOrderStatusMutation.isLoading
                            ? "updating..."
                            : "Change Status"}
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col space-y-4">
                      <label htmlFor="paymentStatusSelect" className="text-sm font-medium leading-4 text-red-700">
                        Change Payment Status:
                      </label>
                      <div className="flex items-center justify-between">
                        <select
                          id="paymentStatusSelect"
                          className="block w-40 px-4 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={selectedPaymentStatus}
                          onChange={handlePaymentChange}
                        >
                          <option value="" disabled>
                            Select status...
                          </option>
                          <option value="PAID">paid</option>
                          <option value="UNPAID">unpaid</option>
                        </select>
                        <button
                          className="bg-slate-600 text-white font-base py-2 px-4 rounded"
                          onClick={handleUpdatePaymentStatus}
                        >
                          {updatePaymentStatusMutation.isLoading
                            ? "updating..."
                            : "Change Status"}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="flex justify-between xl:h-full items-stretch w-full flex-col mt-6 md:mt-0">
                <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row items-center md:items-start">
                  <div className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8">
                    <p className="text-base font-semibold leading-4 text-center md:text-left text-gray-800">
                      Shipping Address
                    </p>
                    <div className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                      <div className="flex flex-col gap-2">
                        <p className="font-medium text-black dark:text-white">
                          <span className="font-semibold text-black-2 dark:text-white">
                            Name:
                          </span>
                          {order?.user?.name}
                        </p>
                        <p className="font-medium text-black dark:text-white">
                          <span className="font-semibold text-black-2 dark:text-white">
                            Phone number:
                          </span>
                          {order?.shipping_info?.phone_number}
                        </p>
                        <p className="font-medium text-black dark:text-white">
                          <span className="font-semibold text-black-2 dark:text-white">
                            Street_address:
                          </span>
                          {order?.shipping_info?.street_address}
                        </p>
                        <p className="font-medium text-black dark:text-white">
                          <span className="font-semibold text-black-2 dark:text-white">
                            State:
                          </span>
                          {order?.shipping_info?.state}
                        </p>
                        <p className="font-medium text-black dark:text-white">
                          <span className="font-semibold text-black-2 dark:text-white">
                            Suburb:
                          </span>
                          {order?.shipping_info?.suberb}
                        </p>
                        <p className="font-medium text-black dark:text-white">
                          <span className="font-semibold text-black-2 dark:text-white">
                            Country:
                          </span>
                          {order?.shipping_info?.country}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
