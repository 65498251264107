import React, { useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { showCategoriesService } from "../../api/apiServices";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

const CustomerSidebar = ({ isFilterOpen, setIsFilterOpen }) => {
  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  const { error, data } = useQuery({
    queryKey: ["categories"],
    queryFn: showCategoriesService,
    retryDelay: 0,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });

  const categoryData = data?.data;

  const handleCategoryClick = (name, categoryId) => {
    navigate(`/category/${name}/${categoryId}`);
  };

  const handleOtherService = () => {
    navigate("/other-services");
    setIsFilterOpen(false);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsFilterOpen(false);
    }
  };

  useEffect(() => {
    if (isFilterOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFilterOpen]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <aside
      ref={sidebarRef}
      className={`absolute left-0 px-5 pt-5 top-0 z-999 sm:hidden flex h-screen w-72 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0
        ${isFilterOpen ? "translate-x-0" : "-translate-x-full"}
    }`}
    >
      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <div className="flex justify-end items-end">
          <button
            className="px-2 text-end items-end text-white sm:hidden border-x-green-600"
            onClick={() => setIsFilterOpen(false)}
          >
            <AiOutlineClose className="text-xl" />
          </button>
        </div>
        {categoryData &&
          categoryData.map((category) => (
            <div
              key={category.category_id}
              className="group relative inline-block"
              onClick={() =>
                handleCategoryClick(category.name, category.category_id)
              }
            >
              <button className="px-4 py-2 text-white hover:text-white/90">
                {category.name}
              </button>
              <hr className="bg-red-600" />
            </div>
          ))}
        <div className="group relative inline-block">
          <button className="text-white mt-5 px-5" onClick={handleOtherService}>
            Other services
          </button>
        </div>
      </div>
    </aside>
  );
};

export default CustomerSidebar;
