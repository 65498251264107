// import PriceDetailsCard from "./PriceDetailsCard";
// import useCartStore from "../../zustand/cartStore";
// import { useCallback } from "react";

// const SummaryCard = ({ children }) => {
//   const cartItems = useCartStore((state) => state.cartItems);
//   const totalPrice = useCartStore((state) => state.totalPrice);

//   const amountdiscount = useCartStore((state) => state.amountdiscount);
//   const discountPriceTotal = useCartStore((state) => state.discountPriceTotal);
//   const deliveryCharge = useCartStore((state) => state.deliveryCharge);
//   const setTotalPrice = useCartStore((state) => state.setTotalPrice);
  
//   useCallback(() => {
//     setTotalPrice(parseFloat(deliveryCharge) + parseFloat(totalPrice));
//   }, [deliveryCharge, totalPrice, setTotalPrice,amountdiscount]);
  
//   // console.log("🚀 ~ SummaryCard ~ discountPriceTotal:", discountPriceTotal)
//   // console.log("totoal price is",totalPrice)

//   const totalSum = parseFloat(totalPrice) + parseFloat(discountPriceTotal)

//   console.log('totoal sm',totalSum.toFixed(2))

//   return (
//     <section className="py-3 w-full  md:w-[500px] md:py-7  md:px-4 lg:px-5 rounded-md shadow-sm bg-white/[0.7] flex flex-col gap-2  h-min">
//       <h1 className="text-xl font-bold text-center">Order Summary</h1>
//       {cartItems.map((item) => (
//         <div
//           className="flex flex-col  shadow-sm px-4 py-2 rounded-sm "
//           key={item.product_id} 
//         >
//           <div className="flex  items-center flex-wrap gap-3 w-full">
//             <div className="flex flex-1 items-center gap-2">
//               <div className=" bg-black/[0.075] h-14 w-14 rounded-md flex items-center">
//                 <img src={item.image} alt="" className="object-fit w-full" />
//               </div>
//               <div className="flex justify-between  items-center text-center w-full">
//                 <h2>{item.name}  {item.qty} X</h2>
//                 <div className="flex items-center justify-center">
//                   <p className="space-x-2">
//                     <span className={`text-sm text-gray-600 ${item.price}`}> 
//                       ${item.price}
//                     </span>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//       <hr />
//       {children}

//       <PriceDetailsCard
//         discountPrice={amountdiscount}
//         actualPriceOfCart={totalPrice}
//       />
//       <hr />
//       <div className="flex justify-between items-center">
//         <p className=" text-gray-600">Total</p>
//         <p className="text-2xl">${discountPriceTotal}</p>
//         {/* <p className="text-2xl">${totalSum.toFixed(2)}</p> */}
//       </div>
//     </section>
//   );
// };

// export default SummaryCard;


import PriceDetailsCard from "./PriceDetailsCard";
import useCartStore from "../../zustand/cartStore";
import { useEffect } from "react";

const SummaryCard = ({ children }) => {
  const cartItems = useCartStore((state) => state.cartItems);
  const totalPrice = useCartStore((state) => state.totalPrice);
  const amountdiscount = useCartStore((state) => state.amountdiscount);
  const discountPriceTotal = useCartStore((state) => state.discountPriceTotal);
  const deliveryCharge = useCartStore((state) => state.deliveryCharge);

  useEffect(() => {
    // This useEffect ensures the total price is updated whenever the delivery charge or amount discount changes
    const updatedTotalPrice = (parseFloat(totalPrice) - parseFloat(amountdiscount) + parseFloat(deliveryCharge)).toFixed(2);
    useCartStore.getState().setDiscountPrice(updatedTotalPrice);
  }, [totalPrice, amountdiscount, deliveryCharge]);

  return (
    <section className="py-3 w-full md:w-[500px] md:py-7 md:px-4 lg:px-5 rounded-md shadow-sm bg-white/[0.7] flex flex-col gap-2 h-min">
      <h1 className="text-xl font-bold text-center">Order Summary</h1>
      {cartItems.map((item) => (
        <div
          className="flex flex-col shadow-sm px-4 py-2 rounded-sm"
          key={item.product_id}
        >
          <div className="flex items-center flex-wrap gap-3 w-full">
            <div className="flex flex-1 items-center gap-2">
              <div className="bg-black/[0.075] h-14 w-14 rounded-md flex items-center">
                <img src={item.image} alt="" className="object-fit w-full" />
              </div>
              <div className="flex justify-between items-center text-center w-full">
                <h2>{item.name} {item.qty} X</h2>
                <div className="flex items-center justify-center">
                  <p className="space-x-2">
                    <span className={`text-sm text-gray-600 ${item.price}`}>
                      ${item.price}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <hr />
      {children}

      <PriceDetailsCard
        discountPrice={amountdiscount}
        actualPriceOfCart={totalPrice}
      />
      <hr />
      <div className="flex justify-between items-center">
        <p className="text-gray-600">Total</p>

        {totalPrice <=100 ? <p className="text-2xl">${discountPriceTotal}</p>:<p className="text-2xl">${totalPrice}</p> }
      </div>
    </section>
  );
};

export default SummaryCard;
