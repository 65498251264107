import React, { useState } from 'react'
import {
  addSubCategoryService,
  showCategoriesService,
} from '../../api/apiServices'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

const SubcategoryForm = ({ setIsOpen }) => {
  const [selectedCategory, setSelectedCategory] = useState('')
  const [newSubCategory, setNewCategory] = useState('')

  const queryClient = useQueryClient()

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['allcategories'],
    queryFn: showCategoriesService,
  })

  const categoryData = data?.data

  const mutation = useMutation(addSubCategoryService, {
    onSuccess: () => {
      queryClient.invalidateQueries('categories')
    },
  })

  const handleSelectedCategoryChange = (e) => {
    setSelectedCategory(e.target.value)
  }

  const handleNewCategoryChange = (e) => {
    setNewCategory(e.target.value)
  }

  const handleCategorySubmit = async (e) => {
    e.preventDefault()
    mutation.mutate({ newSubCategory, selectedCategory })
    setIsOpen(false)
    setNewCategory('')
  }

  if (isLoading) return <div>Loading...</div>

  if (isError) return <div>{error.message}</div>

  return (
    <>
      <form onSubmit={handleCategorySubmit}>
        <div className='flex flex-col space-y-4'>
          <select
            value={selectedCategory}
            onChange={handleSelectedCategoryChange}
            className='w-full px-4 py-2 border border-gray-300 mb-4 rounded'
          >
            <option value=''>Select Category</option>
            {categoryData.map((category) => (
              <option key={category.category_id} value={category.category_id}>
                {category.name}
              </option>
            ))}
          </select>
          <input
            type='text'
            placeholder='New Subcategory'
            value={newSubCategory}
            onChange={handleNewCategoryChange}
            className='px-4 py-2 w-full border border-gray-300 rounded-l'
          />
          <button
            type='submit'
            className='px-4 py-2 bg-success w-full text-white rounded-r hover:bg-blue-600 transition-colors flex-1'
          >
            {isLoading ? 'Loading...' : 'Add Subcategory'}
          </button>
        </div>
      </form>
    </>
  )
}

export default SubcategoryForm
