import React from 'react'
import { useNavigate } from 'react-router-dom'
import MainLoader from '../MainLoader/MainLoader'
import Pagination from '../Pagination/Pagination'
import productStore from '../../zustand/productStore'
import NoOrdersMessage from './NoOrdersMessage'

const OrderList = ({
  orders,
  totalCount,
  totalPages,
  handlePageChange,
  currentPage,
  loading,
}) => {
  const navigate = useNavigate()

  const search = productStore((state) => state.search)

  const getOrderStatusColor = (status) => {
    switch (status) {
      case 'STRIPE':
        return 'bg-red-500 text-white'
      case 'CASH_ON_DELIVERY':
        return 'bg-slate-600 text-white'
      case 'PENDING':
        return 'bg-orange-500 text-white'
      case 'SHIPPED':
        return 'bg-blue-200'
      case 'DELIVERED':
        return 'bg-green-500 text-white'
      case 'PAID':
        return 'bg-green-500 text-white'
      case 'UNPAID':
        return 'bg-red-200'
        case 'CANCEL':
          return 'bg-red-200'
      default:
        return
    }
  }

  const getOrderDetail = (id) => {
    navigate(`/order/${id}`)
  }

  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * 20 + index + 1
  }

  if (orders?.length === 0) return <NoOrdersMessage />

  return (
    <div className='rounded-sm border mt-0  border-stroke bg-white px-2 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark  xl:pb-1'>
      {loading ? (
        <>
          <MainLoader />
        </>
      ) : (
        <>
          <div className='relative overflow-x-auto mt-10 md:mt-0'>
            <table className='w-full text-sm text-left text-gray-800 dark:text-gray-400'>
              <thead className='text-xs text-black-2 uppercase bg-gray-400 dark:bg-black dark:text-slate-600'>
                <tr className='bg-gray-2 dark:bg-meta-4'>
                  <th className=' px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                    S.N
                  </th>
                  <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                    Date
                  </th>
                  <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                    Total Amount
                  </th>
                  <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                    payment Status
                  </th>
                  <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                    Shipping Status
                  </th>
                  <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                    Payment Method
                  </th>
                  <th className=' py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                    View order detail
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders
                  ?.filter((order) => {
                    return search === ''
                      ? order
                      : (
                          order?.payment.payment_method ||
                          order?.payment.payment_status ||
                          order?.payment.shipping_status
                        )
                          .toLowerCase()
                          .includes(search.toLowerCase())
                  })
                  ?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
                  ?.map((order, index) => (
                    <tr key={order?.order_id} className='p-2 '>
                      <td className='px-3 py-3 text-xs font-medium text-gray-800 uppercase tracking-wider'>
                        {calculateSerialNumber(index)}
                      </td>
                      <td className='px-3 py-3 text-xs font-medium text-gray-800 uppercase tracking-wider'>
                        {new Date(order?.createdAt).toLocaleDateString('en-US', {
                          dateStyle: 'long',
                        })}
                      </td>
                      <td className='px-3 py-3 text-xs font-medium text-gray-800 uppercase tracking-wider'>
                        ${order?.total_amount}
                      </td>
                      <td
                        className={`px-3 py-3 text-xs font-medium text-gray-800 uppercase tracking-wider p-2 text-center`}
                      >
                        <button
                          className={`px-3 rounded-e-md py-1 ${getOrderStatusColor(
                            order?.payment.payment_status
                          )}`}
                        >
                          {order.payment.payment_status}
                        </button>
                      </td>
                      <td
                        className={`px-3 py-3 text-xs font-medium text-gray-800 uppercase tracking-wider p-2 text-center`}
                      >
                        <button
                          className={` px-3 rounded-md py-1 ${getOrderStatusColor(
                            order.shipping_status
                          )}`}
                        >
                          {order?.shipping_status}
                        </button>
                      </td>
                      <td
                        className={`px-3 py-3 text-xs font-medium text-gray-800 uppercase tracking-wider  p-2 text-center`}
                      >
                        <button
                          className={` px-3 py-1 rounded-md ${getOrderStatusColor(
                            order?.payment.payment_method
                          )}`}
                        >
                          {order?.payment.payment_method}
                        </button>
                      </td>

                      <td className=' text-xs text-end px-4 font-medium text-gray-800 uppercase tracking-wider'>
                        <button
                          onClick={() => getOrderDetail(order.order_id)}
                          className={` inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium text-success bg-success border-[#eee] dark:border-strokedark `}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination
              onPageChange={handlePageChange}
              totalCount={totalCount}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default OrderList
