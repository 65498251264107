import React from 'react'

const NoOrdersMessage = () => {
  return (
    <div className='w-full h-full flex items-center justify-center mt-20 md:mt-10'>
      <div className='text-center'>
        <svg
          className='w-16 h-16 mx-auto text-gray-600'
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M12 6v6m0 0v6m0-6h6m-6 0H6'
          />
        </svg>
        <h2 className='text-2xl text-gray-600 mt-2'>No Orders Found</h2>
        <p className='text-gray-400 mt-2'>
          You don't have any orders at the moment.
        </p>
      </div>
    </div>
  )
}

export default NoOrdersMessage
