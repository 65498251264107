// import { create } from "zustand";

// const useCartStore = create((set) => ({
//   cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
//   totalQuantity: 0,
//   totalPrice: 0,
//   amountdiscount: 0,
//   discountPriceTotal: 0,
//   shippingAddress: {},
//   user: null,

//   shippingOption: "General",
//   deliveryCharge: process.env.REACT_APP_GENERAL_SHIPPING_RATE,
//   setShippingOption: (option) => {
//     set((state) => ({
//       shippingOption: option,
//     }));
//   },

//   setShippingAddress: (address) => {
//     set((state) => ({
//       shippingAddress: address,
//     }));
//   },

//   clearCart: () => {
//     set((state) => ({
//       cartItems: [],
//       totalQuantity: 0,
//       totalPrice: 0,
//       amountdiscount: 0,
//       discountPriceTotal: 0,
//       shippingAddress: {},
//       user: null,
//     }));
//     localStorage.removeItem("cartItems");
//   },

//   addToCart: (item) => {
//     if (item.discounted_price && item.price) {
//       item.price = item.discounted_price;
//     }
//     localStorage.setItem(
//       "cartItems",
//       JSON.stringify([...useCartStore.getState().cartItems, item]),
//     );
//     set((state) => {
//       const totalPrice =
//         parseFloat(state.totalPrice) + item.qty * parseFloat(item.price);
//       return {
//         cartItems: [...state.cartItems, item],
//         totalQuantity: state.totalQuantity + item.qty,
//         totalPrice: totalPrice.toFixed(2),
//       };
//     });
//   },

//   removeFromCart: (itemId) => {
//     localStorage.setItem(
//       "cartItems",
//       JSON.stringify(
//         useCartStore
//           .getState()
//           .cartItems.filter((c) => c.product_id !== itemId),
//       ),
//     );
//     set((state) => {
//       const updatedItems = state.cartItems.filter(
//         (c) => c.product_id !== itemId,
//       );
//       const updatedTotalQuantity = updatedItems.reduce(
//         (total, item) => total + item.qty,
//         0,
//       );
//       const updatedTotalPrice = updatedItems.reduce(
//         (total, item) => total + item.qty * item.price,
//         0,
//       );
//       return {
//         cartItems: updatedItems,
//         totalQuantity: updatedTotalQuantity,
//         totalPrice: updatedTotalPrice,
//       };
//     });
//   },

//   removeAllFromCart: () => {
//     set((state) => {
//       localStorage.removeItem("cartItems");
//       return {
//         cartItems: [],
//         totalQuantity: 0,
//         totalPrice: 0,
//       };
//     });
//   },

//   updateQuantity: (itemId, quantity) => {
//     if (quantity === 0) {
//       useCartStore.getState().removeFromCart(itemId);
//       return;
//     }

//     localStorage.setItem(
//       "cartItems",
//       JSON.stringify(
//         useCartStore
//           .getState()
//           .cartItems.map((c) =>
//             c.product_id === itemId ? { ...c, qty: quantity } : c,
//           ),
//       ),
//     );

//     set((state) => {
//       const updatedItems = state.cartItems.map((c) =>
//         c.product_id === itemId ? { ...c, qty: quantity } : c,
//       );
//       const updatedTotalQuantity = updatedItems.reduce(
//         (total, item) => total + item.qty,
//         0,
//       );

//       const updatedTotalPrice = updatedItems.reduce(
//         (total, item) => total + item.qty * item.price,
//         0,
//       );
//       localStorage.setItem("cartItems", JSON.stringify(updatedItems));

//       return {
//         cartItems: updatedItems,
//         totalQuantity: updatedTotalQuantity,
//         totalPrice: updatedTotalPrice.toFixed(2),
//       };
//     });
//   },

//   setDiscountPrice: (price) => {
//     console.log('price at fis',price)
//     set((state) => ({
//       discountPriceTotal: parseFloat(price).toFixed(2),
//     }));
//   },

//   setAmountDiscount: (price) => {
//     set((state) => ({
//       amountdiscount: parseFloat(price).toFixed(2),
//     }));
//   },

//   isInCart: (itemId) => {
//     return useCartStore
//       .getState()
//       .cartItems.some((c) => c.product_id === itemId);
//   },

//   setTotalPrice: (price) => {
//     set((state) => ({
//       totalPrice: parseFloat(price).toFixed(2),
//     }));
//   },

//   updateTotalApplyDiscount: (price) => {
//     set((state) => ({
//       totalPrice: parseFloat(price).toFixed(2),
//     }));
//   },

//   setTotalQuantity: (quantity) => {
//     set((state) => ({
//       totalQuantity: quantity,
//     }));
//   },

//   initializeCartFromLocalStorage: () => {
//     const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
//     const totalQuantity = cartItems.reduce(
//       (total, item) => total + item.qty,
//       0,
//     );
//     const totalPrice = cartItems.reduce(
//       (total, item) => total + item.qty * item.price,
//       0,
//     );

//     console.log(totalPrice, "total price...");

//     set((state) => ({
//       discountPriceTotal: (
//         parseFloat(totalPrice) + parseFloat(state.deliveryCharge)
//       ).toFixed(2),
//       cartItems,
//       totalQuantity,
//       totalPrice: totalPrice.toFixed(2),
//     }));
//   },
// }));

// useCartStore.getState().initializeCartFromLocalStorage();

// export default useCartStore;

import { create } from "zustand";

const useCartStore = create((set) => ({
  cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  totalQuantity: 0,
  totalPrice: 0,
  amountdiscount: 0,
  discountPriceTotal: 0,
  shippingAddress: {},
  user: null,

  shippingOption: "General",
  deliveryCharge: parseFloat(process.env.REACT_APP_GENERAL_SHIPPING_RATE),
  
  setShippingOption: (option) => {
    set((state) => {
      
      const deliveryCharge = option === "Express" 
        ? parseFloat(process.env.REACT_APP_EXPRESS_SHIPPING_RATE) 
        : parseFloat(process.env.REACT_APP_GENERAL_SHIPPING_RATE);
      
      const discountPriceTotal = (parseFloat(state.totalPrice) - parseFloat(state.amountdiscount) + deliveryCharge).toFixed(2);

      return {
        shippingOption: option,
        deliveryCharge: deliveryCharge,
        discountPriceTotal: discountPriceTotal,
      };
    });
  },

  setShippingAddress: (address) => {
    set((state) => ({
      shippingAddress: address,
    }));
  },

  clearCart: () => {
    set((state) => ({
      cartItems: [],
      totalQuantity: 0,
      totalPrice: 0,
      amountdiscount: 0,
      discountPriceTotal: 0,
      shippingAddress: {},
      user: null,
    }));
    localStorage.removeItem("cartItems");
  },

  addToCart: (item) => {
    if (item.discounted_price && item.price) {
      item.price = item.discounted_price;
    }
    localStorage.setItem(
      "cartItems",
      JSON.stringify([...useCartStore.getState().cartItems, item]),
    );
    set((state) => {
      const totalPrice = parseFloat(state.totalPrice) + item.qty * parseFloat(item.price);
      const discountPriceTotal = (totalPrice - parseFloat(state.amountdiscount) + parseFloat(state.deliveryCharge)).toFixed(2);
      
      return {
        cartItems: [...state.cartItems, item],
        totalQuantity: state.totalQuantity + item.qty,
        totalPrice: totalPrice.toFixed(2),
        discountPriceTotal: discountPriceTotal,
      };
    });
  },

  removeFromCart: (itemId) => {
    localStorage.setItem(
      "cartItems",
      JSON.stringify(
        useCartStore
          .getState()
          .cartItems.filter((c) => c.product_id !== itemId),
      ),
    );
    set((state) => {
      const updatedItems = state.cartItems.filter(
        (c) => c.product_id !== itemId,
      );
      const updatedTotalQuantity = updatedItems.reduce(
        (total, item) => total + item.qty,
        0,
      );
      const updatedTotalPrice = updatedItems.reduce(
        (total, item) => total + item.qty * item.price,
        0,
      );
      const discountPriceTotal = (updatedTotalPrice - parseFloat(state.amountdiscount) + parseFloat(state.deliveryCharge)).toFixed(2);
      
      return {
        cartItems: updatedItems,
        totalQuantity: updatedTotalQuantity,
        totalPrice: updatedTotalPrice.toFixed(2),
        discountPriceTotal: discountPriceTotal,
      };
    });
  },

  removeAllFromCart: () => {
    set((state) => {
      localStorage.removeItem("cartItems");
      return {
        cartItems: [],
        totalQuantity: 0,
        totalPrice: 0,
        discountPriceTotal: 0,
      };
    });
  },

  updateQuantity: (itemId, quantity) => {
    if (quantity === 0) {
      useCartStore.getState().removeFromCart(itemId);
      return;
    }

    localStorage.setItem(
      "cartItems",
      JSON.stringify(
        useCartStore
          .getState()
          .cartItems.map((c) =>
            c.product_id === itemId ? { ...c, qty: quantity } : c,
          ),
      ),
    );

    set((state) => {
      const updatedItems = state.cartItems.map((c) =>
        c.product_id === itemId ? { ...c, qty: quantity } : c,
      );
      const updatedTotalQuantity = updatedItems.reduce(
        (total, item) => total + item.qty,
        0,
      );

      const updatedTotalPrice = updatedItems.reduce(
        (total, item) => total + item.qty * item.price,
        0,
      );
      const discountPriceTotal = (updatedTotalPrice - parseFloat(state.amountdiscount) + parseFloat(state.deliveryCharge)).toFixed(2);

      localStorage.setItem("cartItems", JSON.stringify(updatedItems));

      return {
        cartItems: updatedItems,
        totalQuantity: updatedTotalQuantity,
        totalPrice: updatedTotalPrice.toFixed(2),
        discountPriceTotal: discountPriceTotal,
      };
    });
  },

  setDiscountPrice: (price) => {
    set((state) => ({
      discountPriceTotal: parseFloat(price).toFixed(2),
    }));
  },

  setAmountDiscount: (price) => {
    set((state) => ({
      amountdiscount: parseFloat(price).toFixed(2),
    }));
  },

  isInCart: (itemId) => {
    return useCartStore
      .getState()
      .cartItems.some((c) => c.product_id === itemId);
  },

  setTotalPrice: (price) => {
    set((state) => ({
      totalPrice: parseFloat(price).toFixed(2),
    }));
  },

  updateTotalApplyDiscount: (price) => {
    set((state) => ({
      totalPrice: parseFloat(price).toFixed(2),
    }));
  },

  setTotalQuantity: (quantity) => {
    set((state) => ({
      totalQuantity: quantity,
    }));
  },

  initializeCartFromLocalStorage: () => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const totalQuantity = cartItems.reduce(
      (total, item) => total + item.qty,
      0,
    );
    const totalPrice = cartItems.reduce(
      (total, item) => total + item.qty * item.price,
      0,
    );

    const deliveryCharge = parseFloat(process.env.REACT_APP_GENERAL_SHIPPING_RATE);
    const discountPriceTotal = (parseFloat(totalPrice) - parseFloat(0) + deliveryCharge).toFixed(2); // Assuming amountdiscount is 0 initially

    set(() => ({
      cartItems,
      totalQuantity,
      totalPrice: totalPrice.toFixed(2),
      discountPriceTotal: discountPriceTotal,
      deliveryCharge: deliveryCharge,
    }));
  },
}));

useCartStore.getState().initializeCartFromLocalStorage();

export default useCartStore;
