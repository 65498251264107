import useCartStore from "../../zustand/cartStore";

const PriceDetailsCard = ({ actualPriceOfCart, discountPrice }) => {
  const setDiscountPrice = useCartStore((state) => state.setDiscountPrice);
  const totalPrice = useCartStore((state) => state.totalPrice);
  const amountdiscount = useCartStore((state) => state.amountdiscount);
  const setShippingOption = useCartStore((state) => state.setShippingOption);

  const handleShippingOption = (e) => {
    setShippingOption(e.target.value);
    if (e.target.value === "General") {
      setDiscountPrice(
        parseFloat(totalPrice) -
          amountdiscount +
          parseFloat(process.env.REACT_APP_GENERAL_SHIPPING_RATE),
      );
    } else if (e.target.value === "Express") {
      setDiscountPrice(
        parseFloat(totalPrice) -
          amountdiscount +
          parseFloat(process.env.REACT_APP_EXPRESS_SHIPPING_RATE),
      );
    }
    // setShippingOption(e.target.value);
  };

  const summaryData = [
    {
      label: "Subtotal",
      value: `${actualPriceOfCart}`,
    },
    {
      label: "Discount",
      value: `${discountPrice}`,
    },
  ];

  return (
    <>
      {summaryData.map(({ label, value }) => (
        <div key={label} className=" flex justify-between items-center p-0 ">
          <p className=" text-gray-600">{label}</p>
          <p className="text-lg">${value}</p>
        </div>
      ))}
      <div className="flex justify-between w-full">
        <div>Select Delivery options</div>
        <select
          onChange={handleShippingOption}
          name="options"
          id="options"
          className="focus:outline-none"
          defaultValue={"General"}
        >
          <option value="select" disabled defaultValue={"General"}>
            select{" "}
          </option>
          <option value="General">General</option>
          <option value="Express">Express</option>
        </select>
      </div>

      <div>
        <p className="text-red-500">NOTE: Delivery </p>
        <div className="space-y-1">
          <p className="text-xs">
            For general cost(
            {process.env.REACT_APP_GENERAL_SHIPPING_RATE}$) and takes within 2
            to 3 days{" "}
          </p>
          <p className="text-xs">
            for Express cost(
            {process.env.REACT_APP_EXPRESS_SHIPPING_RATE}$) and takes within 1
            to 2 days{" "}
          </p>
        </div>
      </div>
      {}
    </>
  );
};
export default PriceDetailsCard;
