import React from 'react'

const Title = ({ children }) => {
  return (
    <>
      <div className=' w-full flex items-center justify-between '>
        <div className='flex flex-col items-start justify-start gap-1'>
          <p className=' text-lg md:text-xl text-headingColor font-bold'>
            {children}
          </p>
          <div className='w-30 md:w-35 h-[2px] rounded-md bg-orange-500'></div>
        </div>
      </div>
    </>
  )
}

export default Title
