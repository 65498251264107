import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import { sendemailServiceInformation } from '../../api/apiServices'
import useAuthStore from '../../zustand/userStore'

const TermsConditions = ({ service, setIsOpen, handleChangeModalType }) => {
  const user = useAuthStore((state) => state.user)
  const navigate = useNavigate() // Initialize useNavigate

  const mutation = useMutation(sendemailServiceInformation, {
    onSuccess: () => {
      setIsOpen(true)
      navigate('/') // Redirect to homepage on success
    },
    onError: (error) => {
      console.log("error")
    }
  })

  const handleOtherService = async (service) => {
    if (user === null) {
      handleChangeModalType('not_verified')
      return
    }

    mutation.mutate({
      name: user.name,
      email: user.email,
      
      service: {
        name: service.name,
        description: service.description,
        id: service.id,
      },
    })
  }

  return (
    <div className='overflow-y-auto'>
      <div className='bg-gray-100 p-6 rounded-lg shadow-md text-justify'>
        <h1 className='text-2xl font-semibold mb-4'>
          Terms and Conditions for Referral “Nepsay Mart”
        </h1>
        <p className='mb-4'>
          1. <span className='font-semibold'>Introduction</span>
          <br />
          These Terms and Conditions govern your participation in our referral
          program (the "Program"). By participating in the Program, you agree to
          comply with these Terms and Conditions in their entirety. Please read
          them carefully before participating. All rights reserved to “Nepsay
          Mart”
        </p>
        <p className='mb-4'>
          2. <span className='font-semibold'>Eligibility</span>
          <br />
          To participate in the Program, you must be at least 18 years old. This
          Program is open to individuals who use the third-party services
          offered on our website ("Referrers") and those who are referred and
          subsequently become our customers ("Referees").
        </p>
        <p className='mb-4'>
          3. <span className='font-semibold'>Referral Process</span>
          <br />
          Customers may use the products or services of the third-party vendors
          by sharing their unique contact information with them upon signing up
          for the Program. Customers must sign up and agree to the terms and
          conditions mentioned herein for the referral program to be valid.
        </p>
        <p className='mb-4'>
          4. <span className='font-semibold'>Referral Reward</span>
          <br />
          If the customer signs up or makes a qualifying purchase of products or
          services from the third-party vendors, they will be eligible to claim
          a certain reward amount based on the services they engage in. This is
          all subject to meeting the conditions outlined in this section. The
          customer is required to provide evidence to “Nepsay Mart” for the
          services used to use any bonus vouchers as part of the service
          referral.
        </p>
        <p className='mb-4'>
          5. <span className='font-semibold'>Reward Condition</span>
          <br />
          The Referrer's reward will be provided as specified in our Program
          guidelines at the time of the referral. These rewards may include
          discount codes and vouchers for use within our Nepsay Mart website,
          which are subject to change at our discretion. The Customer will only
          be eligible for the reward if they have complied with these Terms and
          Conditions. The reward will also be provided according to our Program
          guidelines, which may vary depending on the specific offer at the time
          of referral. The reward will generally be granted after the Referee
          has met all eligibility criteria and fulfilled any qualifying
        </p>
      </div>

      <div className='mt-5'>
        <button
          className='bg-red-600 text-white w-auto px-4 py-2 rounded-full'
          onClick={() => handleOtherService(service)}
          disabled={mutation.isLoading}
        >
          {mutation.isLoading ? (
            <div className='flex items-center justify-center'>
              <div className='w-4 h-4 border-2 border-white rounded-full animate-spin'></div>
            </div>
          ) : (
            'Accept terms and conditions to continue'
          )}
        </button>
      </div>
    </div>
  )
}

export default TermsConditions
