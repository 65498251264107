import React, { useEffect, useState } from 'react'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { BsCheckLg } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { notify } from '../../utils/utils'
import { Helmet } from 'react-helmet'
import productStore from '../../zustand/productStore'
import useCartStore from '../../zustand/cartStore'
import SimilarProduct from './SimilarProduct'
import BackToHome from '../../components/Breadcrumb/BackToHome'
import useScrollToTop from '../../hooks/useScrollToTop'

function ProductDetail() {
  const param = useParams()
  const productName = param.id
  const [product, setProduct] = useState(null)
  const [selectedImage, setSelectedImage] = useState(  product?.productdetail?.images[0]?.url || '')

  useScrollToTop()

  const {
    productdetail: {
      product_id,
      name,
      price,
      category,
      images,
      count_in_stock,
    } = {},
  } = product || {}

  const getProductByName = productStore((state) => state.getProductByName)


  const addToCart = useCartStore((state) => state.addToCart)
  const cartitem = useCartStore((state) => state.cartItems)

  useEffect(() => {
    const fetchProduct = async () => {
      const fetchedProduct = await getProductByName(productName)
      setProduct(fetchedProduct)
    }
    fetchProduct()
  }, [productName, getProductByName])

  const handleAddToCart = () => {
    if (cartitem.find((item) => item.product_id === product_id)) {
      notify('error', 'Item already in cart')
      return
    } else {
      addToCart({
        product_id,
        name,
        price,
        category,
        qty: 1,
        image: images?.[0]?.url,
      })
    }
  }

  return (
    <>
      <Helmet>
        <meta charset='utf-8' />
        <title>{name || 'Product Detail'}</title>
        <meta name='description' content='product detail page' />
      </Helmet>

      <div className=' mt-0 flex justify-center items-center'>
        <main className='flex flex-col md:flex-row px-3 justify-center items-start gap-10 w-full max-w-7xl '>
          <BackToHome />
          <div className='flex flex-col md:flex-row gap-3 w-full'>
            <div className=' md:w-2/3 flex-row space-y-3 md:flex md:space-x-3'>
              <div className='flex md:flex-col items-center justify-center space-x-3 '>
                {product?.productdetail?.images.slice(0, 5).map((image) => (
                  <img
                    key={image.url}
                    src={image.url}
                    alt=''
                    className={`object-cover w-14 h-14 md:w-20 md:h-20 rounded-sm cursor-pointer p-1 ${
                      selectedImage === image.url ? 'ring-2 ring-amber-600' : ''
                    }`}
                    onClick={() => setSelectedImage(image.url)}
                  />
                ))}
              </div>
              <section className='bg-black/[0.075] flex items-center justify-center h-[400px] md:h-[500px] md:w-[500px]'>
                <img
                  src={
                    selectedImage ||
                    product?.productdetail?.images[0]?.url ||
                    ''
                  }
                  alt=''
                  className=' h-full w-full object-cover'
                />
              </section>
            </div>

            <section className='w-full max-w-2xl p-4 md:p-6 rounded-md shadow-sm bg-white/[0.7]'>
              <div className='flex flex-col gap-4'>
                <p className='text-green-500 space-x-2'>
                  <span className='inline-block'>
                    <BsCheckLg />
                  </span>
                  <span>
                    {count_in_stock > 0 ? (
                      'IN STOCK'
                    ) : (
                      <span className='bg-red-500 text-white p-1 rounded-md text-xs'>
                        {' '}
                        OUT OF STOCK
                      </span>
                    )}
                  </span>
                </p>
                <h1 className='text-md sm:text-3xl md:text-2xl font-bold'>
                  {product?.productdetail?.name}
                </h1>
                <h1 className='border-b text-blue-600 text-md'>Description</h1>
                <p className='text-gray-600 text-base'>
                  {product?.productdetail?.description}
                </p>
              </div>
              <div className='flex flex-col gap-4 mt-6'>
                <h2 className='border-b text-blue-600 text-md py-2 border-green-400 shadow-xs border-x-0'>
                  About Product
                </h2>
                <ul className='flex flex-col gap-2'>
                  {/* <li>
                    <span className='text-gray-500 text-sm'>
                      Total product in Stock:
                    </span>{' '}
                    {product?.productdetail?.count_in_stock}
                  </li> */}
                  <li>
                    <span className='text-gray-500 text-sm'>Category:</span>{' '}
                    {product?.productdetail?.category?.name}
                  </li>
                </ul>
              </div>

              <div className='flex items-center gap-2 mt-6'>
                <span className='text-base text-gray-500'>Price:</span>
                <span className={`text-xl sm:text-2xl text-amber-600`}>
                  {product?.productdetail?.discounted_price &&
                    product?.productdetail?.discounted_price}
                </span>
                <span
                  className={`text-xl sm:text-2xl text-gray-600 ${
                    product?.productdetail.price &&
                    product?.productdetail.discounted_price &&
                    'line-through'
                  }`}
                >
                  ${product?.productdetail.price}
                </span>
              </div>

              <div className='w-full flex flex-wrap gap-4 mt-6'>
                <button
                  disabled={count_in_stock === 0}
                  className='flex justify-center items-center bg-orange-500 hover:bg-orange-600 p-3 font-medium text-white border  text-center  py-1.5 text-sm  rounded-lg px-3 hover:bg-[--primary-text-color] hover:text-white transition hover:shadow-md disabled:cursor-not-allowed'
                  onClick={() => {
                    handleAddToCart(product)
                  }}
                >
                  <HiOutlineShoppingBag className='mr-2' /> Add to cart
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
      <SimilarProduct
        product={product?.productdetail?.product_id}
        categoryid={product?.productdetail?.category?.category_id}
      />
    </>
  )
}

export default ProductDetail
