import React, { Suspense, useEffect } from "react";
import { lazy } from "react";
import { Helmet } from "react-helmet";
import useProductStore from "../../zustand/productStore";
import useScrollToTop from "../../hooks/useScrollToTop";
import MainLoader from "../../components/MainLoader/MainLoader";

const ProductList = lazy(() => import("../product/ProductList"));
const TopProducts = lazy(() => import("../product/TopProducts"));
const CategoriesMenu = lazy(() =>
  import("../../components/Header/CategoriesMenu"),
);
// const CategoryListAutoSlide = lazy(() =>
//   import("../../components/Category/CategoryListAutoSlide"),
// );
const SearchResult = lazy(() => import("../../components/search/SearchResult"));
const EcommerceCarousel = lazy(() =>
  import("../../components/Carousel/EcommerceCarousel"),
);

function Home() {
  const { filteredProductsByNameAndCategory, setTopSellingProducts, TopSellingProducts } = useProductStore();

  useScrollToTop();
  useEffect(() => {
    console.log("Checking setTopSellingProducts:", setTopSellingProducts); // <-- log
    if (typeof setTopSellingProducts === 'function') {
      setTopSellingProducts();
    } else {
      console.error('setTopSellingProducts is not a function');
    }
  }, [setTopSellingProducts]);

  
  return (
    <section className="h-full">
      <Helmet>
        <meta charset="utf-8" />
        <title>
          best grocery online shopping in austrila || best deals price and
          discounts
        </title>
        <meta name="description" content="homepage nepsaymart" />
      </Helmet>
      <Suspense fallback={<MainLoader />}>
        <div className="mx-auto  px-2  lg:px-5">
          <div className="flex-1 w-full">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-6 ">
              <div className=" w-full rounded-lg  px-2 lg:col-span-12 lg:h-full space flex flex-col space-y-5">
                <div className="w-full md: flex md:justify-between md:items-center">
                  <CategoriesMenu />
                   
                   <EcommerceCarousel />
                  
                </div>
                {filteredProductsByNameAndCategory &&
                  filteredProductsByNameAndCategory.length !== 0 && (
                    <SearchResult />
                  )}
                {/* <CategoryListAutoSlide /> */}
                <TopProducts products={TopSellingProducts} />
                <ProductList />
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </section>
  );
}

export default Home;
