import OtherServices from "../components/OtherServices/OtherServices";
import {
  ProductDetail,
  Login,
  Home,
  Cart,
  Checkout,
  ForgotPassword,
  ResetPassword,
  ErrorPage,
  RegisterPage,
  Shipping,
  PaymentSuccess,
  Profile,
  ProfileSetting,
  OrderDetail,
  MyOrderPage,
  EmailVerification,
} from "../pages";

const authRoutes = [
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/register",
    element: <RegisterPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/resetpassword/:token",
    element: <ResetPassword />,
  },
  {
    path: "/emailverification/:token",
    element: <EmailVerification />,
  },
];

const contentRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profilesetting",
    element: <ProfileSetting />,
  },
  {
    path: "/myorder",
    element: <MyOrderPage />,
  },
  {
    path: "/order/:id",
    element: <OrderDetail />,
  },
  {
    path: "/product/:id",
    element: <ProductDetail />,
  },

  {
    path: "/cartpage",
    element: <Cart />,
  },
  {
    path: "/shipping",
    element: <Shipping />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },

  {
    path: "/payment-successfull",
    element: <PaymentSuccess />,
  },
  {
    path: "/other-services",
    element: <OtherServices />,
  },

  {
    path: "/*",
    element: <ErrorPage />,
  },
];
export { authRoutes, contentRoutes };
