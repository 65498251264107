import React, { useState, useEffect, useCallback } from "react";
import { CiSearch } from "react-icons/ci";
import { searchProductByKeywordService } from "../../api/apiServices";
import useProductStore from "../../zustand/productStore";

const Search = () => {
  const [search, setSearch] = useState("");

  const { setFilteredProductsByNameAndCategory } = useProductStore();

  const fetchResults = useCallback(async () => {
    try {
      const { data } = await searchProductByKeywordService(search);
      setFilteredProductsByNameAndCategory(data);
    } catch (error) {
      console.error("Error searching for products:", error);
    }
  }, [search, setFilteredProductsByNameAndCategory]);

  const changeHandler = (e) => {
    if (e.target.value === "") {
      setFilteredProductsByNameAndCategory([]);
    }
    setSearch(e.target.value);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if  (search && search.length>1 &&  search.trim() !== "") {
        fetchResults();
      }
    }, 100);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search, fetchResults]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          changeHandler(e);
        }}
        autoComplete="off"
        className={`flex items-center justify-center bg-white text-white/[0.8] px-3 rounded-t-md text-sm transition`}
      >
        <input
          className="w-full sm:w-full md:w-1/2 rounded-lg  bg-gray py-2 md:py-3 px-6  text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
          type="search"
          value={search}
          placeholder="Search products..."
          onChange={changeHandler}
        />
        <CiSearch />
      </form>
    </>
  );
};

export default Search;
