import React, { useEffect, useState } from "react";
import useUserListStore from "../../zustand/adminuserStore";

import { toast } from "react-toastify";
import { deleteUserProfileService } from "../../api/apiServices";
import { notify } from "../../utils/utils";
import Pagination from "../Pagination/Pagination";
import UpdateForm from "./user/UpdateForm";
import { Modal } from "../Category/Modal";
import AdminUserList from "./AdminUserList";
import useAuthStore from "../../zustand/userStore";
import { DeleteIconBtn, UpdateIconBtn } from "../ui/Button/Buttons";

const Users = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const user = useAuthStore((state) => state.user);

  const handlePageChange = (newPage) => {
    if (newPage <= 0) newPage = 1;
    setCurrentPage(newPage);
    getUsers(newPage, 10);
  };

  const { users, getUsers } = useUserListStore((state) => ({
    users: state.users,
    getUsers: state.getUsers,
  }));

  const normalUsers =
    users?.users?.filter((user) => user.role === "USER") || [];
  const adminUsers =
    users?.users?.filter(
      (user) => user.role === "ADMIN" || user.role === "EDITOR",
    ) || [];

  const [selectedUser, setSelectedUser] = useState({});

  const handleUpdate = (user) => {
    setIsOpen(true);
    setSelectedUser(user);
  };

  useEffect(() => {
    getUsers(1, 20);
  }, [getUsers]);

  const handleDelete = (id) => {
    const confirmToastId = toast.warning(
      <div>
        <p className="text-xs">Are you sure you want to delete this User?</p>
        <div className="w-7/12 flex space-x-2 mt-2 ">
          <button
            className="bg-red-500 text-xs hover:bg-green-700 text-white font-medium py-1 px-4 rounded"
            onClick={async () => {
              const { data } = await deleteUserProfileService(id);
              toast.dismiss(confirmToastId);
              notify("success", data.message);
            }}
          >
            Yes
          </button>
          <button
            className="bg-green-500 text-xs hover:bg-green-700 text-white font-medium py-1 px-4 rounded"
            onClick={() => {
              toast.dismiss(confirmToastId); // Close the confirmation toast
            }}
          >
            No
          </button>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        autoClose: false,
        hideProgressBar: true,
      },
    );
  };

  if (user.role !== "ADMIN") {
    console.log("user.role", user.role);
    return (
      <h1 className="text-2xl text-red-500">
        You are not authorized to access this page
      </h1>
    );
  }

  return (
    <div className="bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 ">
      <div className="md:flex md:justify-between items-center text-center mb-4">
        <h4 className=" text-xl font-semibold text-black dark:text-white">
          User management
        </h4>
        <div className="flex justify-end items-end">
          <form
            autoComplete="off"
            className="pt-2 w-[290px] relative mx-auto text-gray-600"
          >
            <input
              className="border-2 border-gray-300 bg-white h-10 px-10 pr-14 rounded-lg text-sm focus:outline-none"
              type="search"
              name="search"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <button type="submit" className="absolute right-0 top-0 mt-5 mr-10">
              <svg
                className="text-gray-600 h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 56.966 56.966"
                style={{ enableBackground: "new 0 0 56.966 56.966" }}
                xmlSpace="preserve"
                width="512px"
                height="512px"
              >
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </form>
        </div>
      </div>

      <div className="rounded-sm border  border-stroke w-full bg-white shadow-default dark:border-strokedark dark:bg-boxdark  ">
        <div className="overflow-x-auto">
          <table className="table-auto min-w-full divide-y divide-gray-200">
            <thead className="text-xs text-black-2 uppercase bg-gray-400 dark:bg-black dark:text-slate-600">
              <tr className="bg-gray-2 text-left dark:bg-meta-4">
                <th className="py-4 px-4 font-medium text-black dark:text-white">
                  User ID
                </th>
                <th className="py-4 px-4 font-medium text-black dark:text-white">
                  Name
                </th>
                <th className="py-4 px-4 font-medium text-black dark:text-white">
                  Email
                </th>
                <th className="py-4 px-4 font-medium text-black dark:text-white">
                  Role
                </th>
                <th className="px-4 font-medium text-black dark:text-white">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {normalUsers
                .filter((user) => {
                  return search === ""
                    ? user
                    : user.name.toLowerCase().includes(search.toLowerCase());
                })
                .map((user, index) => (
                  <tr key={user.id}>
                    <td className="border-b text-gray-800 border-[#eee] py-4 px-4 dark:border-strokedark">
                      {index + 1}
                    </td>
                    <td className="border-b text-gray-800 border-[#eee] py-4 px-4 dark:border-strokedark">
                      {user.name}
                    </td>
                    <td className="border-b text-gray-800 border-[#eee] py-4 px-4 dark:border-strokedark">
                      {user.email}
                    </td>
                    <td className="border-b text-gray-800 border-[#eee] py-4 px-4 dark:border-strokedark">
                      {user.role}
                    </td>
                    <td className="border-b border-[#eee] py-4  dark:border-strokedark">
                      <div className="flex space-x-2 justify-center items-center">
                        <UpdateIconBtn onClick={() => handleUpdate(user)} />
                        <DeleteIconBtn onClick={() => handleDelete(user.id)} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            onPageChange={handlePageChange}
            totalCount={users?.totalCount}
            totalPages={users?.totalPages}
            currentPage={currentPage}
          />
        </div>
        <AdminUserList
          adminUsers={adminUsers}
          search={search}
          setSearch={setSearch}
        />
        <Modal title="Update User" isOpen={isOpen} setIsOpen={setIsOpen}>
          <UpdateForm
            selectedUser={selectedUser}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Users;
