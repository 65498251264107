import React, { useState } from 'react'

import { toast } from 'react-toastify'
import { deleteUserProfileService } from '../../api/apiServices'
import { notify } from '../../utils/utils'
import UpdateForm from './user/UpdateForm'
import { Modal } from '../Category/Modal'
import { DeleteIconBtn, UpdateIconBtn } from '../ui/Button/Buttons'

const AdminUserList = ({ adminUsers, search }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})

  const handleUpdate = (user) => {
    setIsOpen(true)
    setSelectedUser(user)
  }

  const getUserRoleColor = (status) => {
    switch (status) {
      case 'ADMIN':
        return 'bg-yellow-200'
      case 'EDITOR':
        return 'bg-yellow-200'
      case 'USER':
        return 'bg-red-200'
      default:
        return
    }
  }

  const handleDelete = (id) => {
    const confirmToastId = toast.warning(
      <div>
        <p className='text-xs'>Are you sure you want to delete this User?</p>
        <div className='w-7/12 flex space-x-2 mt-2 '>
          <button
            className='bg-red-500 text-xs hover:bg-green-700 text-white font-medium py-1 px-4 rounded'
            onClick={async () => {
              const { data } = await deleteUserProfileService(id)
              console.log(data)
              toast.dismiss(confirmToastId)
              notify('success', data.message)
            }}
          >
            Yes
          </button>
          <button
            className='bg-green-500 text-xs hover:bg-green-700 text-white font-medium py-1 px-4 rounded'
            onClick={() => {
              toast.dismiss(confirmToastId) // Close the confirmation toast
            }}
          >
            No
          </button>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        autoClose: false,
        hideProgressBar: true,
      }
    )
  }

  return (
    <div className='w-full  '>
      <div className='bg-white  pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark'>
        <div className='md:flex md:justify-between text-center items-center mb-4'>
          <h4 className=' text-xl font-semibold text-black dark:text-white'>
            Admin user management
          </h4>
        </div>
        <div className='relative overflow-x-auto rounded-sm border  border-stroke w-full bg-white shadow-default dark:border-strokedark dark:bg-boxdark '>
          <table className='table-auto min-w-full divide-y divide-gray-200'>
            <thead className='text-xs text-black-2 uppercase bg-gray-400 dark:bg-black dark:text-slate-600'>
              <tr className='bg-gray-2 text-left dark:bg-meta-4'>
                <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                  User ID
                </th>
                <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                  Name
                </th>
                <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                  Email
                </th>
                <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                  Role
                </th>
                <th className='px-3 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {adminUsers.map((user, index) => (
                <tr key={user.id}>
                  <td className='border-b text-gray-800 border-[#eee] py-3 px-3 dark:border-strokedark'>
                    {index + 1}
                  </td>
                  <td className='border-b text-gray-800 border-[#eee] py-3 px-3 dark:border-strokedark'>
                    {user.name}
                  </td>
                  <td className='border-b text-gray-800 border-[#eee] py-3 px-3 dark:border-strokedark'>
                    {user.email}
                  </td>
                  <td className='border-b text-gray-800 border-[#eee] py-3 px-3 dark:border-strokedark'>
                    <button
                      className={` px-3 rounded-md py-1 ${getUserRoleColor(
                        user.role
                      )}`}
                    >
                      {user.role}
                    </button>
                  </td>
                  <td className='border-b text-gray-800 border-[#eee] py-4  dark:border-strokedark'>
                    <div className='flex space-x-2 justify-center items-center'>
                      <UpdateIconBtn onClick={() => handleUpdate(user)} />
                      <DeleteIconBtn
                        className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
                        onClick={() => handleDelete(user.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal title='Update User' isOpen={isOpen} setIsOpen={setIsOpen}>
          <UpdateForm
            selectedUser={selectedUser}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </Modal>
      </div>
    </div>
  )
}

export default AdminUserList
