import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCategoryService } from "../../api/apiServices";
import { notify } from "../../utils/utils";

const UpdateCategoryForm = ({ updateCategory, setIsOpen }) => {
  const [newCategory, setNewCategory] = useState(updateCategory.title);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ id, title }) => updateCategoryService(id, { title }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("categories");
        setIsOpen(false);
      },
      onError: (error) => {
        notify(
          "error",
          error.response?.data?.message || "Error updating category"
        );
      },
    }
  );

  const handleNewCategoryChange = (e) => {
    setNewCategory(e.target.value);
  };

  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    mutation.mutate({ id: updateCategory.id, title: newCategory });
  };

  return (
    <form onSubmit={handleCategorySubmit}>
      <div className="flex flex-col space-y-4">
        <input
          type="text"
          value={newCategory}
          onChange={handleNewCategoryChange}
          className="px-4 py-2 w-full border border-gray-300 rounded-l"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-success w-full text-white rounded-r hover:bg-blue-600 transition-colors flex-1"
        >
          Update Category
        </button>
      </div>
    </form>
  );
};

export default UpdateCategoryForm;
