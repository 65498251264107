import React, { useEffect, useState, useRef } from "react"; // Import useRef hook
import { useQuery } from "@tanstack/react-query";
import { getAllCategoryWithSubcategoryService } from "../../api/apiServices";
import { MdClear } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { AiOutlineClose, AiFillPlusCircle } from "react-icons/ai";
import Logo from "../../assets/logo/Logo.png";
import InputRange from "./InputRange";
import useProductStore from "../../zustand/productStore";

const FilterHeading = ({ text }) => (
  <h2 className="text-xl text-white dark:text-white mb-2">{text}</h2>
);

const Filters = () => {
  const { isFilterOpen, setIsFilterOpen } = useProductStore((state) => state);
  const [detailsOpen, setDetailsOpen] = useState(true);
  const [keywordtoSearch, setKeywordtoSearch] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState([]);

  const { setFilteredProducts, setClearFilter, setAllProducts } =
    useProductStore();

  const sidebarRef = useRef(null); // Create a ref to the sidebar component

  const handleCheckboxChange = async (
    categoryIndex,
    subcategoryIndex,
    checked
  ) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [categoryIndex]: {
        ...(prevState[categoryIndex] || {}),
        [subcategoryIndex]: checked,
      },
    }));

    setKeywordtoSearch((prevState) => {
      const nextState = [...prevState];
      if (checked) {
        nextState.push(
          allcategoryData[categoryIndex].subcategories[subcategoryIndex].title
        );
      } else {
        const indexToRemove = nextState.indexOf(
          allcategoryData[categoryIndex].subcategories[subcategoryIndex].title
        );
        if (indexToRemove !== -1) {
          nextState.splice(indexToRemove, 1);
        }
      }

      return nextState;
    });
  };

  const { data } = useQuery({
    queryKey: ["categoriesWithSubcategories"],
    queryFn: getAllCategoryWithSubcategoryService,
  });

  const allcategoryData = data?.data;

  const handleactivesummary = (id) => {
    setDetailsOpen(!detailsOpen);
  };

  const clearAllFilters = () => {
    setClearFilter();
    setAllProducts(1, 20);
  };

  useEffect(() => {
    if (keywordtoSearch.length > 0) {
      setFilteredProducts(keywordtoSearch);
    }
  }, [keywordtoSearch, setFilteredProducts]);

  useEffect(() => {
    // Function to close sidebar when clicking outside of it
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsFilterOpen]);

  return (
    <>
      <aside
        ref={sidebarRef} // Assign ref to the sidebar element
        className={`absolute left-0 px-5 pt-5 top-0 z-9999 flex h-screen w-72 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0
        ${isFilterOpen ? "translate-x-0" : "-translate-x-full"}
      `}
      >
        <div className="flex items-end justify-end">
          <button
            className="px-2 text-white md:hidden dark:text-black border-x-green-600"
            onClick={() => setIsFilterOpen(false)}
          >
            <AiOutlineClose className="text-xl" />
          </button>
        </div>
        <div className="flex items-center justify-center">
          <NavLink to="/">
            <img
              src={Logo}
              alt="Logo"
              className="w-full object-cover xs:object-contain sm:object-cover h-18 sm:h-20 md:h-48"
            />
          </NavLink>
        </div>
        <div className=" flex justify-start">
          <button
            className="flex py-1 px-2 rounded-md shadow-md items-center gap-1 text-white dark:text-white hover:shadow-lg"
            onClick={() => clearAllFilters()}
          >
            <MdClear className="text-lg" />
            <span className="text-sm">Clear </span>
          </button>
        </div>
        <section className="no-scrollbar scroll-smooth focus:scroll-auto flex flex-col overflow-y-auto duration-300 ease-linear">
          <div className="w-full h-[2px] rounded-md bg-white dark:bg-black mb-2"></div>
          <div className="flex flex-col gap-2">
            <button
              className=" flex justify-start shadow-sm bg-white text-black rounded-md font-semibold px-2"
              onClick={() => {
                setAllProducts(1, 11);
              }}
            >
              All products
            </button>
            {allcategoryData &&
              allcategoryData?.map((category, categoryIndex) => (
                <details
                  className={`rounded relative px-2 shadow-sm mb-1 bg-white duration-300`}
                  key={categoryIndex}
                  open={detailsOpen === categoryIndex}
                >
                  <summary
                    className="list-none mb-2 font-semibold flex justify-between items-center mt-2 relative text-sm cursor-pointer pr-4"
                    onClick={() => handleactivesummary(categoryIndex)}
                  >
                    {category?.title.toUpperCase()}
                    <span className="inline-block ml-5">
                      <AiFillPlusCircle className="text-base " />
                    </span>
                  </summary>
                  <ul className="mb-2 space-y-2">
                    {category.subcategories?.map(
                      (subcategory, subcategoryIndex) => (
                        <li
                          className="list-none text-sm hover:text-blue-200"
                          key={subcategoryIndex}
                        >
                          <input
                            type="checkbox"
                            className="mr-2"
                            name="category"
                            value="category"
                            checked={
                              checkboxStates[categoryIndex]?.[
                                subcategoryIndex
                              ] || false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                categoryIndex,
                                subcategoryIndex,
                                e.target.checked
                              )
                            }
                          />
                          {subcategory.title}
                        </li>
                      )
                    )}
                  </ul>
                </details>
              ))}
          </div>
        </section>
        <section className="py-3">
          <FilterHeading text="Price Range" />
          <InputRange />
        </section>
      </aside>
    </>
  );
};

export default Filters;
