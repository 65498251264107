import React, { useEffect, useState } from "react";
import OrderList from "../Order/OrderList";
import { useQuery, useQueryClient } from "@tanstack/react-query";
// import { myOrdersService } from "../../api/apiServices";
import MainLoader from "../MainLoader/MainLoader";
import { getAllOrdersService } from "../../api/apiServices";

const AllOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(50);

  const handlePageChange = (newPage) => {
    if (newPage <= 0) newPage = 1;
    setCurrentPage(newPage);
    queryClient.invalidateQueries(["Allorders", newPage, pageSize]);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries(["Allorders", currentPage, pageSize]);
  }, [currentPage, pageSize, queryClient]);

  const { isLoading, data: orders } = useQuery({
    queryKey: ["Allorders", currentPage, pageSize],
    queryFn: () => getAllOrdersService(currentPage, pageSize),
    cacheTime: 0,
  });
  const ordersData = orders?.data.orders;

  if (isLoading) return <MainLoader />;

  return (
    <div className="rounded-sm border border-stroke bg-white  shadow-default dark:border-strokedark dark:bg-boxdark ">
      <div className="relative overflow-x-auto">
        <OrderList
          orders={ordersData}
          totalPages={orders?.data.totalPages}
          totalCount={orders?.data.totalCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          loading={isLoading}
          // setLoading={setLoading}
        />
      </div>
    </div>
  );
};

export default AllOrders;
