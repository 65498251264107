import React from 'react'
import PriceCard from './PriceCard'
import { useNavigate } from 'react-router'
import cartStore from '../../zustand/cartStore'
import { AiFillShopping } from 'react-icons/ai'
import useAuthStore from '../../zustand/userStore'
import { notify } from '../../utils/utils'

const CartTotalCard = () => {
  const isAuth = useAuthStore((state) => state.isAuthenticated)
  const totalPrice = cartStore((state) => state.totalPrice)
  // const deliveryCharge = cartStore((state) => state.deliveryCharge)

  const navigate = useNavigate()

  const handleOrder = async () => {
    // check total price if less than 50 then not allowed to checkout

    if (totalPrice < 50) {
      notify('error', 'Minimum order amount is $50')
      return
    }
    if (!isAuth) {
      navigate('/checkout/login')
    } else {
      localStorage.getItem('shipping-info')
        ? navigate('/checkout/payment')
        : navigate('/checkout/shipping')
    }
  }

  return (
    <section className=' w-full md:w-4/12 py-7 mt-10 px-7 rounded-md shadow-md bg-white/[0.7] flex-col gap-6 h-min'>
      <div className='flex'>
        <AiFillShopping size={20} />
        <p className=' flex text-[1rem] font-semibold items-start ml-3'>
          Summary:
        </p>
      </div>
      <PriceCard />
      <hr />
      <div className='flex justify-between  w-[100%]'>
        <p className=' text-gray-600'>Total</p>
        <div className='flex flex-col items-center justify-center'>
          <p className={`text-2xl  `}>${totalPrice}</p>
        </div>
      </div>

      <div className='w-full py-2 flex gap-4 items-center'>
        <button
          className='flex w-full justify-center rounded bg-orange-500 hover:bg-orange-600  p-3 font-medium text-white'
          onClick={handleOrder}
        >
          Proceed to checkout
        </button>
      </div>
    </section>
  )
}

export default CartTotalCard
