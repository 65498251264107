import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { resetPasswordService } from '../../api/apiServices'
import { useNavigate, useParams } from 'react-router-dom'
import { notify } from '../../utils/utils'

const ForgotPassword = () => {
  const { token } = useParams()

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      console.log(values)
      try {
        const response = await resetPasswordService(
          token,
          values.password,
          values.confirmPassword
        )
        const data = response.data
        console.log(data)
        resetForm()
        notify('Password reset successfully', 'success')
        setTimeout(() => {
          navigate('/auth/login')
        }, 2000)
      } catch (error) {
        console.error(error)
        notify('Failed to reset password. Please try again.', 'error')
      } finally {
        setSubmitting(false)
      }
    },
  })
  return (
    <div className='flex flex-col items-center justify-center px-4 pt-4 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900'>
      <div className='w-full max-w-xl p-6 space-y-8 bg-white rounded-lg shadow sm:p-8 dark:bg-gray-800'>
        <h2 className='text-2xl font-bold text-gray-900 dark:text-white'>
          Reset your password
        </h2>
        <form className='mt-8 space-y-6' onSubmit={formik.handleSubmit}>
          <div>
            <label
              htmlFor='password'
              className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
            >
              New password
            </label>
            <input
              type='password'
              name='password'
              id='password'
              placeholder='••••••••'
              autoComplete='off'
              value={formik.values.password}
              onChange={formik.handleChange}
              className={`bg-gray-50 border ${
                formik.touched['password'] && formik.errors.password
                  ? 'border-red-500'
                  : 'border-gray-300'
              } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
              required
            />
            {formik.touched['password'] && formik.errors.password ? (
              <div className='text-red-500'>{formik.errors.password}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor='confirmPassword'
              className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
            >
              Confirm New Password
            </label>
            <input
              type='password'
              name='confirmPassword'
              id='confirmPassword'
              placeholder='••••••••'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`bg-gray-50 border ${
                formik.touched['confirmPassword'] &&
                formik.errors.confirmPassword
                  ? 'border-red-500'
                  : 'border-gray-300'
              } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
              required
            />
            {formik.touched['confirmPassword'] &&
            formik.errors.confirmPassword ? (
              <div className='text-red-500'>
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>
          <div className='flex items-start'>
            <div className='flex items-center h-5'>
              <input
                id='remember'
                aria-describedby='remember'
                name='remember'
                type='checkbox'
                className='w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600'
                required
              />
            </div>
            <div className='ml-3 text-sm'>
              <label
                htmlFor='remember'
                className='font-medium text-gray-900 dark:text-white'
              >
                I accept the{' '}
                <a
                  href='/'
                  className='text-primary-700 hover:underline dark:text-primary-500'
                >
                  Terms and Conditions
                </a>
              </label>
            </div>
          </div>
          <button
            type='submit'
            className='w-full cursor-pointer rounded-lg border border-primary bg-green-600 p-2 text-white transition hover:bg-opacity-90'
            disabled={formik.isSubmitting}
          >
            Reset password
          </button>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
