import React, { useEffect, useState } from 'react'
import { getProductByCategoryService } from '../../api/apiServices'
import Product from './Product'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'
import { Grid, Autoplay } from 'swiper/modules'

const SimilarProduct = ({ categoryid, product: productID }) => {
  const [similarProduct, setSimilarProduct] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const getSimilarProduct = async () => {
      if (categoryid !== undefined) {
        try {
          const { data } = await getProductByCategoryService(categoryid)
          setSimilarProduct(data?.products)
          setLoading(false)
        } catch (error) {
          console.error('Error fetching similar products:', error)
          setLoading(false)
        }
      }
    }
    getSimilarProduct()
  }, [categoryid])

  const [slidesPerView, setSlidesPerView] = useState(5)

  const updateSlidesPerView = () => {
    if (window.innerWidth >= 1024) {
      setSlidesPerView(5)
    } else if (window.innerWidth >= 768) {
      setSlidesPerView(4)
    } else if (window.innerWidth >= 480) {
      setSlidesPerView(2)
    } else {
      setSlidesPerView(2)
    }
  }

  // Update slidesPerView on window resize
  useEffect(() => {
    updateSlidesPerView()
    window.addEventListener('resize', updateSlidesPerView)
    return () => {
      window.removeEventListener('resize', updateSlidesPerView)
    }
  }, [])

  const filteredSimilarProducts = similarProduct.filter(
    (product) => product?.product_id !== productID
  )

  if (loading) return <p>Loading ....</p>

  if (similarProduct.length === 0) return <p>No similar products found.</p>
  return (
    <div className='px-5 '>
      <div className=' w-full mt-10 flex items-center justify-between '>
        <div className='flex flex-col items-start justify-start gap-1'>
          <p className='text-xl md:text-2xl text-headingColor font-bold'>
            You may also like to buy
          </p>
          <div className='w-40 h-1 rounded-md bg-orange-500'></div>
        </div>
      </div>
      <div className='w-full pt-10'>
        <Swiper
          slidesPerView={slidesPerView}
          centeredSlides={false}
          grabCursor={true}
          spaceBetween={25}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Grid]}
          className='mySwiper'
        >
          {filteredSimilarProducts.length > 1 ? (
            filteredSimilarProducts.map((product, index) => (
              <SwiperSlide key={index}>
                {productID !== product?.product_id && (
                  <Product product={product} key={index} />
                )}
              </SwiperSlide>
            ))
          ) : (
            <p>No similar products found.</p>
          )}
        </Swiper>
      </div>
    </div>
  )
}

export default SimilarProduct
