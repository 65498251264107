import useScrollToTop from '../../hooks/useScrollToTop'
import BackToHome from './BackToHome'

const RefundPolicy = () => {
  useScrollToTop()
  return (
    <div className='mx-auto mt-8 p-4 text-justify'>
      <BackToHome />

      <h2 className='text-2xl font-bold mb-4 text-center'>Refund Policy</h2>

      <p>
        At Nepsay Mart, we work hard to offer our clients the best goods and
        services that are available on our website. We will provide a refund or
        exchange of item/items if you, the customer, are not happy with your
        purchase under the conditions as outlined below.
      </p>

      <h3 className='text-lg font-semibold mt-4'>Returns and Refunds</h3>
      <div className='flex flex-col space-y-3'>
        <p>
          Within 7 days of delivery, you, the customer, may exchange or return
          any Nepsay Mart product as long as it's still in its original
          packaging and in like-new condition.
        </p>
        <p>
          Once we've received your return request, we'll examine it and advise
          whether your refund has can be processed. If your return is accepted,
          we will contact you “The Customer” and process your refund and issue
          credit to your original payment method within 3 business days. You
          “The Customer” may choose to use the store credit option instead of
          getting a refund.
        </p>
      </div>

      <h3 className='text-lg font-semibold mt-4'>Exclusions</h3>
      <ul className='list-disc pl-6 mt-2'>
        <li>
          Perishable products such as dairy products, meat, fresh fish items,
          fresh vegetables, or any "fresh products".
        </li>
        <li>
          Products that have been tampered with after delivery, used, or
          damaged.
        </li>
        <li>
          Products with missing labels or ones that are not in their original
          packing at the time of delivery.
        </li>
      </ul>

      <h3 className='text-lg font-semibold mt-4'>Perishable Products</h3>
      <p>
        Perishable products such as dairy products, meat, fresh fish items,
        fresh vegetables, or any "fresh products" are not deemed eligible for
        return unless the products do not meet the food safety quality standard
        set by the appropriate food safety regulations in Australia.
      </p>

      <h3 className='text-lg font-semibold mt-4'>Shipping Costs</h3>
      <p>
        Unless the return is the result of our error, such as shipping the wrong
        goods or a defective product, we will only reimburse the original price
        of the product. Any shipping expenses incurred will be at your, the
        customer's, own expenses; Nepsay Mart will not cover the shipping
        expense for any returns.
      </p>

      <h3 className='text-lg font-semibold mt-4'>Cancellations</h3>
      <p>
        Your order may be canceled prior to shipping. You may return an order
        that has already been dispatched in accordance with agreements between
        supplier Nepsay Mart and you, the customer.
      </p>

      <h3 className='text-lg font-semibold mt-4'>Contact Us</h3>
      <p>
        Please get in touch with us if you have any queries or complaints
        regarding our Conditions of Use.
      </p>

      <section>
        <p className='mb-4'>
          Please get in touch with us if you have any queries or complaints
          regarding our Conditions of Use.
        </p>
        <address className='mb-4 font-bold'>
          Nepsay Mart Pty Ltd
          <br />
          29 Helpmann Cres, Epping VIC 3076, Australia
          <br />
          Phone: 0420574005
          <br />
          Email: nepsaymart.info@gmail.com
        </address>
      </section>
    </div>
  )
}

export default RefundPolicy
