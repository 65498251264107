import { create } from 'zustand'

const useAuthStore = create((set) => {
  return {
    isAuthenticated: JSON.parse(localStorage.getItem('user')) ? true : false,
    loading: false,
    isGoogleUser: false,
    user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null,
    login: (data) => {
      localStorage.setItem('user', JSON.stringify(data))
      set({ isAuthenticated: true, loading: false, user: data })
    },

    googleLogin: (data) => {
      localStorage.setItem('user', JSON.stringify(data))
      set({ isAuthenticated: true, user: data, loading: false, isGoogleUser: true })
    },

    register: (data) => {
      localStorage.setItem('user', JSON.stringify(data))
      set({ isAuthenticated: true, loading: false, user: data })
    },
    logout: () => {
      localStorage.removeItem('user')
      set({ isAuthenticated: false, user: null, loading: false })
    },
  }
})

export default useAuthStore
