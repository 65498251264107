import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import SharedLayout from "./SharedLayout";
import { motion } from "framer-motion";
import { fadeInOut } from "../components/animations";
import { authRoutes, contentRoutes } from "./publicRoutes";

import { ErrorPage } from "../pages";
import DashBoardLayout from "../pages/dashboard/layout/DashBoardLayout";
import OtherLayout from "./OtherLayout";
import MainLoader from "../components/MainLoader/MainLoader";
import ProductListLayout from "./ProductListLayout";
import ProductFilterPage from "../pages/Home/ProductFilterPage";
import useAuthStore from "../zustand/userStore";

import CheckoutLayout from "./CheckoutLayout";
const Home = lazy(() => import("../pages/Home/HomePage"));
const FAQ = lazy(() => import("../components/Footer/Faq"));
const PrivacyPolicy = lazy(() => import("../components/Footer/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("../components/Footer/RefundPolicy"));
const TermConditions = lazy(() =>
  import("../components/Footer/TermConditions"),
);
const OrderLogin = lazy(() =>
  import("../components/Checkout/OrderCheckout/OrderLogin"),
);
const OrderShipping = lazy(() =>
  import("../components/Checkout/OrderShipping/OrderShipping"),
);
const OrderPayment = lazy(() =>
  import("../components/Checkout/OrderPayment/OrderPayment"),
);
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const ProductTable = lazy(() => import("../components/Table/ProductTable"));
const Users = lazy(() => import("../components/Table/Users"));
const Category = lazy(() => import("../components/Category/Category"));
const OrderPage = lazy(() => import("../components/Table/OrderPage"));
const CouponManagement = lazy(() => import("../pages/Coupon/CouponManagement"));
const AllOrders = lazy(() => import("../components/Table/AllOrders"));
const ShippedOrders = lazy(() => import("../components/Table/ShippedOrders"));
const PendingOrders = lazy(() => import("../components/Table/PendingOrders"));
const DeliveredOrders = lazy(() =>
  import("../components/Table/DeliveredOrders"),
);
const UploadFeatureProduct = lazy(() =>
  import("../components/UploadFeatureProduct/UploadFeatureProduct"),
);
const OtherServiceManagement = lazy(() =>
  import("../pages/otherservice/OtherServiceManagement"),
);

const Index = () => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const user = useAuthStore((state) => state.user);

  const isAdmin =
    isAuthenticated &&
    user &&
    (user.role === "ADMIN" || user.role === "EDITOR");
 
  const isLoading = useAuthStore((state) => state.loading);


  return (
    <div>
      {isLoading && (
        <motion.div
          {...fadeInOut}
          className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full"
        >
          <MainLoader />
        </motion.div>
      )}
      <Router>
        <Routes>
          <Route element={isAuthenticated ? <Navigate to="/" /> : <Outlet />} />
          <Route path="*" element={<ErrorPage />} />

          <Route element={<SharedLayout />}>
            <Route
              path="/"
              element={
                <Suspense fallback={<MainLoader />}>
                  <Home />
                </Suspense>
              }
              index
            />
            {contentRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  <Suspense fallback={<MainLoader />}>{route.element}</Suspense>
                }
              />
            ))}

            <Route element={<CheckoutLayout />}>
              <Route
                path="/checkout/login"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <OrderLogin />
                  </Suspense>
                }
              />
              <Route
                path="/checkout/shipping"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <OrderShipping />
                  </Suspense>
                }
              />
              <Route
                path="/checkout/payment"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <OrderPayment />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="/faq"
              element={
                <Suspense fallback={<MainLoader />}>
                  <FAQ />
                </Suspense>
              }
            />
            <Route
              path="/privacy"
              element={
                <Suspense fallback={<MainLoader />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="/refund"
              element={
                <Suspense fallback={<MainLoader />}>
                  <RefundPolicy />
                </Suspense>
              }
            />
            <Route
              path="/terms"
              element={
                <Suspense fallback={<MainLoader />}>
                  <TermConditions />
                </Suspense>
              }
            />
          </Route>

          <Route element={<OtherLayout />}>
            {authRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  <Suspense fallback={<MainLoader />}>{route.element}</Suspense>
                }
                exact
              />
            ))}
          </Route>

          <Route element={<ProductListLayout />}>
            <Route
              path="/:type/:name/:id"
              element={
                <Suspense fallback={<MainLoader />}>
                  <ProductFilterPage />
                </Suspense>
              }
            />
          </Route>
          {isAdmin && (
            <Route path="/admin" element={<DashBoardLayout />}>
              <Route
                index
                element={
                  <Suspense fallback={<MainLoader />}>
                    <Dashboard />
                  </Suspense>
                }
              />

              <Route
                path="/admin/productslist"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <ProductTable />
                  </Suspense>
                }
              />
              <Route
                path="/admin/userlist"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <Users />
                  </Suspense>
                }
              />
              <Route
                path="/admin/category"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <Category />
                  </Suspense>
                }
              />

              <Route
                path="/admin/orders"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <OrderPage />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<MainLoader />}>
                      <AllOrders />
                    </Suspense>
                  }
                />
                <Route
                  path="/admin/orders/shipped"
                  element={
                    <Suspense fallback={<MainLoader />}>
                      <ShippedOrders />
                    </Suspense>
                  }
                />
                <Route
                  path="/admin/orders/pending"
                  element={
                    <Suspense fallback={<MainLoader />}>
                      <PendingOrders />
                    </Suspense>
                  }
                />
                <Route
                  path="/admin/orders/delivered"
                  element={
                    <Suspense fallback={<MainLoader />}>
                      <DeliveredOrders />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="/admin/promotion"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <UploadFeatureProduct />
                  </Suspense>
                }
              />
              <Route
                path="/admin/coupon"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <CouponManagement />
                  </Suspense>
                }
              />
              <Route
                path="/admin/other-services"
                element={
                  <Suspense fallback={<MainLoader />}>
                    <OtherServiceManagement />
                  </Suspense>
                }
              />
            </Route>
          )}
        </Routes>
      </Router>
    </div>
  );
};

export { Index };
