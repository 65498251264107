// UpdateSubcategoryForm.jsx

import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSubCategoryService } from "../../api/apiServices";
import { notify } from "../../utils/utils";

const UpdateSubcategoryForm = ({ updateSubcategory, setIsOpen }) => {
  const [newTitle, setNewTitle] = useState(updateSubcategory.title);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ id, title }) => updateSubCategoryService(id, title), // Corrected to pass title directly
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subcategories");
        setIsOpen(false);
      },
      onError: (error) => {
        notify(
          "error",
          error.response?.data?.message || "Error updating subcategory"
        );
      },
    }
  );

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    mutation.mutate({
      id: updateSubcategory.id,
      title: newTitle,
    });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="flex flex-col space-y-4">
        <input
          type="text"
          placeholder="New Subcategory Title"
          value={newTitle}
          onChange={handleTitleChange}
          className="px-4 py-2 w-full border border-gray-300 rounded-l"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-success w-full text-white rounded-r hover:bg-blue-600 transition-colors flex-1"
        >
          Update Subcategory
        </button>
      </div>
    </form>
  );
};

export default UpdateSubcategoryForm;
