import React, { useState } from 'react'
import { updateUserProfileService } from '../../../api/apiServices'

import { useMutation, useQueryClient } from '@tanstack/react-query'

const UpdateForm = ({ selectedUser, setIsOpen }) => {
  const [role, setRole] = useState('user')

  const queryClient = useQueryClient()

  const mutation = useMutation(updateUserProfileService, {
    onSuccess: () => {
      queryClient.invalidateQueries('users')
    },
  })

  const handleChange = (e) => {
    setRole(e.target.value)
  }

  const handleUpdateSubmit = async (e) => {
    e.preventDefault()

    try {
      mutation.mutate({ id: selectedUser.id, role })
      setIsOpen(false)
    } catch (error) {}
  }

  return (
    <form className='w-full' onSubmit={handleUpdateSubmit}>
      <div className='mb-4'>
        <label htmlFor='role' className='block text-gray-700 font-medium'>
          Role
        </label>
        <select
          id='role'
          name='role'
          value={role}
          onChange={handleChange}
          className='border border-gray-300 p-2 rounded w-full'
          required
        >
          <option value='' disabled>
            Select Role
          </option>
          <option value='User'>User</option>
          <option value='Admin'>Admin</option>
          <option value='Editor'>Editor</option>
        </select>
      </div>
      <div className='flex items-center space-x-3 justify-center mt-4 mb-2'>
        <button
          type='submit'
          className='px-4 py-2 bg-success w-full text-white rounded-r hover:bg-blue-600 transition-colors flex-1'
        >
          {mutation.isLoading ? 'Loading...' : 'Update User'}
        </button>
      </div>
    </form>
  )
}

export default UpdateForm
