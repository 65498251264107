import React from 'react'
import '../../utils/Loader.css'

const MainLoader = () => {
  return (
    <div className='relative flex justify-center items-center h-screen'>
      <div className='absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-purple-500'></div>
      <img
        src='https://www.svgrepo.com/show/509001/avatar-thinking-9.svg'
        className='rounded-full h-20 w-20 md:h-28 md:w-28'
        alt='loading ...'
      />
    </div>
  )
}

export default MainLoader
