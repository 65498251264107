import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { forgotPasswordService } from '../../api/apiServices'
import SuccessPage from '../../components/Success/SuccessPage'
import EmailError from '../../components/Success/EmailError'
import { notify } from '../../utils/utils'

const EmailReset = () => {
  const [isMessageSent, setIsMessageSent] = useState(false)
  const [isEmailFailed, setIsEmailFailed] = useState(false)
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsSendingEmail(true)
        const res = await forgotPasswordService(values.email)
        const data = res.data
        data?.message && setIsMessageSent(true)
        resetForm()
        setIsSendingEmail(false)

        if (res.status === 200) {
          notify('success', res.data.message, 100)
        } else {
          notify('error', res.data.error, 100)
        }
      } catch (error) {
        console.log(error)
        notify('error', 'Failed to reset password. Please try again.', 100)
        setErrorMessage(error.message)
        setIsEmailFailed(true)
        setIsSendingEmail(false)
      }
    },
  })

  if (isMessageSent) return <SuccessPage />
  if (isEmailFailed) return <EmailError errorMessage={errorMessage} />

  return (
    <div className='mx-auto max-w-screen-2xl h-[87vh] overflow-y-hidden flex px-5 md:px-0 items-center justify-center'>
      <div className='rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark w-full md:w-5/12'>
        <div className='flex flex-wrap items-center'>
          <div className='w-full p-4 sm:p-6.5 xl:p-5.5'>
            <h2 className='mb-3 text-xl font-bold text-black dark:text-white sm:text-title-xl2'>
              Reset Password
            </h2>
            <p className='mb-3.5 text-graydark'>
              Enter your email address to receive a password reset link.
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className='mb-6'>
                <label
                  className={`mb-2.5 block font-medium text-black dark:text-white ${
                    formik.touched.email && formik.errors.email
                      ? 'text-red-500'
                      : 'text-gray-700'
                  }`}
                >
                  {formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : 'Email'}
                </label>
                <div className='relative'>
                  <input
                    type='email'
                    name='email'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder='Enter your email'
                    className='w-full rounded-lg border border-stroke bg-transparent py-2 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary'
                  />
                  <span className='absolute right-4 top-4'>
                    <svg
                      className='fill-current'
                      width='22'
                      height='22'
                      viewBox='0 0 22 22'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g opacity='0.5'>
                        <path
                          d='M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z'
                          fill=''
                        ></path>
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div>
                <input
                  type='submit'
                  className='w-full cursor-pointer rounded-lg border border-primary bg-green-500 p-2 text-white transition hover:bg-opacity-90'
                  value={
                    isSendingEmail
                      ? 'Email is sending ...'
                      : 'Send Password Reset Link'
                  }
                  disabled={isSendingEmail}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailReset
