import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowUp } from 'react-icons/md'
import { useParams } from 'react-router-dom'

import MainLoader from '../../components/MainLoader/MainLoader'
import CheckoutSteps from '../../components/Breadcrumb/CheckoutSteps'
import SearchResult from '../../components/search/SearchResult'

import useProductStore from '../../zustand/productStore'
import {
  getProductByCategoryService,
  getProductBySubcategory,
} from '../../api/apiServices'

import Product from '../product/Product'
import { SortBy } from '../../components/filters'

const ProductFilterPage = ({ name }) => {
  const [loading, setLoading] = useState(true)
  const [showScrollArrow, setShowScrollArrow] = useState(false)
  const [products, setProducts] = useState([])

  const { filteredProductsByNameAndCategory, filteredProducts } =
    useProductStore()
  const param = useParams()

  const { type, name: type_name, id } = param

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response =
          type === 'category'
            ? await getProductByCategoryService(id)
            : await getProductBySubcategory(id)

        const { products } = response.data
        setProducts(products)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching products:', error)
        setLoading(false)
      }
    }

    fetchProducts()
  }, [id, type])

  useEffect(() => {
    const toggleShowArrow = () => {
      setShowScrollArrow(window.scrollY > 300)
    }

    window.addEventListener('scroll', toggleShowArrow)

    return () => {
      window.removeEventListener('scroll', toggleShowArrow)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {loading ? (
        <div className='h-[70vh] w-full flex items-center justify-center overflow-hidden'>
          <span>
            <MainLoader />
          </span>
        </div>
      ) : (
        <div>
          <div className='flex flex-col md:flex-row items-start md:items-center space-y-4  space-x-0 md:space-x-10 justify-between '>
            <CheckoutSteps step1='Home' step2={type} step3={type_name} />
          </div>
          <div className='my-5'>
            <div className=' flex  flex-1 py-2 px-2   justify-between items-center col-auto shadow-md bg-white mb-2'>
              <div className='flex flex-col md:flex-col space-x-2 items-center justify-center'>
                <span className='text-xs'>
                  {filteredProductsByNameAndCategory &&
                  filteredProductsByNameAndCategory.length !== 0
                    ? `${products.length}`
                    : `${filteredProducts.length}`}
                  items in{' '}
                </span>
                <span className='font-bold text-xs ml-2'>
                  `{type}/{type_name}`
                </span>
              </div>

              <SortBy />
            </div>
            <div>
              {filteredProductsByNameAndCategory &&
                filteredProductsByNameAndCategory.length !== 0 && (
                  <SearchResult />
                )}
            </div>
          </div>

          <main className='relative mx-auto gap-1  grid items-center py-4 grid-cols-2 sm:gap-2 md:gap-3 lg:grid-cols-5'>
            {filteredProducts && filteredProducts.length > 0
              ? filteredProducts.map((product) => (
                  <Product key={product.product_id} product={product} />
                ))
              : products.map((product) => (
                  <Product key={product.product_id} product={product} />
                ))}
          </main>
          <button
            className={`fixed z-30 h-10 w-10 bottom-10 bg-gray-800 right-2 p-2 rounded-full text-xl shadow-2xl transition-all delay-100 ease-in-out ${
              showScrollArrow ? 'block' : 'hidden'
            }`}
            onClick={scrollToTop}
          >
            <MdKeyboardArrowUp className=' text-white' />
          </button>
        </div>
      )}
    </>
  )
}

export default ProductFilterPage
