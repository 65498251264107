// import React, { useCallback, useEffect, useState } from 'react'
// import { Formik, Form, Field, ErrorMessage } from 'formik'
// import * as Yup from 'yup'
// import { getUserDetailsService, updateCrediential } from '../../api/apiServices'
// import { notify } from '../../utils/utils'
// import { Link } from 'react-router-dom'
// import Helmat from '../Helmat/Helmat'

// const ProfileSetting = () => {
//   const [userprofileData, setUserProfileData] = useState({})

//   const validationSchema = Yup.object().shape({
//     name: Yup.string().required('Full Name is required'),
//     oldPassword: Yup.string().required('Old password is required'),
//     newPassword: Yup.string().required('Password is required'),
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
//       .required('Confirm Password is required'),
//   })

//   const handleSubmit = useCallback(async (values, { resetForm }) => {
//     const userInfo = {
//       name: values.name,
//       oldPassword: values.oldPassword,
//       newPassword: values.newPassword,
//       confirmPassword: values.confirmPassword,
//     }

//     try {
//       const { data } = await updateCrediential(
//         userInfo.name,
//         userInfo.oldPassword,
//         userInfo.newPassword,
//         userInfo.confirmPassword
//       )
//       notify('success', data.message, 200)
//       resetForm()
//     } catch (error) {
//       notify('error', error.response.data.message, 200)
//       console.error('Error submitting form:', error)
//     }
//   }, [])

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const { data } = await getUserDetailsService()
//       setUserProfileData(data.user)
//     }
//     fetchProfile()
//   }, [])

//   const shippingInfo = JSON.parse(localStorage.getItem('shipping-info'))
//   console.log(typeof shippingInfo)

//   return (
//     <>
//       <Helmat title='profile' content='user profile nepsaymart' />

//       <div className='mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'>
//         <div className='mx-auto max-w-270'>
//           <Link to='/' className='text-xl text-red-500 underline'>
//             Back
//           </Link>

//           <div className='grid grid-cols-5 gap-8'>
//             <div className='col-span-5 xl:col-span-3'>
//               <div className='rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark'>
//                 <div className='border-b border-stroke py-4 px-7 dark:border-strokedark'>
//                   <h3 className='font-medium text-black dark:text-white'>
//                     Edit your information
//                   </h3>
//                 </div>
//                 <div className='p-7'>
//                   <Formik
//                     initialValues={{
//                       name: '',
//                       oldPassword: '',
//                       newPassword: '',
//                       confirmPassword: '',
//                     }}
//                     validationSchema={validationSchema}
//                     onSubmit={handleSubmit}
//                   >
//                     <Form>
//                       <div className='mb-3.5'>
//                         <label
//                           className='mb-3 block text-sm font-medium text-black dark:text-white'
//                           htmlFor='name'
//                         >
//                           Full Name
//                         </label>
//                         <div className='relative'>
//                           <Field
//                             className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
//                             type='text'
//                             name='name'
//                             id='name'
//                             placeholder='Enter full name'
//                           />
//                         </div>
//                         <ErrorMessage
//                           name='name'
//                           component='div'
//                           className='text-red-500'
//                         />
//                       </div>
//                       <div className='mb-5.5'>
//                         <label
//                           className='mb-3 block text-sm font-medium text-black dark:text-white'
//                           htmlFor='oldPassword'
//                         >
//                           Old Password
//                         </label>
//                         <div className='relative'>
//                           <Field
//                             className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
//                             type='password'
//                             name='oldPassword'
//                             id='oldPassword'
//                             placeholder='Enter old password ..'
//                           />
//                         </div>
//                         <ErrorMessage
//                           name='oldPassword'
//                           component='div'
//                           className='text-red-500'
//                         />
//                       </div>
//                       <div className='mb-5.5'>
//                         <label
//                           className='mb-3 block text-sm font-medium text-black dark:text-white'
//                           htmlFor='newPassword'
//                         >
//                           New Password
//                         </label>
//                         <Field
//                           className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
//                           type='password'
//                           name='newPassword'
//                           placeholder='Enter new password '
//                           id='newPassword'
//                         />
//                       </div>
//                       <ErrorMessage
//                         name='newPassword'
//                         component='div'
//                         className='text-red-500'
//                       />
//                       <div className='mb-5.5'>
//                         <label
//                           className='mb-3 block text-sm font-medium text-black dark:text-white'
//                           htmlFor='confirmPassword'
//                         >
//                           Confirm Password
//                         </label>
//                         <Field
//                           className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
//                           type='password'
//                           name='confirmPassword'
//                           placeholder='confirm password'
//                           id='confirmPassword'
//                         />
//                       </div>
//                       <ErrorMessage
//                         name='confirmPassword'
//                         component='div'
//                         className='text-red-500'
//                       />

//                       <div className='flex justify-end gap-4.5'>
//                         <button
//                           className='flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white'
//                           type='reset'
//                         >
//                           Cancel
//                         </button>
//                         <button
//                           className='flex justify-center rounded bg-green-500 py-2 px-6 font-medium text-gray hover:bg-opacity-95'
//                           type='submit'
//                         >
//                           Save
//                         </button>
//                       </div>
//                     </Form>
//                   </Formik>
//                 </div>
//               </div>
//             </div>
//             <div className='col-span-5 xl:col-span-2'>
//               <div className='flex flex-col gap-4'>
//                 <div className='rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark'>
//                   <div className='border-b border-stroke py-4 px-7 dark:border-strokedark'>
//                     <h3 className='font-semibold text-black dark:text-white'>
//                       Your Personal information
//                     </h3>
//                   </div>
//                   <div className='p-7'>
//                     <div className='flex flex-col gap-2'>
//                       <h3 className='font-medium text-black dark:text-white'>
//                         {' '}
//                         <span className='font-semibold text-black-2'>
//                           Name :
//                         </span>{' '}
//                         {userprofileData.name}
//                       </h3>
//                       <p className='font-medium text-black dark:text-white'>
//                         <span className='font-semibold text-black-2 dark:text-white'>
//                           Email :
//                         </span>{' '}
//                         {''}
//                         {userprofileData.email}
//                       </p>
//                       {userprofileData.role !== 'USER' && (
//                         <p className='font-medium text-black dark:text-white'>
//                           <span className='font-semibold text-black-2 dark:text-white'>
//                             Role :
//                           </span>{' '}
//                           {''}
//                           {userprofileData.role}
//                         </p>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//                 {shippingInfo && (
//                   <div className='rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark'>
//                     <div className='border-b border-stroke py-4 px-7 dark:border-strokedark'>
//                       <h3 className='font-semibold text-black dark:text-white'>
//                         Your shipping information
//                       </h3>
//                     </div>
//                     <div className='p-7'>
//                       <div className='flex flex-col gap-2'>
//                         <p className='font-medium text-black dark:text-white'>
//                           <span className='font-semibold text-black-2 dark:text-white'>
//                             Street Address :
//                           </span>{' '}
//                           {''}
//                           {shippingInfo.street_address}
//                         </p>
//                         <p className='font-medium text-black dark:text-white'>
//                           <span className='font-semibold text-black-2 dark:text-white'>
//                             Suberb :
//                           </span>{' '}
//                           {''}
//                           {shippingInfo.suberb}
//                         </p>
//                         <p className='font-medium text-black dark:text-white'>
//                           <span className='font-semibold text-black-2 dark:text-white'>
//                             State :
//                           </span>{' '}
//                           {''}
//                           {shippingInfo.state}
//                         </p>
//                         <p className='font-medium text-black dark:text-white'>
//                           <span className='font-semibold text-black-2 dark:text-white'>
//                             Phone :
//                           </span>{' '}
//                           {''}
//                           {shippingInfo.phone_number}
//                         </p>
//                         <p className='font-medium text-black dark:text-white'>
//                           <span className='font-semibold text-black-2 dark:text-white'>
//                             Postal code :
//                           </span>{' '}
//                           {''}
//                           {shippingInfo.postal_code}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default ProfileSetting
import React, { useCallback, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getUserDetailsService,
  updateCrediential,
} from "../../api/apiServices";
import { notify } from "../../utils/utils";
import { Link } from "react-router-dom";
import Helmat from "../Helmat/Helmat";

const ProfileSetting = () => {
  const [userprofileData, setUserProfileData] = useState({});
  const [isEditingShipping, setIsEditingShipping] = useState(false);
  const [shippingInfo, setShippingInfo] = useState(
    JSON.parse(localStorage.getItem("shipping-info")) || {}
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = useCallback(async (values, { resetForm }) => {
    const userInfo = {
      name: values.name,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    };

    try {
      const { data } = await updateCrediential(
        userInfo.name,
        userInfo.oldPassword,
        userInfo.newPassword,
        userInfo.confirmPassword
      );
      notify("success", data.message, 200);
      resetForm();
    } catch (error) {
      notify("error", error.response.data.message, 200);
      console.error("Error submitting form:", error);
    }
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      const { data } = await getUserDetailsService();
      setUserProfileData(data.user);
    };
    fetchProfile();
  }, []);

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleShippingSave = () => {
    // Save the updated shipping information
    localStorage.setItem("shipping-info", JSON.stringify(shippingInfo));
    setIsEditingShipping(false);
  };

  return (
    <>
      <Helmat title="profile" content="user profile nepsaymart" />

      <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
        <div className="mx-auto max-w-270">
          <Link to="/" className="text-xl text-red-500 underline">
            Back
          </Link>

          <div className="grid grid-cols-5 gap-8">
            <div className="col-span-5 xl:col-span-3">
              <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                  <h3 className="font-medium text-black dark:text-white">
                    Edit your information
                  </h3>
                </div>
                <div className="p-7">
                  <Formik
                    initialValues={{
                      name: "",
                      oldPassword: "",
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div className="mb-3.5">
                        <label
                          className="mb-3 block text-sm font-medium text-black dark:text-white"
                          htmlFor="name"
                        >
                          Full Name
                        </label>
                        <div className="relative">
                          <Field
                            className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter full name"
                          />
                        </div>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="mb-5.5">
                        <label
                          className="mb-3 block text-sm font-medium text-black dark:text-white"
                          htmlFor="oldPassword"
                        >
                          Old Password
                        </label>
                        <div className="relative">
                          <Field
                            className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                            type="password"
                            name="oldPassword"
                            id="oldPassword"
                            placeholder="Enter old password .."
                          />
                        </div>
                        <ErrorMessage
                          name="oldPassword"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="mb-5.5">
                        <label
                          className="mb-3 block text-sm font-medium text-black dark:text-white"
                          htmlFor="newPassword"
                        >
                          New Password
                        </label>
                        <Field
                          className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="password"
                          name="newPassword"
                          placeholder="Enter new password "
                          id="newPassword"
                        />
                      </div>
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="text-red-500"
                      />
                      <div className="mb-5.5">
                        <label
                          className="mb-3 block text-sm font-medium text-black dark:text-white"
                          htmlFor="confirmPassword"
                        >
                          Confirm Password
                        </label>
                        <Field
                          className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="password"
                          name="confirmPassword"
                          placeholder="confirm password"
                          id="confirmPassword"
                        />
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-red-500"
                      />

                      <div className="flex justify-end gap-4.5">
                        <button
                          className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                          type="reset"
                        >
                          Cancel
                        </button>
                        <button
                          className="flex justify-center rounded bg-green-500 py-2 px-6 font-medium text-gray hover:bg-opacity-95"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
            <div className="col-span-5 xl:col-span-2">
              <div className="flex flex-col gap-4">
                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                  <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                    <h3 className="font-semibold text-black dark:text-white">
                      Your Personal information
                    </h3>
                  </div>
                  <div className="p-7">
                    <div className="flex flex-col gap-2">
                      <h3 className="font-medium text-black dark:text-white">
                        {" "}
                        <span className="font-semibold text-black-2">
                          Name :
                        </span>{" "}
                        {userprofileData.name}
                      </h3>
                      <p className="font-medium text-black dark:text-white">
                        <span className="font-semibold text-black-2 dark:text-white">
                          Email :
                        </span>{" "}
                        {""}
                        {userprofileData.email}
                      </p>
                      {userprofileData.role !== "USER" && (
                        <p className="font-medium text-black dark:text-white">
                          <span className="font-semibold text-black-2 dark:text-white">
                            Role :
                          </span>{" "}
                          {""}
                          {userprofileData.role}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {shippingInfo && (
                  <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                      <h3 className="font-semibold text-black dark:text-white">
                        Your shipping information
                      </h3>
                      <button
                        className="ml-4 mt-3 px-3 py-1 bg-blue-500 text-white rounded"
                        onClick={() => setIsEditingShipping(!isEditingShipping)}
                      >
                        {isEditingShipping ? "Cancel" : "Edit"}
                      </button>
                    </div>
                    <div className="p-7">
                      {isEditingShipping ? (
                        <>
                          <div className="mb-3.5">
                            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                              Country
                            </label>
                            <input
                              className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              type="text"
                              name="country"
                              value={shippingInfo.country || ""}
                              onChange={handleShippingChange}
                            />
                          </div>
                          <div className="mb-3.5">
                            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                              Address
                            </label>
                            <input
                              className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              type="text"
                              name="street_address"
                              value={shippingInfo.street_address || ""}
                              onChange={handleShippingChange}
                            />
                          </div>
                          <div className="mb-3.5">
                            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                              City
                            </label>
                            <input
                              className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              type="text"
                              name="city"
                              value={shippingInfo.city || ""}
                              onChange={handleShippingChange}
                            />
                          </div>
                          <div className="mb-3.5">
                            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                              State
                            </label>
                            <input
                              className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              type="text"
                              name="state"
                              value={shippingInfo.state || ""}
                              onChange={handleShippingChange}
                            />
                          </div>
                          <div className="mb-3.5">
                            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                              Phone Number
                            </label>
                            <input
                              className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              type="text"
                              name="phone_number"
                              value={shippingInfo.phone_number || ""}
                              onChange={handleShippingChange}
                            />
                          </div>
                          <div className="mb-3.5">
                            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                              Suburb
                            </label>
                            <input
                              className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              type="text"
                              name="suburb"
                              value={shippingInfo.suburb || ""}
                              onChange={handleShippingChange}
                            />
                          </div>

                          
                          <div className="mb-3.5">
                            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                              Postal Code
                            </label>
                            <input
                              className="w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              type="text"
                              name="postalCode"
                              value={shippingInfo.postalCode || ""}
                              onChange={handleShippingChange}
                            />
                          </div>

                          <button
                            className="px-4 py-2 bg-green-500 text-white rounded"
                            onClick={handleShippingSave}
                          >
                            Save Shipping Info
                          </button>
                        </>
                      ) : (
                        <>
                          <p className="font-medium mb-1s text-black dark:text-white">
                            <span className="font-semibold text-black-2 dark:text-white">
                              Country:
                            </span>{" "}
                            {shippingInfo.country || "N/A"}
                          </p>
                          <p className="font-medium mb-1 text-black dark:text-white">
                            <span className="font-semibold text-black-2 dark:text-white">
                              Address:
                            </span>{" "}
                            {shippingInfo.street_address|| "N/A"}
                          </p>
                          <p className="font-medium mb-1 text-black dark:text-white">
                            <span className="font-semibold text-black-2 dark:text-white">
                              City:
                            </span>{" "}
                            {shippingInfo.city || "N/A"}
                          </p>
                          <p className="font-medium mb-1 text-black dark:text-white">
                            <span className="font-semibold text-black-2 dark:text-white">
                              State:
                            </span>{" "}
                            {shippingInfo.State || "N/A"}
                          </p>
                          <p className="font-medium mb-1 text-black dark:text-white">
                            <span className="font-semibold text-black-2 dark:text-white">
                              Phone Number:
                            </span>{" "}
                            {shippingInfo.phone_number || "N/A"}
                          </p>
                          <p className="font-medium mb-1 text-black dark:text-white">
                            <span className="font-semibold text-black-2 dark:text-white">
                              Suburb:
                            </span>{" "}
                            {shippingInfo.suburb || "N/A"}
                          </p>
                          


                          <p className="font-medium text-black dark:text-white">
                            <span className="font-semibold text-black-2 dark:text-white">
                              Postal Code:
                            </span>{" "}
                            {shippingInfo.postalCode || "N/A"}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSetting;
