const baseUrl = process.env.REACT_APP_BACKEND_URL;
//auth url
export const SIGNUP_URL = `${baseUrl}/register`;
export const LOGIN_URL = `${baseUrl}/login`;
export const LOGOUT_URL = `${baseUrl}/logout`;
export const FORGOT_PASSWORD_URL = `${baseUrl}/forgetpassword`;
export const RESET_PASSWORD_URL = `${baseUrl}/resetpassword`;
export const UPDATE_PASSWORD = `${baseUrl}/updateuserprofile`;

export const VERYFY_EMAIL_URL = `${baseUrl}/emailverification`;

//user url
export const UPDATE_USER_ROLE = `${baseUrl}/updateuser`;
export const USER_URL = `${baseUrl}/userlist`;
export const USER_DETAILS_URL = `${baseUrl}/profile`;
export const USER_DELETE_URL = `${baseUrl}/deleteuser`;

//feature image upload
export const UPLOAD_FEATURE_IMAGES = `${baseUrl}/uploadFeatureImg`;
export const GET_FEATURE_IMAGES = `${baseUrl}/getAllFeatureImages`;
export const DELETE_FEATURE_IMAGE = `${baseUrl}/deleteFeatureImage`;

//products url
export const ADD_PRODUCT_URL = `${baseUrl}/addproduct`;
export const UPDATE_PRODUCT_URL = `${baseUrl}/updateproduct`;
export const DELETE_PRODUCT_URL = `${baseUrl}/deleteproduct`;
export const PRODUCTS_URL = `${baseUrl}/getallproducts`;
// export const PRODUCT_DETAILS_URL = `${baseUrl}/getProductById`;
export const PRODUCT_DETAILS_URL = `${baseUrl}/getproductbyName`;
export const getTopSoldProducts = `${baseUrl}/getTopSoldProducts`;

//category url
export const CATEGORIES_URL = `${baseUrl}/getallcategory`;
export const ADD_CATEGORY_URL = `${baseUrl}/addcategory`;
export const UPDATE_CATEGORY_URL = `${baseUrl}/updatecategory`;
export const DELETE_CATEGORY_URL = `${baseUrl}/deletecategory`;
export const SHOW_PRODUCTS_BY_CATEGORY_URL = `${baseUrl}/getProductByCategory`;
export const GET_ALL_CATEGORY_WITH_SUBCATEGORY_URL = `${baseUrl}/getallcategorywithsubcategory`;
export const GET_ALL_CATEGORY_WITH_PRODUCT_URL = `${baseUrl}/getallcategorieswithproducts`;

//subcategory url
export const SUBCATEGORIES_URL = `${baseUrl}/getallsubcategory`;
export const ADD_SUBCATEGORY_URL = `${baseUrl}/addsubcategory`;
export const UPDATE_SUBCATEGORY_URL = `${baseUrl}/updatesubcategory`;
export const DELETE_SUBCATEGORY_URL = `${baseUrl}/deletesubcategory`;
export const GET_PRODUCTS_BY_SUBCATEGORY_URL = `${baseUrl}/getProductBySubcategory`;

//order url
export const ORDER_URL = `${baseUrl}/orders`;
export const ORDER_DETAILS_URL = `${baseUrl}/orders`;
export const MY_ORDERS = `${baseUrl}/myorders`;
export const UPDATE_ORDER_STATUS = `${baseUrl}/shipping`;
export const GET_ALL_ORDERS = `${baseUrl}/orders`;
export const GET_ORDERS_BY_PAYMENT_STATUS = `${baseUrl}/getordersbypaymentstatus`;
export const GET_ORDERS_BY_SHIPPING_STATUS = `${baseUrl}/getordersbyshippingstatus`;

//guest order
export const GUEST_ORDER_URL = `${baseUrl}/guestOrders`;

//shipping url
export const SHIPPING_URL = `${baseUrl}/orders/shipping`;

//update order to paid
export const UPDATE_PAYMENT_STATUS = `${baseUrl}/payment`;
//payment url
export const PAYMENT_URL = `${baseUrl}/create_checkout_session`;
//cart url
export const CART_URL = `${baseUrl}/user/cart`;

//dashboard url
export const DASHBOARD_URL = `${baseUrl}/getstats`;

// filter url
export const SUBCATEGORY_KEYWORD_SEARCH = `${baseUrl}/search/subcategoryKeywordSearch`;
export const PRICE_RANGE_SEARCH = `${baseUrl}/search/min/max`;
export const SEARCH_PRODUCT_BY_KEYWORD = `${baseUrl}/search`;

// discount url
export const ADD_COUPON_URL = `${baseUrl}/coupon/add`;
export const GET_ALL_COUPON_URL = `${baseUrl}/coupon/getall`;
export const DELETE_COUPON_URL = `${baseUrl}/coupon/delete`;
export const GET_COUPON_BY_ID_URL = `${baseUrl}/coupon/getbyid`;
export const CHECK_COUPON_CODE_URL = `${baseUrl}/coupon/check`;

// other service url
export const Add_OTHER_SERVICE_URL = `${baseUrl}/otherservices/add`;
export const GET_ALL_OTHER_SERVICE_URL = `${baseUrl}/otherservices/getall`;
export const DELETE_OTHER_SERVICE_URL = `${baseUrl}/otherservices/delete`;
export const GET_OTHER_SERVICE_BY_ID_URL = `${baseUrl}/otherservices/getbyid`;
export const UPDATE_OTHER_SERVICE_URL = `${baseUrl}/otherservices/update`;
export const CLICKED_OTHER_SERVICE_URL = `${baseUrl}/otherservices/clicked`;
export const SEND_EMAIL_OTHER_SERVICE_URL = `${baseUrl}/otherservices/sendemail`;
