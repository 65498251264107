import React, { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { Modal } from '../Category/Modal'
import TermsConditions from './TermsConditions'
import BackToHome from '../Breadcrumb/BackToHome'

import { useQuery } from '@tanstack/react-query'
import { getAllLinkService } from '../../api/apiServices'
import AddServiceCustomerForm from './AddServiceCustomerForm'
import OtherServiceCarousel from '../Carousel/OtherServiceCarousel'
import useScrollToTop from '../../hooks/useScrollToTop'
import ServiceDescription from './ServiceDescription'

const OtherServices = () => {
  useScrollToTop()

  const [isOpen, setIsOpen] = useState(false)
  const [service, setService] = useState({})
  const [modalType, setModalType] = useState('verified')

  const handleChangeModalType = (type) => {
    setModalType(type)
  }

  const handleService = (service) => {
    setIsOpen(true)
    setService(service)
  }

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['links'],
    queryFn: getAllLinkService,
  })

  const servicesData = data?.data

  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error: {error.message}</div>

  return (
    <div className='mt-10 md:mt-4 px-5 md:0'>
      <BackToHome />
      <OtherServiceCarousel />
      <h2 className='text-xl font-bold text-green-700 mt-10'>Services List</h2>
      <ul className='mx-auto gap-5 grid  py-4 grid-cols-2 md:gap-3 lg:grid-cols-5'>
        {servicesData.map((service) => (
          <div
            key={service.id}
            className='flex h-fit flex-col xs:flex-row sm:flex-col  bg-white/[0.5] rounded-lg shadow-md border-2 border-black/[0.05]
              cursor-pointer
              transition-transform
              hover:scale-[1.02] hover:shadow-lg'
          >
            <div
              onClick={() => handleService(service)}
              className=' relative flex items-center justify-center  bg-black/[0.075]  xs:w-1/2 w-full sm:w-full'
            >
              <img
                src={service.image}
                alt=''
                className='w-full object-cover xs:object-contain sm:object-cover h-35 rounded-t-md'
              />
              {service.discount !== 0 && (
                <div className='absolute left-0 bottom-3/2 shadow-lg w-[150px] h-[150px] transform  text-lg bg-yellow-600 bg-opacity-75 text-white overflow-hidden rounded-br-full '>
                  <div className='px-3 py-4'>
                    <span className='font-bold text-gray-800'>Get</span>
                    <p className='font-bold text-3xl text-whiten'>
                      {service.discount}
                      <span className='text-4xl'>%</span>
                    </p>
                    <span className='text-md'>OFF</span>
                  </div>
                </div>
              )}
              <div className='flex items-center absolute right-3 -bottom-4'>
                <button
                  className={`text-sm  rounded-full h-10 w-10  bg-slate-600 text-white transition hover:shadow-md disabled:cursor-not-allowed flex items-center justify-center`}
                >
                  <BsSearch />
                </button>
              </div>
            </div>
            <div className='p-3 flex flex-col justify-between gap-2 mt-2 h-1/2 xs:h-full sm:h-1/2 xs:w-2/3 w-full sm:w-full'>
              <ServiceDescription
                data={{
                  name: service.name,
                  description: service.description,
                }}
              />
            </div>
          </div>
        ))}
      </ul>

      {
        // conditional rendering
        modalType === 'not_verified' ? (
          <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <AddServiceCustomerForm service={service} setIsOpen={setIsOpen} />
          </Modal>
        ) : (
          <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <TermsConditions
              service={service}
              setIsOpen={setIsOpen}
              handleChangeModalType={handleChangeModalType}
            />
          </Modal>
        )
      }
    </div>
  )
}

export default OtherServices
