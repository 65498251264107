import React, { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteFeatureImage, getAllFeatureImages } from "../../api/apiServices";
import { toast } from "react-toastify";
import AddFeatureImage from "./AddFeatureImage";
import { Modal } from "../Category/Modal";
import { Link } from "react-router-dom";

const UploadFeatureProduct = () => {
  const [addFeatureImage, setAddFeatureImage] = useState(false);

  const queryClient = useQueryClient();
  const { isLoading, data: featureImages } = useQuery({
    queryKey: ["featureImages"],
    queryFn: getAllFeatureImages,
  });

  const handleFeatureImage = () => {
    setAddFeatureImage(true);
  };

  const deleteMutation = useMutation(deleteFeatureImage, {
    onSuccess: () => {
      // query cache invalidation
      queryClient.invalidateQueries("featureImages");
    },
  });

  const handleDelete = async (id) => {
    const confirmToastId = toast.warning(
      <div>
        <p className="text-xs">Are you sure you want to delete this image?</p>
        <div className="w-7/12 flex space-x-2 mt-2 ">
          <button
            className="bg-red-500 text-xs hover:bg-green-700 text-white font-normal py-1 px-4 rounded"
            onClick={() => {
              deleteMutation.mutate(id);

              toast.dismiss(confirmToastId);
              toast.success("Feature image deleted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }}
          >
            Yes
          </button>
          <button
            className="bg-green-500 text-xs hover:bg-green-700 text-white font-normal py-1 px-4 rounded"
            onClick={() => {
              toast.dismiss(confirmToastId);
            }}
          >
            No
          </button>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        autoClose: false, // Disabled auto-closing
        hideProgressBar: true,
      },
    );
  };

  return (
    <>
      <div className="flex justify-between items-center  mb-4">
        <h4 className=" md:text-xl text-sm font-semibold text-black dark:text-white">
          Feature Image Management
        </h4>
        <button
          className="bg-success text-white font-normal py-2 px-4 rounded"
          onClick={() => handleFeatureImage()}
        >
          Add Feature Info
        </button>
      </div>
      <div className="rounded-sm border  border-stroke w-full bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="overflow-x-auto">
          <table className="table-auto min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  S.N
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  URL
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Preview
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  ACTION
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan="4" className="text-center">
                    Loading...
                  </td>
                </tr>
              </tbody>
            ) : (
              featureImages?.data.images.map((item, index) => (
                <tbody key={item.id}>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-black-2 whitespace-nowrap dark:text-white"
                    >
                      {index + 1}
                    </th>
                    <td className="px-6 text-center my-auto overflow-ellipsis line-clamp-1">
                      <Link to={item.url}>{item?.url}</Link>
                    </td>
                    <td className="px-6 py-4">{item?.category_type}</td>

                    <td className="px-6 ">
                      <img
                        src={item?.url}
                        alt="featureimage"
                        className="h-10 w-10 rounded-md"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <button onClick={() => handleDelete(item.id)}>
                        <RiDeleteBinLine className="text-2xl" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))
            )}
          </table>
        </div>
      </div>

      <Modal
        title="Add Feature Info"
        isOpen={addFeatureImage}
        setIsOpen={setAddFeatureImage}
      >
        <AddFeatureImage setAddFeatureImage={setAddFeatureImage} />
      </Modal>
    </>
  );
};

export default UploadFeatureProduct;
