import React, { useState } from 'react'
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci'

const ServiceDescription = ({ data }) => {
  const [showFullDescription, setShowFullDescription] = useState(false)

  const handleToggle = () => {
    setShowFullDescription(!showFullDescription)
  }
  return (
    <div className='flex flex-col'>
      <span className='text-base font-bold '>{data.name}</span>
      {showFullDescription ? (
        <p className='text-justify'>{data.description}</p>
      ) : (
        <p className='line-clamp-3 text-justify'>{data.description}</p>
      )}
      <div className='flex justify-end '>
        <button onClick={handleToggle} className='text-blue-600'>
          {showFullDescription ? (
            <div className='flex items-center space-x-1'>
              <span>show</span> <CiCircleMinus className='text-xl' />{' '}
            </div>
          ) : (
            <div className='flex items-center space-x-1'>
              <span>show</span> <CiCirclePlus className='text-xl' />
            </div>
          )}
        </button>
      </div>
    </div>
  )
}

export default ServiceDescription
