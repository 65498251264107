import { create } from 'zustand'
import { getUserListService } from '../api/apiServices'

const useUserListStore = create((set, get) => ({
  users: [],
  totalCount: 0,
  totalPages: 0,

  setUsers: (users) => {
    set({ users })
  },

  // Get users
  getUsers: async (page, perPage) => {
    try {
      const {data} = await getUserListService(page,perPage)
      set({ users:data })
    }
    catch (error) {
      console.error('Error fetching users:', error)
    }

  },

  // Add user
  addUser: (user) => {
    set((state) => ({ users: [...state.users, user] }))
  },

  // Update user by id
  updateUser: (userId, updatedUser) => {
    set((state) => ({
      users: state.users.map((user) =>
        user.id === userId ? updatedUser : user
      ),
    }))
  },

  // Delete user by id
  deleteUser: (userId) => {
    set((state) => ({
      users: state.users.filter((user) => user.id !== userId),
    }))
  },

  // Get user by id
  getUserById: (userId) => {
    const state = get()
    return state.users.find((user) => user.id === userId)
  },
}))

export default useUserListStore
