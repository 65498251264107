import React from 'react'
import emptyBag from '../../assets/empty-shopping-bag.png'
import { useNavigate } from 'react-router'

import CartItemCard from '../../components/Cart/CartItemCard'
import CartTotalCard from '../../components/Cart/CartTotalCard'

import cartStore from '../../zustand/cartStore'
import { CiCircleRemove } from 'react-icons/ci'
import BackToHome from '../../components/Breadcrumb/BackToHome'
import Helmat from '../../components/Helmat/Helmat'
import useScrollToTop from '../../hooks/useScrollToTop'

const CartPageTest = () => {
  const navigate = useNavigate()
  const cartItems = cartStore((state) => state.cartItems)
  const removeAllFromCart = cartStore((state) => state.removeAllFromCart)

  useScrollToTop()
  const handleRemoveAll = () => {
    removeAllFromCart()
  }

  return (
    <>
      <Helmat title='Your Shopping cart' content='nepsaymart shopping cart' />

      <div className=' mx-auto mt-10 md:mt-2'>
        <BackToHome />
        {cartItems.length ? (
          <div className='flex flex-col  md:flex-row justify-start '>
            {cartItems.length > 0 && (
              <h1 className='text-2xl font-bold p-5 '>
                Cart({cartItems.length})
              </h1>
            )}
            <main className='w-full md:w-6/12 px-5 '>
              {cartItems.map((product, idx) => (
                <CartItemCard product={product} key={idx} />
              ))}
              <hr />
              <div className='mt-2 flex items-center justify-between w-full'>
                <button
                  onClick={() => handleRemoveAll()}
                  type='button'
                  className=' hover:text-red-600 flex items-end space-x-2 px-4 py-1 shadow-md rounded-md bg-slate-100 hover:bg-slate-200'
                >
                  <CiCircleRemove size={16} />
                  <span className='text-black  hover:text-red-600'>
                    Remove all
                  </span>
                </button>

                <button
                  className='btn bg-orange-500 px-3 py-1 rounded-md text-white  border-1 border-pink-700 hover:bg-orange-600  cursor-pointer  '
                  onClick={() => navigate('/')}
                >
                  Back to Shop
                </button>
              </div>
            </main>
            <CartTotalCard />
          </div>
        ) : (
          <div className='h-[60vh] w-full flex flex-col items-center justify-center  gap-3 '>
            <img
              src={emptyBag}
              alt='empty bag'
              className='h-36 -rotate-12 mt-5 drop-shadow-lg'
            />
            <div className='text-center'>
              <h2 className='text-2xl font-bold'>Hey, it feels so light!</h2>
              <p className='text-sm text-slate-500'>
                There's nothing in your bag. Let's add some items.
              </p>
            </div>

            <button
              className='btn-rounded-secondary text-sm mt-5'
              onClick={() => navigate('/')}
            >
              Explore
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default CartPageTest
