import React, { useState } from 'react'
import useProductStore from '../../zustand/productStore'

const SortBy = () => {
  const { setFilteredByPrice } = useProductStore()

  const [selectedOption, setSelectedOption] = useState('')

  const handleSortChange = (event) => {
    setSelectedOption(event.target.value)
    setFilteredByPrice(event.target.value)
  }

  return (
    <label>
      <select
        name='sortBy'
        className=' cursor-pointer  text-black underline '
        value={selectedOption}
        onChange={handleSortChange}
      >
        <option value='' disabled>
          Sort By Price
        </option>
        <option value='asc'>Low to High</option>
        <option value='dec'>High to Low</option>
      </select>
    </label>
  )
}

export default SortBy
