import React from 'react'
import useCartStore from '../../zustand/cartStore'
import { orderService } from '../../api/apiServices'
import { notify } from '../../utils/utils'
import { useNavigate } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

const AddressForm = ({
  setShippingInfoModal,
  setOrderStatus,
  paymentOptions,
  coupon_info,
}) => {
  const navigate = useNavigate()
  const cartItems = useCartStore((state) => state.cartItems)

  const validationSchema = Yup.object().shape({
    street_address: Yup.string().required('Required'),
    suberb: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    postal_code: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    phone_number: Yup.string()
      .required('Required')
      .matches(/^[0-9]{10}$/, 'Phone number must be a valid 10-digit number'),
  })

  const formik = useFormik({
    initialValues: {
      street_address: '',
      suberb: '',
      state: '',
      postal_code: '',
      country: 'Australia',
      phone_number: '',
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setOrderStatus({ loading: true, orderId: null, error: null })
        const { data } = await orderService(
          cartItems,
          paymentOptions,
          values,
          coupon_info
        )
        resetForm()
        notify('success', 'order created successfully')
        navigate(`/order/${data.order.order_id}`)
      } catch (error) {
        setOrderStatus({ loading: false, orderId: null, error: error.message })
        console.error('Error during order:', error)
      }

      setShippingInfoModal(false)
    },
  })

  return (
    <>
      <div className='flex flex-col'>
        <div className='rounded-sm border border-stroke bg-white  dark:border-strokedark dark:bg-boxdark'>
          <form
            className='rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5  dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1'
            onSubmit={formik.handleSubmit}
          >
            <div className=''>
              <div className='mb-4.5 flex flex-col gap-6 xl:flex-row'>
                <div className='w-full xl:w-1/2'>
                  <label
                    className={`mb-3 block text-sm font-medium text-black dark:text-white ${
                      formik.touched.country && formik.errors.country
                        ? 'text-red-500'
                        : ''
                    }`}
                  >
                    {formik.touched.country && formik.errors.country
                      ? formik.errors.country
                      : 'Country'}
                  </label>
                  <input
                    required
                    type='text'
                    className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                    placeholder='Australia'
                    name='country'
                    defaultValue={formik.values.country}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='w-full xl:w-1/2'>
                  <label
                    className={`mb-3 block text-sm font-medium text-black dark:text-white ${
                      formik.touched.street_address &&
                      formik.errors.street_address
                        ? 'text-red-500'
                        : ''
                    }`}
                  >
                    {formik.touched.street_address &&
                    formik.errors.street_address
                      ? formik.errors.street_address
                      : 'Street Address'}
                  </label>
                  <input
                    required
                    type='text'
                    placeholder='sydney-123'
                    className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                    onChange={formik.handleChange}
                    value={formik.street_address}
                    name='street_address'
                  />
                </div>
              </div>
              <div className='mb-4.5'>
                <label
                  className={`mb-3 block text-sm font-medium text-black dark:text-white ${
                    formik.touched.suberb && formik.errors.suberb
                      ? 'text-red-500'
                      : ''
                  }`}
                >
                  {formik.touched.suberb && formik.errors.suberb
                    ? formik.errors.suberb
                    : 'Enter suberb'}
                </label>
                <input
                  required
                  type='text'
                  placeholder='suberb'
                  className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                  onChange={formik.handleChange}
                  name='suberb'
                  value={formik.suberb}
                />
              </div>
              <div className='mb-4.5'>
                <label
                  className={`mb-3 block text-sm font-medium text-black dark:text-white ${
                    formik.touched.state && formik.errors.state
                      ? 'text-red-500'
                      : ''
                  }`}
                >
                  {formik.touched.state && formik.errors.state
                    ? formik.errors.state
                    : 'Enter state'}
                </label>
                <input
                  required
                  type='text'
                  placeholder='state'
                  className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                  onChange={formik.handleChange}
                  name='state'
                  value={formik.state}
                />
              </div>
              <div className='mb-4.5'>
                <label
                  className={`mb-3 block text-sm font-medium text-black dark:text-white ${
                    formik.touched.postal_code && formik.errors.postal_code
                      ? 'text-red-500'
                      : ''
                  }`}
                >
                  {formik.touched.postal_code && formik.errors.postal_code
                    ? formik.errors.postal_code
                    : 'Enter postal code'}
                </label>
                <input
                  required
                  type='string'
                  placeholder='postal code'
                  className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                  onChange={formik.handleChange}
                  name='postal_code'
                  value={formik.postal_code}
                />
              </div>
              <div className='mb-4.5'>
                <label
                  className={`mb-3 block text-sm font-medium text-black dark:text-white ${
                    formik.touched.phone_number && formik.errors.phone_number
                      ? 'text-red-500'
                      : ''
                  }`}
                >
                  {formik.touched.phone_number && formik.errors.phone_number
                    ? formik.errors.phone_number
                    : 'Enter phone number'}
                </label>
                <input
                  required
                  type='number'
                  placeholder='phone number'
                  className='w-full rounded border border-stroke bg-gray py-2 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                  onChange={formik.handleChange}
                  name='phone_number'
                  value={formik.phone_number}
                />
              </div>

              <button
                className='mb-4.5 text-base py-2 w-full bg-red-700 flex items-center justify-center rounded-lg text-white'
                type='submit'
              >
                Confirm Order
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddressForm
