import React from 'react'
import { Helmet } from 'react-helmet'

const Helmat = ({ title, content }) => {
  return (
    <div>
      <Helmet>
        <meta charset='utf-8' />
        <title>{title}</title>
        <meta name='description' content={content} />
      </Helmet>
    </div>
  )
}

export default Helmat
