import { Outlet } from "react-router-dom";
import { Filters } from "../components/filters";
import { AiOutlineShoppingCart } from "react-icons/ai";
import useProductStore from "../zustand/productStore";
import Header from "../pages/dashboard/Header/Header";
import { useCallback, useEffect, useState } from "react";
import { searchProductByKeywordService } from "../api/apiServices";
import { Link } from "react-router-dom";
import useCartStore from "../zustand/cartStore";
// import MainLoader from '../components/MainLoader/MainLoader'

const ProductListLayout = () => {
  const { isFilterOpen, setIsFilterOpen } = useProductStore((state) => state);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [search, setSearch] = useState("");

  const { setFilteredProductsByNameAndCategory } = useProductStore();

  const fetchResults = useCallback(async () => {
    try {
      const { data } = await searchProductByKeywordService(search);
      setFilteredProductsByNameAndCategory(data);
    } catch (error) {
      console.error("Error searching for products:", error);
    } finally {
    }
  }, [search, setFilteredProductsByNameAndCategory]);
  const { totalQuantity } = useCartStore();

  const changeHandler = (e) => {
    if (e.target.value === "") {
      setFilteredProductsByNameAndCategory([]);
    }
    setSearch(e.target.value);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (search && search.trim() !== "") {
        fetchResults();
      }
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search, fetchResults]);

  return (
    <>
      <div className="dark:bg-boxdark-2 dark:text-bodydark">
        <div className="flex h-screen overflow-hidden ">
          <div className="flex w-full justify-between h-screen">
            <Filters
              isFilterOpen={isFilterOpen}
              s
              setIsFilterOpen={setIsFilterOpen}
              className={`${
                isFilterOpen ? "block" : "hidden"
              } md:block bg-gray-200 `}
            />
            <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
              <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
                
                <form
                  className="mr-6 flex md:gap-150"
                  onSubmit={(e) => {
                    e.preventDefault();
                    changeHandler(e);
                  }}
                >
                  <div className="relative">
                    {/* <button className="absolute top-1/2 left-0 -translate-y-1/2">
                      <svg
                        className="fill-body hover:fill-primary dark:fill-bodydark dark:hover:fill-primary"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
                          fill=""
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
                          fill=""
                        />
                      </svg>
                    </button> */}
                    <input
                      type="text"
                      placeholder="Type to search..."
                      onChange={changeHandler}
                      className=" w- full lg:w-[300px] bg-transparent pr-4 pl-6 focus:outline-none"
                    />
                  </div>
                  <div className=" relative flow-root">
              <Link to="/cartpage" className="">
                <AiOutlineShoppingCart
                  className="text-2xl text-red-500 "
                  aria-hidden="false"
                />
                <span className="absolute flex left-6 -top-3 items-center justify-center text-xs font-medium text-white bg-green-600 h-7 w-7 rounded-full">
                  {totalQuantity}
                </span>
              </Link>
            </div>
                </form>
                
              </Header>
              <main>
                <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                  <Outlet />
                </div>
              </main>
            </div>
          </div>
        </div>
        <div className=" absolute right-0 md:hidden lg:hidden flow-root ">
              <Link to="/cartpage" className="">
                <AiOutlineShoppingCart
                  className="text-2xl text-red-500 "
                  aria-hidden="false"
                />
                <span className="absolute flex left-6 -top-3 items-center justify-center text-xs font-medium text-white bg-green-600 h-7 w-7 rounded-full">
                  {totalQuantity}
                </span>
              </Link>
            </div>
      </div>
    </>
  );
};

export default ProductListLayout;
