import React from 'react'
import Box1 from './Box/Box1'
import Box2 from './Box/Box2'
import Box3 from './Box/Box3'
import Box4 from './Box/Box4'
import Box5 from './Box/Box5'

const AnalyticsCard = ({ data }) => {
  return (
    <>
      <Box1 data={data} />
      <Box2 data={data} />
      <Box3 data={data} />
      <Box4 data={data} />
      <Box5 data={data} />
    </>
  )
}

export default AnalyticsCard
