import React from 'react'
import useScrollToTop from '../../hooks/useScrollToTop'
import BackToHome from './BackToHome'

const PrivacyPolicy = () => {
  useScrollToTop()
  return (
    <div className='max-w-7xl text-sm mx-auto mt-8 p-4 text-justify tracking-wide'>
      <BackToHome />

      <h2 className='text-2xl font-bold mb-4 text-center'>PRIVACY POLICY</h2>

      <p className='text-sm mb-2'>Last updated December 7, 2023</p>

      <p>
        This privacy notice for Nepsay Mart Pty Ltd ("we," "us," or "our"),
        describes how and why we might collect, store, use, and/or share
        ("process") your information when you use our services ("Services"),
        such as when you:
      </p>

      <p className='mb-2'>
        <span className='font-bold'>Questions or converns ?</span> Reading this
        privacy notice will help you understand your privacy rights and choices.
        If you do not agree with our policies and practices, please do not use
        our Services.
      </p>

      <h3 className='text-lg font-semibold mt-4'>SUMMARY OF KEY POINTS</h3>
      <p className='font-semibold'>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for.
      </p>
      <ul className='list-disc pl-6 mt-2'>
        <li className=''>
          <span className='font-bold'>
            What personal information do we process?
          </span>{' '}
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use.
        </li>
        <li className=''>
          <span className='font-bold'>
            Do we process any sensitive personal information?
          </span>
          We do not process sensitive personal information.
        </li>
        <li className=''>
          <span className='font-bold'>
            Do we receive any information from third parties?
          </span>
          We may receive information from public databases, marketing partners,
          social media platforms, and other outside sources.
        </li>
        <li className=''>
          <span className='font-bold'>How do we process your information?</span>
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Learn more about how we process
          your information.
        </li>
        <li className=''>
          <span className='font-bold'>
            In what situations and with which parties do we share personal
            information?
          </span>
          We may share information in specific situations and with specific
          third parties.
        </li>
        <li className=''>
          <span className='font-bold'>What are your rights?</span>
          Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal
          information. Learn more about your privacy rights.
        </li>
        <li className=''>
          <span className='font-bold'></span>
          How do you exercise your rights? The easiest way to exercise your
          rights is by submitting a data subject access request via our email
          listed at the bottom of this page, or by contacting us. We will
          consider and act upon any request in accordance with applicable data
          protection laws.
        </li>
      </ul>

      {/* TABLE OF CONTENTS */}
      <h3 className='text-lg font-semibold mt-4'>TABLE OF CONTENTS</h3>
      <ul className='flex flex-col space-y-2 pl-6 mt-2'>
        <li>
          <a className='font-bold text-sm' href='#section-1'>
            1. WHAT INFORMATION DO WE COLLECT?
          </a>
        </li>
        <li>
          <a className='font-bold text-sm' href='#section-2'>
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </a>
        </li>
        <li>
          <a className='font-bold text-sm' href='#section-3'>
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a className='font-bold text-sm' href='#section-4'>
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a className='font-bold text-sm' href='#section-5'>
            5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a className='font-bold text-sm' href='#section-6'>
            6. HOW LONG DO WE KEEP YOUR INFORMATION?
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a className='font-bold text-sm' href='#section-7'>
            7. DO WE COLLECT INFORMATION FROM MINORS?
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a className='font-bold text-sm' href='#section-8'>
            8. WHAT ARE YOUR PRIVACY RIGHTS?
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a className='font-bold text-sm' href='#section-9'>
            9. CONTROLS FOR DO-NOT-TRACK FEATURES
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a className='font-bold text-sm' href='#section-10'>
            10. DO WE MAKE UPDATES TO THIS NOTICE?
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a className='font-bold text-sm' href='#section-11'>
            11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
        </li>
        <li className='font-bold text-sm'>
          <a href='#section-12'>
            12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </li>
      </ul>

      {/* SECTION 1: WHAT INFORMATION DO WE COLLECT? */}
      <h3 id='section-1' className='text-lg font-semibold mt-4'>
        1. WHAT INFORMATION DO WE COLLECT?
      </h3>
      <p className='text-sm mb-2'>Personal information you disclose to us.</p>
      <p>
        <span className='font-bold'>In Short</span> We collect personal
        information that you provide to us. We collect personal information that
        you voluntarily provide to us when you register on the Services, express
        an interest in obtaining information about us or our products and
        Services, when you participate in activities on the Services, or
        otherwise when you contact us. Sensitive Information. We do not process
        sensitive information. All personal information that you provide to us
        must be true, complete, and accurate, and you must notify us of any
        changes to such personal information. Information automatically
        collected. In Short: Some information — such as your Internet Protocol
        (IP) address and/or browser and device characteristics — is collected
        automatically when you visit our Services. We automatically collect
        certain information when you visit, use, or navigate the Services. This
        information does not reveal your specific identity (like your name or
        contact information) but may include device and usage information, such
        as your IP address, browser and device characteristics, operating
        system, language preferences, referring URLs, device name, country,
        location, information about how and when you use our Services, and other
        technical information. This information is primarily needed to maintain
        the security and operation of our Services, and for our internal
        analytics and reporting purposes. Like many businesses, we also collect
        information through cookies and similar technologies.
      </p>
      {/* Add more content for Section 1 as needed */}

      {/* SECTION 2: HOW DO WE PROCESS YOUR INFORMATION? */}
      <h3 id='section-2' className='text-lg font-semibold mt-4'>
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </h3>
      <p>
        <span className='font-bold'>In Short</span> We process your information
        to provide, improve, and administer our Services, communicate with you,
        for security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your personal information for a variety of reasons, depending on
        how you interact with our Services, including: using other services from
        third-party from within our website.
      </p>
      {/* Add more content for Section 2 as needed */}

      {/* SECTION 3: WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? */}
      <h3 id='section-3' className='text-lg font-semibold mt-4'>
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </h3>
      <p>
        <span className='font-bold'>In Short</span> We may share information in
        specific situations described in this section and/or with the following
        third parties. We may need to share your personal information in the
        following situations: Business Transfers. We may share or transfer your
        information in connection with, or during negotiations of, any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company. Affiliates. We may share your
        information with our affiliates, in which case we will require those
        affiliates to honour this privacy notice. Affiliates include our parent
        company and any subsidiaries, joint venture partners, or other companies
        that we control or that are under common control with us. Business
        Partners. We may share your information with our business partners to
        offer you certain products, services, or promotions.
      </p>
      {/* Add more content for Section 3 as needed */}

      {/* SECTION 4: DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? */}
      <h3 id='section-4' className='text-lg font-semibold mt-4'>
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </h3>
      <p>
        <span className='font-bold'>In Short</span> We may use cookies and other
        tracking technologies to collect and store your information. We may use
        cookies and similar tracking technologies (like web beacons and pixels)
        to access or store information. Specific information about how we use
        such technologies and how you can refuse certain cookies is set out in
        our Cookie Notice.
      </p>
      {/* Add more content for Section 4 as needed */}

      {/* SECTION 5: IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? */}
      <h3 id='section-5' className='text-lg font-semibold mt-4'>
        5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
      </h3>
      <p>
        <span className='font-bold'>In Short</span> We may transfer, store, and
        process your information in countries other than your own. Our servers
        are located in. If you are accessing our Services from outside, please
        be aware that your information may be transferred to, stored, and
        processed by us in our facilities and by those third parties with whom
        we may share your personal information (see "WHEN AND WITH WHOM DO WE
        SHARE YOUR PERSONAL INFORMATION?" above), in and other countries. If you
        are a resident in the European Economic Area (EEA), United Kingdom (UK),
        or Switzerland, then these countries may not necessarily have data
        protection laws or other similar laws as comprehensive as those in your
        country. However, we will take all necessary measures to protect your
        personal information in accordance with this privacy notice and
        applicable law.
      </p>
      {/* Add more content for Section 5 as needed */}

      {/* SECTION 6: HOW LONG DO WE KEEP YOUR INFORMATION? */}
      <h3 id='section-6' className='text-lg font-semibold mt-4'>
        6. HOW LONG DO WE KEEP YOUR INFORMATION?
      </h3>
      <p>
        <span className='font-bold'>In Short</span>We keep your information for
        as long as necessary to fulfill the purposes outlined in this privacy
        notice unless otherwise required by law. We will only keep your personal
        information for as long as it is necessary for the purposes set out in
        this privacy notice, unless a longer retention period is required or
        permitted by law (such as tax, accounting, or other legal requirements).
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
      {/* Add more content for Section 6 as needed */}

      {/* SECTION 7: DO WE COLLECT INFORMATION FROM MINORS? */}
      <h3 id='section-7' className='text-lg font-semibold mt-4'>
        7. DO WE COLLECT INFORMATION FROM MINORS?
      </h3>
      <p>
        <span className='font-bold'>In Short</span> We do not knowingly collect
        data from or market to children under 18 years of age. We do not
        knowingly solicit data from or market to children under 18 years of age.
        By using the Services, you represent that you are at least 18 or that
        you are the parent or guardian of such a minor and consent to such minor
        dependent’s use of the Services. If we learn that personal information
        from users less than 18 years of age has been collected, we will
        deactivate the account and take reasonable measures to promptly delete
        such data from our records. If you become aware of any data, we may have
        collected from children under age 18, please contact us at
        nepsaymart@gmail.com
      </p>
      {/* Add more content for Section 7 as needed */}

      {/* SECTION 8: WHAT ARE YOUR PRIVACY RIGHTS? */}
      <h3 id='section-8' className='text-lg font-semibold mt-4'>
        8. WHAT ARE YOUR PRIVACY RIGHTS?
      </h3>
      <p>
        <span className='font-bold'>In Short</span> You may review, change, or
        terminate your account at any time. Withdrawing your consent: If we are
        relying on your consent to process your personal information, which may
        be express and/or implied consent depending on the applicable law, you
        have the right to withdraw your consent at any time. You can withdraw
        your consent at any time by contacting us by using the contact details
        provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
        below. However, please note that this will not affect the lawfulness of
        the processing before its withdrawal nor, when applicable law allows,
        will it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent. Account
        Information If you would at any time like to review or change the
        information in your account or terminate your account, you can: Upon
        your request to terminate your account, we will deactivate or delete
        your account and information from our active databases. However, we may
        retain some information in our files to prevent fraud, troubleshoot
        problems, assist with any investigations, enforce our legal terms and/or
        comply with applicable legal requirements.
      </p>
      {/* Add more content for Section 8 as needed */}

      {/* SECTION 9: CONTROLS FOR DO-NOT-TRACK FEATURES */}
      <h3 id='section-9' className='text-lg font-semibold mt-4'>
        9. CONTROLS FOR DO-NOT-TRACK FEATURES
      </h3>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      {/* Add more content for Section 9 as needed */}

      {/* SECTION 10: DO WE MAKE UPDATES TO THIS NOTICE? */}
      <h3 id='section-10' className='text-lg font-semibold mt-4'>
        10. DO WE MAKE UPDATES TO THIS NOTICE?
      </h3>
      <p>
        <span className='font-bold'>In Short</span> Yes, we will update this
        notice as necessary to stay compliant with relevant laws. We may update
        this privacy notice from time to time. The updated version will be
        indicated by an updated "Revised" date and the updated version will be
        effective as soon as it is accessible. If we make material changes to
        this privacy notice, we may notify you either by prominently posting a
        notice of such changes or by directly sending you a notification. We
        encourage you to review this privacy notice frequently to be informed of
        how we are protecting your information.
      </p>
      {/* Add more content for Section 10 as needed */}

      {/* SECTION 11: HOW CAN YOU CONTACT US ABOUT THIS NOTICE? */}
      <h3 id='section-11' className='text-lg font-semibold mt-4'>
        11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </h3>
      <p>
        If you have questions or comments about this notice, you may contact us
        by post at: Nepsay Mart Pty Ltd 29 Helpmann Crescent, Epping VIC 3076
      </p>
      {/* Add more content for Section 11 as needed */}

      {/* SECTION 12: HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? */}
      <h3 id='section-12' className='text-lg font-semibold mt-4'>
        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h3>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please fill out and submit a data subject
        access request.
      </p>
      {/* Add more content for Section 12 as needed */}
    </div>
  )
}

export default PrivacyPolicy
