import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <nav className='flex justify-start items-center'>
      <NavItem step={step1} to='/' label='Home' />
      <FaChevronRight className='mt-1 text-indigo-500' />
      <NavItem step={step2} to='' label={step2} />
      <FaChevronRight className='mt-1 text-indigo-500' />
      <NavItem step={step3} to='' label={step3} />
    </nav>
  )
}

const NavItem = ({ step, to, label }) => {
  const activeStepStyle = 'text-indigo-500 border-indigo-500'
  const inactiveStepStyle = 'text-gray-500 border-gray-500 cursor-not-allowed'
  const stepStyle = step ? activeStepStyle : inactiveStepStyle

  return (
    <div className=''>
      <Link
        to={to}
        className={`block cursor-pointer text-center border-b-2 border-transparent ${stepStyle} hover:border-indigo-500 hover:text-indigo-500`}
      >
        {label}
      </Link>
    </div>
  )
}

export default CheckoutSteps
