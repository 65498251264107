import React from "react";
import { useLocation } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import useAuthStore from "../../zustand/userStore";
import { Link } from "react-router-dom";

const CheckoutCrumb = () => {
  const isauth = useAuthStore((state) => state.isAuthenticated);
  const location = useLocation();
  const { pathname } = location;

  const path = isauth
    ? ["shipping", "payment"]
    : ["login", "shipping", "payment"];

  const pathName = path.findIndex((p) => p === pathname.split("/")[2]);
  return (
    <>
      <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 md:p-2">
        <Link to="/">
          <li className="hidden md:block">
            <AiOutlineHome className="text-2xl" />
          </li>
        </Link>

        {path.map((step, index) => (
          <li
            key={step}
            className={`flex items-center ${
              pathName === index
                ? "text-blue-600 dark:text-blue-500"
                : "text-gray-500"
            }`}
          >
            <span className="flex items-center justify-center w-5 h-5 mr-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
              {index + 1}
            </span>
            {step.charAt(0).toUpperCase() + step.slice(1)}{" "}
            <span className="hidden sm:inline-flex sm:ml-2">Info</span>
            <svg
              className="w-3 h-3 ml-2 sm:ml-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 12 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m7 9 4-4-4-4M1 9l4-4-4-4"
              />
            </svg>
          </li>
        ))}
      </ol>
    </>
  );
};

export default CheckoutCrumb;

//import React from 'react'
// import { Nav } from 'react-bootstrap'
// import { LinkContainer } from 'react-router-bootstrap'

// const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
//   return (
//     <Nav className='justify-content-center mb-4'>
//       <Nav.Item>
//         {step1 ? (
//           <LinkContainer to='/login'>
//             <Nav.Link>Sign In</Nav.Link>
//           </LinkContainer>
//         ) : (
//           <Nav.Link disabled>Sign In</Nav.Link>
//         )}
//       </Nav.Item>

//       <Nav.Item>
//         {step2 ? (
//           <LinkContainer to='/shipping'>
//             <Nav.Link>Shipping</Nav.Link>
//           </LinkContainer>
//         ) : (
//           <Nav.Link disabled>Shipping</Nav.Link>
//         )}
//       </Nav.Item>

//       <Nav.Item>
//         {step3 ? (
//           <LinkContainer to='/payment'>
//             <Nav.Link>Payment</Nav.Link>
//           </LinkContainer>
//         ) : (
//           <Nav.Link disabled>Payment</Nav.Link>
//         )}
//       </Nav.Item>

//       <Nav.Item>
//         {step4 ? (
//           <LinkContainer to='/placeorder'>
//             <Nav.Link>Place Order</Nav.Link>
//           </LinkContainer>
//         ) : (
//           <Nav.Link disabled>Place Order</Nav.Link>
//         )}
//       </Nav.Item>
//     </Nav>
//   )
// }

// export default CheckoutSteps
