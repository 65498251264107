import { Tooltip, Button } from 'antd'

import { IoPencil, IoTrashOutline } from 'react-icons/io5'

export const UpdateIconBtn = ({ ...rest }) => (
  <Tooltip title={'Update'}>
    <Button
      {...rest}
      type='default'
      color='yellow'
      className='px-1 transition duration-500 transform-gpu hover:scale-105 hover:text-orange-500 hover:border-orange-500 focus:text-orange-500 focus:border-orange-500'
    >
      <IoPencil fontSize={24} />
    </Button>
  </Tooltip>
)

export const DeleteIconBtn = ({ ...rest }) => (
  <Tooltip title={'Delete'}>
    <Button
      {...rest}
      type='default'
      className='px-1 transition duration-500 transform-gpu hover:scale-105 hover:text-red-500 hover:border-red-500'
    >
      <IoTrashOutline fontSize={25} />
    </Button>
  </Tooltip>
)
