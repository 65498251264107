import AddressForm from '../../components/Address/AddressForm'

function ShippingPage() {
  return (
    <>
      <div className='mt-3 animation boxShadow bg-whiter dropShadow'>
        <AddressForm />
      </div>
    </>
  )
}

export default ShippingPage
