
import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Index as Route } from './routes/index'
import './custom.style.css'
import { ToastContainer } from 'react-toastify'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export const  queryClient = new QueryClient({defaultOptions:{
  queries: {
    retry: 2, // Number of retries before giving ups
    retryDelay: (attemptIndex) => {
      return 10000; // 10 seconds delay for each retry
    },
  },
}})

const App = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ToastContainer hideProgressBar theme='dark' autoClose={2000} />
        <Route />
        {process.env.REACT_APP_NODE_ENV === 'development' ?
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' /> : null}
      </QueryClientProvider>
    </React.StrictMode>
  )
}

export default App