import React, { useMemo } from "react";
import AvatarComponent from "react-avatar";

import useAuthStore from "../../zustand/userStore";

export const Avatar = ({ image }) => {
  const user = useAuthStore((state) => state.user);
  useMemo(() => {
    if (user?.name) {
      return user.name;
    } else {
      return null;
    }
  }, [user]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex space-x-5">
        <AvatarComponent
          className="inline-block h-6   w-6 rounded-full"
          name={user?.name}
          size="40"
          // src={image}
        />
      </div>
    </div>
  );
};
