import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Helmat from '../../../components/Helmat/Helmat'
// import Sidebar from '

const DashBoardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <Helmat title='Admin Dashboard' content='Nepsaymart admin dashboard' />

      <div className='dark:bg-boxdark-2 dark:text-bodydark'>
        <div className='flex h-screen overflow-hidden'>
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className='relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden'>
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <main>
              <div className='mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'>
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashBoardLayout
